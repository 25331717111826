.modal {
	display: none;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Sit on top */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);
	/* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
	max-width: 50%;
	background-color: #fefefe;
	margin: 15% auto;
	/* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	z-index: 2;
	width: 80%;
	/* Could be more or less, depending on screen size */
}

/* Modal injected content */
#modal-injected_content {
	max-height: calc(100vh - 210px);
	overflow-y: auto;
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 1.75rem;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

body.modal-open {
	overflow: hidden;
}

.ReactModal__Overlay {
	z-index: 20;
	height: 100%;
	overflow-y: scroll !important;
	background: rgba($white--true, 0.5) !important;
	outline: none !important;

	@include breakpoint($medium) {
		padding: $cell;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba($white--true, 0.5) !important;
	}

	.modal__close {
		text-align: right;
		padding-bottom: 2rem;
		font-size: 1.85rem;
		line-height: 1;
		font-family: $base-font-family;
		cursor: pointer;
		transition: $transition-time;

		&:hover {
			color: $color-secondary;
		}
	}
}

.ReactModal__Content {
	border-radius: 0;
	border: none !important;
	padding: 3.125rem 2rem !important;
	top: auto !important;
	left: auto !important;
	bottom: auto !important;
	right: auto !important;
	margin: auto !important;
	margin-top: auto !important;
	transform: none !important;
	position: relative !important;
	background-color: $white--true;
	outline: none;
	box-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.25);

	@include breakpoint($medium) {
		padding: 2rem 2rem !important;
		padding-bottom: 4rem !important;
		width: 4 * $cell;
	}
}

.ReactModal__Body--open {
	overflow: hidden;
}

.modal__bottom {
	height: $cell;
	display: block;
	background: $color-accent;
}

.modal--small {
}

.modal--mail {
	width: 100%;
	@include breakpoint($medium) {
		max-width: 37.5rem;
	}
}

.modal__title {
	font-family: $heading-font-family;
	font-size: 1.5rem;
	line-height: 1.25;

	@include breakpoint($large) {
		font-size: 2.25rem;
	}
}
