/*------------------------------------*\
** COLORS
\*------------------------------------*/

$bamboo: #a4d867; // http://mera.se/PMS/367C.html

$mine-shaft: #323232;
$asbestos: #7f8c8d;
$concrete: #95a5a6;
$silver: #c0c0c0;
$mercury: #e5e5e5;
$alabaster: #fafafa;
$clouds: #ecf0f1;

$red: #ff0500;
$yellow-orange: #ffb400;
$dark-sky-blue: #4989dc;
$turquoise: #1abc9c;
$green-sea: #16a085;
$emerald: #2ecc71;
$nephritis: #27ae60;
$peter-river: #3498db;
$belize-hole: #2980b9;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$sun-flower: #f1c40f;
$orange: #f39c12;
$carrot: #e67e22;
$pumpkin: #d35400;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$wet-asphalt: #34495e;
$midnight-blue: #2c3e50;
$greyish-brown: rgba(72, 72, 72, 0.5);
$kelly-green: #07a028;

$cream--light: rgba(180, 55, 37, 0.12);
$cream--lighter: #faf6ef;

$color-primary: #1B1464;
$color-secondary: #678699;
$color-tertiary: #dfe8f1;

$white--true: #ffffff;
$white: #f9f9f9;
$color-gray--lighter: rgba(12, 12, 12, 0.1);
$color-gray--light: rgba(17, 17, 17, 0.4);
$color-gray: rgba(12, 12, 12, 0.7);
$color-gray--dark: $asbestos;
$color-gray--darker: $mine-shaft;
$black: #0c0c0c;
$black--true: #0c0c0c;
$color-green: $kelly-green;
$color-green--light: #3bd45c;

$color-accent: #EABB5F;

$color-info: #2196f3;
$color-success: #4caf50;
$color-warning: #f1c40f;
$color-alert: #f44336;

/*------------------------------------*\
** BREAKPOINTS
\*------------------------------------*/

$small: 0;
$medium: 640px;
$large: 1024px;
$xlarge: 1200px;
$xxlarge: 1440px;

$breakpoints: (
	small: $small,
	medium: $medium,
	large: $large,
	xlarge: $xlarge,
	xxlarge: $xxlarge
);

/*------------------------------------*\
# GRID
\*------------------------------------*/

$gutter: 1.5rem;
$cell: 6.5rem;
$width: 80rem;
$text-width: 40rem;

/*------------------------------------*\
** BUTTONS
\*------------------------------------*/

$button-radius: 0.25rem;
$button-padding--lr: 0.75rem;
$button-padding--tb: 0.4375rem;
$button-icon--size: 1.5rem;

/*------------------------------------*\
** INPUTS
\*------------------------------------*/

$input-radius: 0.25rem;
$input-padding: 0.75rem;
$input-border: 1px solid $color-gray--light;

/*------------------------------------*\
** FONT WEIGHTS
\*------------------------------------*/

$light: 300;
$regular: 400;
$med: 500;
$semibold: 600;
$bold: 700;
$extrabold: 900;

/*------------------------------------*\
** FONT CONFIG
\*------------------------------------*/

// http://www.zell-weekeat.com/responsive-typography

@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Roboto:400,500,700,900&display=swap');

$base-font-family: 'Roboto', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 1.6;
$base-font-color: $black--true;

$heading-font-family: 'Libre Baskerville', serif;

/*------------------------------------*\
** FONT SIZES
\*------------------------------------*/

$copy-font-sizes: (
	small: (
		0.875rem,
		$base-line-height
	),
	medium: (
		1rem,
		$base-line-height
	),
	large: (
		1rem,
		$base-line-height
	),
	xlarge: (
		1rem,
		$base-line-height
	),
	xxlarge: (
		1rem,
		$base-line-height
	)
);

$h1-font-sizes: (
	small: (
		1.625rem,
		1.2
	),
	medium: (
		2.25rem,
		1.2
	),
	large: (
		3rem,
		1
	),
	xlarge: (
		3rem,
		1
	),
	xxlarge: (
		3rem,
		1
	)
);

$h2-font-sizes: (
	small: (
		1.365rem,
		1
	),
	medium: (
		1.875rem,
		1.2
	),
	large: (
		2.625rem,
		3rem
	)
);

$h3-font-sizes: (
	small: (
		1.125rem,
		normal
	),
	medium: (
		1.375rem,
		1.2
	),
	large: (
		1.375rem,
		1.2
	),
	xlarge: (
		1.375rem,
		1.2
	),
	xxlarge: (
		1.375rem,
		1.2
	)
);

$h4-font-sizes: (
	small: (
		0.75rem,
		1
	),
	medium: (
		1rem,
		1.2
	),
	large: (
		1rem,
		1.2
	),
	xlarge: (
		1rem,
		1.2
	),
	xxlarge: (
		1rem,
		1.2
	)
);

$h5-font-sizes: (
	small: (
		1rem,
		1.2
	),
	medium: (
		1rem,
		1.2
	),
	large: (
		1rem,
		1.2
	),
	xlarge: (
		1rem,
		1.2
	),
	xxlarge: (
		1rem,
		1.2
	)
);

$h6-font-sizes: (
	small: (
		1rem,
		1.2
	),
	medium: (
		1rem,
		1.2
	),
	large: (
		1rem,
		1.2
	),
	xlarge: (
		1rem,
		1.2
	),
	xxlarge: (
		1rem,
		1.2
	)
);

/*------------------------------------*\
# Transitions
\*------------------------------------*/

$transition-time: 0.3s;
