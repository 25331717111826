.tabs {
}

.tabs__nav {
	line-height: 1.5rem;
	color: $black;
	font-size: 0.75rem;
	flex-direction: column;
	margin-bottom: 1.25rem;


	@include breakpoint($medium) {
		flex-direction: row;
		font-size: 1.25rem;
	margin-bottom: 1/2 * 6.25rem;

	}

	@include breakpoint($large) {
		font-size: 1.5rem;
		margin-bottom: 6.25rem;
	}

	p {
		font-size: inherit;
	}

	* {
		line-height: 1;
	}

	> div {
		display: flex;
		margin-bottom: 1rem;

		@include breakpoint($medium) {
			margin-bottom: 0;
		}

		> div {
			margin-right: 1.75rem;

			@include breakpoint($large) {
				margin-right: 3.75rem;
			}
		}
	}

	p {
		margin: 0;
	}

	b {
		font-weight: 400;
		position: relative;
		display: inline-block;

		&::after {
			content: '';
			width: 100%;
			height: 0;
			border-bottom: 1px solid $black;
			position: absolute;
			top: 100%;
			left: 0;
		}
	}

	a {
		color: $black;
	}
}

.react-tabs__tab-list {
	padding-bottom: $gutter;
	margin-bottom: $gutter;
}

.react-tabs__tab-list {
	display: flex;
	flex-direction: column;

	@include breakpoint($large) {
		flex-direction: row;
	}

	li {
		list-style-type: none;
		padding: 0;
		margin: 0;
		outline: none !important;
		box-shadow: none !important;

		@include breakpoint($large) {
			margin-right: 2 * $gutter;
			font-size: 1.25rem;
		}

		&::before,
		&::after {
			display: none;
		}
	}
}

.react-tabs__tab-panel {
	.tab-content--books > .tab-grid * {
		display: none;
	}

	.tab-content > .tab-grid {
		display: grid !important;
		flex-direction: column;
		width: 100%;
		grid-template-columns: 1fr;
		grid-gap: 2.5rem;

		@include breakpoint($medium) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include breakpoint($large) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	.tab-content > .tab-grid--books {
		grid-template-columns: 1fr 1fr;

		@include breakpoint($medium) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		@include breakpoint($large) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			padding-right: 3 * $cell;
		}
	}

	.tab-content a:not(.btn) {
		width: 100%;
		height: auto;
		display: block;

		img {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.card {
		width: 100%;
	}
}

.react-tabs__tab--selected {
	border: none;
	font-weight: $bold;
	color: $color-primary;
}

.tab-content__logo {
	width: 12.5rem;
	height: auto;
	display: inline-block;
	margin-top: 2 * 1.25rem;
	@include breakpoint($large) {
		margin-top: 0;
		margin-left: 1.25rem;
	}
}

.tab-content__row {
	display: flex;
	flex-direction: column;
	margin-top: $cell;

	@include breakpoint($medium) {
		flex-direction: row;
		align-items: center;
	}
}
