@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Roboto:400,500,700,900&display=swap');

/*------------------------------------*\
** HEADINGS
\*------------------------------------*/

.heading-fill--primary {
	display: inline-block;
	padding: $gutter;
	background-color: $color-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	font-weight: $regular;
	margin: 0;
	padding: 0;

	img {
		display: inline-block;
		object-fit: contain;
	}

	&.white > img {
		filter: brightness(0) invert(1);
	}
}

h1 {
	@include font-size($h1-font-sizes);
	margin-bottom: 3rem;

	@include breakpoint($large) {
		margin-bottom: 4rem;
	}

	img {
		height: 1ch;
		width: 1ch;
		margin-right: 1ch;
	}

	.heading-icon--2x {
		width: 2ch;
		height: 2ch;
	}
}

h2 {
	@include font-size($h2-font-sizes);
	margin-bottom: 1.25rem;

	@include breakpoint($large) {
		margin-bottom: 1.875rem;
	}
}

h3 {
	@include font-size($h3-font-sizes);
}

h4 {
	@include font-size($h4-font-sizes);
}

h5 {
	@include font-size($h5-font-sizes);
}

h6 {
	@include font-size($h6-font-sizes);
}

/*------------------------------------*\
** LISTS
\*------------------------------------*/

ul {
	padding-left: 2.5rem;
	margin-bottom: 2 * $gutter;
}

ul li {
	list-style-type: none;
	position: relative;
	line-height: inherit;
	font-size: inherit;
	margin-bottom: 0.625rem;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: -1.5rem;
		top: 1/2 * 1.75rem;
		margin: auto 0;
		background: $black;
		height: 0.25rem;
		width: 0.25rem;
		transform: translateY(-50%);
		border-radius: 50%;
		overflow: hidden;
	}
}

ol li {
}

/*------------------------------------*\
** LINKS
\*------------------------------------*/

a {
	text-decoration: none;
}

a.link {
	outline-width: 0;
	text-decoration: none;
	color: $color-primary;
	transition: 0.2s;
	font-size: 1rem;
	line-height: 1.6;
}

a.link--big {
	font-size: 1.375rem;
	font-weight: $bold;
	padding: 2.25rem 0;
	color: $color-primary;
	transition: $transition-time;

	&:hover {
		color: $color-accent;
	}
}

a.link:hover,
a.link:focus,
a.link:active {
	color: lighten($color-primary, 10%);
}

a.link--light {
	outline-width: 0;
	text-decoration: none;
	color: $white--true;
	transition: 0.2s;
	font-size: 1rem;
	line-height: 1.6;
}

a.link--light:hover,
a.link--light:focus,
a.link--light:active {
	color: lighten($color-primary, 10%);
}

a.link--small {
	font-size: 0.8rem;
}

a.link--center {
	text-align: center;
	display: block;
}

.link--section {
	font-size: 0.875rem;
	font-weight: $medium;
	text-transform: uppercase;
	color: $color-primary;
	transition: $transition-time;

	@include breakpoint($large) {
		font-size: 1.25rem;
	}

	&:hover,
	&:focus {
		opacity: 0.75;
	}
}

/*------------------------------------*\
** OTHER
\*------------------------------------*/

b,
strong,
.strong {
	font-weight: $bold;
}

p,
ul {
	@include font-size($copy-font-sizes);
	font-family: $base-font-family;
	margin-bottom: 1.5rem;
}

.text {
	font-size: 1rem;
	color: $color-gray;

	@include breakpoint($large) {
		font-size: 1.375rem;
		line-height: 2.25rem;
	}

	&:nth-last-child(1) {
		margin-bottom: 0;
	}
}

pre,
code {
	font-size: 0.75rem;
	line-height: 1rem;
	margin-bottom: 1rem;
	display: block;
}

/*------------------------------------*\
** TEXT MODIFIERS
\*------------------------------------*/

.text--small {
	font-size: 0.8rem;
}

.text--slarge {
	font-size: 1.125rem;
}

.text--large {
	font-size: 1.2rem;
}

p.text--large {
	line-height: 2.25rem;

	@include breakpoint($large) {
	}
}

.text--xlarge {
	font-size: 1.75rem;
}

.text--double {
	font-size: 2rem;
}

.text--center {
	text-align: center;
}

.text--light {
	color: $white--true;
}

.text--dark {
	color: $black--true;
}

.text--alert {
	color: $color-alert;
}

.text--limiter {
	max-width: 40rem;
}

.medium {
	font-weight: $medium;
}

.semibold {
	font-weight: $semibold;
}

.bold {
	font-weight: $bold;
}

.color-primary {
	color: $color-primary;
}

.color-tertiary {
	color: $color-tertiary;
}

.color-accent {
	color: $color-accent;
}

.color-blue {
	color: darken($dark-sky-blue, 25%) !important;
}

.white {
	color: $white--true;

	li::before,
	li::after {
		background: $white--true;
	}
}

.white--children {
	* {
		color: $white--true !important;
	}
}

.text--legend {
	display: block;
	text-align: center;
	line-height: 1rem;
	font-size: 0.8rem;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.gray {
	color: $color-gray;
}

.font--small {
	font-size: 0.875rem;
}

.font--medium {
	@include breakpoint($large) {
		font-size: 1.375rem;
		line-height: 2.25rem;
	}

	> * {
		font-size: inherit;
	}
}

.font--heading {
	font-family: $heading-font-family;
}

.margin--double {
	margin-bottom: 2 * $gutter;
}

.margin--80--l {
	@include breakpoint($large) {
		margin-bottom: 5rem;
	}
}

.page-content {
	font-size: 1.125rem;

	p {
		font-size: inherit;
	}

	a {
		color: $color-accent;
		font-weight: 500;
	}
}

.multicolumn--3-2-1 {
	column-gap: 2rem;
	column-count: 1;

	@include breakpoint($medium) {
		column-count: 2;
	}

	@include breakpoint($large) {
		column-count: 3;
	}
}

.link.-store {
	height: 6.25rem;
	width: 100%;

	img {
		height: 100%;
		width: 100%;
	}
}

.base-font-family {
	font-family: $base-font-family;
}

.no-margin {
	margin-bottom: 0;
}
