.map-wrap {
	height: 22rem;
	width: 100%;
	margin-bottom: 4rem;
	position: relative;

	> div {
		height: 100% !important;
		width: 100% !important;
	}
}
