.card {
	background: $white--true;
	display: flex;
	flex-direction: column;
	position: relative;

	@include breakpoint($large) {
		box-shadow: 0 0.125rem 0.375rem 0 rgba(12, 12, 12, 0.12);
	}

	.like-btn {
		margin-top: auto;
		margin-bottom: 0.5rem;
	}

	.overlay-link {
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		height: 100% !important;
		width: 100%;
		opacity: 0;
	}

	iframe {
		background: $black;
		border: none;
	}
}

.card__stores {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 0.625rem;
	margin-top: auto;
	padding-right: 2 * $gutter;

	a > img {
		object-fit: contain !important;
	}
}

.card--left {
	.card__img img {
		object-position: left;
		object-fit: contain;
	}

	.card__body {
		padding-left: 0;
		margin-bottom: 1.75rem;
	}
}

.card--small {
	.card__btn {
		margin-top: auto;
	}
}

.card__link {
	color: $black--true;
	transition: $transition-time;

	.card__img {
		overflow: hidden;
	}

	* {
		transition: $transition-time;
	}

	&:hover {
		color: $color-secondary;
		img {
			transform: scale(1.1);
		}
	}
}

.card--compact {
	.card__title {
		margin-bottom: 1.75rem;

		@include breakpoint($large) {
		}
	}

	.card__description {
		margin-bottom: 0.625rem;
		font-size: 0.875rem;

		@include breakpoint($large) {
			font-size: 1rem;
			margin-bottom: 1.75rem;
		}
	}

	.card__copy {
		display: none;
	}
}

.card--horizontal {
	display: flex;
	flex-direction: column;
	padding-bottom: 3rem;
	margin-bottom: 3rem;
	border-bottom: 1px solid $black;

	@include breakpoint($large) {
		flex-direction: row;
	}

	&:nth-last-child(1) {
		padding-bottom: 0;
		border-bottom: none;
		margin-bottom: 6.25rem;
	}

	.card__body {
		padding: 0;

		@include breakpoint($large) {
			width: auto;
		}
	}

	.card__copy {
		line-height: 1.5rem;

		@include breakpoint($large) {
		}
	}

	.card__btn {
		padding: 0;

		@include breakpoint($large) {
			margin-top: 3.25rem;
			margin-left: auto;
		}
	}
}

.card--horizontal.white {
	border-color: $white--true;
}

.card--news {
	padding: 0;
	margin: 0 auto;
	border: none;
	max-width: 4 * $cell;
	margin-bottom: $gutter;


	@include breakpoint($large) {
		max-width: unset;
		display: flex;
		position: relative;
	margin-bottom: 5rem;
	}

	.card__img {
		display: flex;
		width: 100%;
		height: auto;

		@include breakpoint($large) {
			width: 4 * $cell;
			min-width: 4 * $cell;
			height: auto;
			display: inline-block;
			position: relative;
			top: 0;
			left: 0;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.card__social {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 3;
		margin: $gutter 0;

		@include breakpoint($large) {
			justify-content: flex-start;
			margin: 0;
			padding-right: 2 * $gutter;
		}

		button {
			margin-left: 1rem;

			&:nth-child(1) {
				margin-left: 0;
			}
		}
	}

	.card__body {
		padding: 0;
		padding: 1rem;

		@include breakpoint($large) {
			padding: 2.875rem $cell;
			padding-right: 2 * $cell;
			display: inline-block;
			//margin-left: 4 * $cell;
		}
	}

	.card__copy {
		@include breakpoint($large) {
			line-height: 1.5rem;
		}
	}

	.card__btn {
		padding: 0;

		@include breakpoint($large) {
			margin-top: 3.25rem;
			margin-left: auto;
		}
	}
}

.card--transparent {
	background: transparent;
	box-shadow: none;
}

.card--big {
	.card__img {
		padding: 1rem;
		@include breakpoint($large) {
			height: 12.5rem;
		}
	}

	.card__body {
		@include breakpoint($large) {
			padding: 1.5rem 1.5rem;
			padding-bottom: 1.5rem;
		}
	}

	.card__title {
		@include breakpoint($large) {
			font-size: 1.75rem;
			margin-bottom: 1.5rem;
		}
	}

	.card__copy {
		@include breakpoint($large) {
			margin-bottom: 1.25rem;
			font-size: 1.125rem;
		}
	}

	.card__btn {
		@include breakpoint($large) {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			padding-bottom: 2.125rem;
		}
	}
}

.card--team {
	display: flex;
	flex-direction: column;

	@include breakpoint($large) {
		flex-direction: row;
		margin-bottom: 1/2 * $cell;
	}

	a {
		color: lighten($dark-sky-blue, 5%) !important;
	}

	.card__img {
		height: 2 * $cell;
		min-width: 2 * $cell;
		width: 2 * $cell;
		background-color: $color-tertiary;
		margin-bottom: 1rem;

		@include breakpoint($large) {
			margin-bottom: 5rem;
		}
	}

	.card__body {
		padding: 0;
		@include breakpoint($large) {
			margin-left: 1/2 * $cell;
			padding-right: 1/2 * $cell;
		}
	}

	.card__title {
		font-size: 1.25rem;
		line-height: 2rem;
		font-weight: $bold;
		margin-bottom: 0;
		//margin-bottom: 2.125rem;

		@include breakpoint($large) {
			font-size: 1.75rem;
			margin-top: 1.75rem;
		}
	}

	.card__info {
		margin-bottom: 0.625rem;
		font-size: 0.875rem;
		font-weight: 500;
		opacity: 0.86;
	}

	.card__description {
		font-size: 0.75rem;
		//margin-bottom: 3rem;

		@include breakpoint($large) {
			margin-bottom: 0;
			font-size: 0.875rem;
		}
	}

	.card__link a {
		color: rgba(12, 12, 12, 0.7);
		font-size: 1rem;
		text-decoration: none;
	}
}

.card__img {
	height: 12.5rem;
	width: 100%;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	.empty-image {
		background-color: $color-gray--light;
		height: 100%;
		width: 100%;
		opacity: 0.25;
	}
}

.card__body {
	padding: 1.25rem;
	padding-bottom: 0;
}

.card__title {
	font-weight: $medium;
	font-size: 1.125rem;
	margin-bottom: 0.625rem;
	line-height: 1.75rem;

	@include breakpoint($large) {
		font-size: 1.375rem;
		margin-bottom: 1rem;
	}

	a {
		color: inherit;
		transition: $transition-time;

		&:hover {
			opacity: 0.65;
		}
	}
}

.card__copy {
	color: $color-gray;
	font-size: 0.75rem;

	@include breakpoint($large) {
		line-height: 1.5rem;
		margin-top: 0;
		font-size: 1rem;
		margin-bottom: 1.5rem;
	}

	&.white {
		color: $white--true;
	}
}

.date-time-info {
	border-top: 1px solid $color-accent;
	border-bottom: 1px solid $color-accent;
	font-weight: $medium;
	color: $color-accent;

	@include breakpoint($large) {
		padding: 1.5rem 0;
	}

	span:nth-child(1) {
		padding-right: 1rem;
		margin-right: 1rem;
		border-right: 1px solid $color-primary;
	}
}

.date-time-info--no-border {
	border: none;
	font-weight: $medium;
	padding: 0;
	margin-bottom: 1.125rem;

	span:nth-last-child(1) {
		border-right: none;
	}
}

.date-time-info--small {
	padding: 0;
	border: none;
	margin-bottom: 1.25rem;

	div {
		line-height: 1;
		display: block;
		margin-bottom: 0.5rem;
	}

	div:nth-child(1),
	span:nth-last-child(1) {
		margin-right: 0;
		padding-right: 0;
		border: none;
	}

	div:nth-last-child(1) {
		margin-bottom: 0;
	}
}

.card__btn {
	margin-top: auto;
	padding: 0 1.25rem;
	padding-bottom: 2rem;
}

.card-padding {
	padding: 0.85 * $gutter;
	padding-bottom: 0;

	@include breakpoint($large) {
		padding: $gutter;
	}

	.card__body,
	.card__btn {
		padding-left: 0;
		padding-right: 0;
	}
}

.card--circle {
	@include breakpoint($large) {
		width: 4 * $cell;
	}
	h1,
	h2 {
		font-size: 32px;
		margin-bottom: 1rem;
		margin-top: 0;
	}

	.btn {
		margin-top: auto;
	}
}

.card--video {
	background: transparent;
	box-shadow: none;
	padding: 0;

	.card__title {
		font-size: 1.5rem;
		line-height: 1.5;
	}

	.card__date {
		margin-bottom: 0.5rem;
		opacity: 0.75;
	}
}

.card--prayer {
	border-top: 0.625rem solid $color-accent;
	box-shadow: 0 0.125rem 0.375rem 0 rgba(12, 12, 12, 0.12);
	padding-bottom: 0.875rem;
	margin-bottom: 0.3125rem;

	.card__body > div {
		font-size: 1rem;
		margin-bottom: 0.625rem;
	}

	strong {
		color: $color-primary;
	}

	.card__description {
		font-size: 1rem;
		line-height: 2;

		@include breakpoint ($large) {
			font-size: 1.25rem;
		}
	}
}

.card--search {
	padding: 1.5rem;
	box-shadow: 0 0.125rem 0.375rem 0 rgba(12, 12, 12, 0.12);
	margin-bottom: 1.5rem;

	p {
		margin-bottom: 0;
		color: $color-gray;
	}
}