$section-padding--large: 1/2 * 14.375rem 0;

.section--whats-on {
	padding-top: 3.25rem;
	padding-bottom: 2.75rem;
	margin-bottom: 1/4 * 14.375rem;
	background: $color-tertiary;

	@include breakpoint($large) {
		padding-bottom: 5.25rem;
	}

	.link--section {
		display: block;
		padding-top: 2.25rem;
		color: $white--true;

		@include breakpoint($large) {
			padding-top: 6.25rem;
		}
	}
}

.section--about {
	.section__body {
		padding: 4.8125rem 0;
		padding-right: $cell;
	}

	.section__img {
		width: 66.5rem;
		height: 100%;
		min-height: 34.375rem;
	}
}

.section--info--bg {
	padding: 8.5rem 0;
	position: relative;

	&:after {
		content: '';
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -2;
		background-color: $color-secondary;
	}
}

.section__img>img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.section__body--margin {
	margin-bottom: 5rem;
}

.section--padded--large {
	padding: 0.75 * $cell 0;
	padding-bottom: 0;

	@include breakpoint($large) {
		padding: 0.75 * $cell 0;
	}
}

.section--margin--large {
	border-top: 0.45 * $cell solid $white--true;
	border-bottom: 0.45 * $cell solid $white--true;

	@include breakpoint($large) {
		border-top: 0.75 * $cell solid $white--true;
		border-bottom: 0.75 * $cell solid $white--true;
	}
}

.not-implemented {
	padding: 1/2 * 14.375rem 0;
	text-align: center;
}

.safeguarding {
	display: grid;
	grid-gap: $cell;

	@include breakpoint($large) {
		grid-gap: 2 * $cell;
		grid-template-columns: 5 * $cell 5 * $cell;
	}
}

.safeguarding__img {
	//max-height: 12.75rem;
	height: auto;
	width: 100%;
	margin-bottom: 3.125rem;

	img {
		height: auto;
		max-height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.about-text {
	img {
		width: 3 * $cell;
		height: auto;
		object-fit: cover;
		margin: 2rem 0 !important;
		display: inline-block;
	}

	span {
		overflow: auto;
		height: auto !important;
		width: auto !important;
	}

	p:empty,
	p:blank {
		display: none;
	}

	p {
		font-size: 1rem;
	}

	h3 {
		font-size: 2.5rem;
	}

	blockquote {
		font-size: 0.75rem;
		padding: 0;
		margin: 0;
		margin-bottom: 4rem;
		opacity: 0.7;
	}

	&:nth-child(odd) {
		text-align: left;
	}
}

.notification-wrapper {
	margin-top: 2rem;
	padding: 1.25rem;
	background-color: $color-accent;
	border-radius: 0.375rem;
	margin-bottom: 1/2 * $cell;
	color: $white;
	text-align: center;

	@include breakpoint($large) {
		margin-top: 0;
	}

	p {
		margin-bottom: 0;
	}

	p:empty {
		display: none;
	}
}

.loader {
	transition: all 300ms ease-in-out;
	opacity: 0.15;
	
	/* .section--primary {
		background: rgba(224, 224, 224, 0.493);
	}  */

	&::after {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		z-index: 1000;
		background: url('/spinner.svg')no-repeat center center;
		opacity: 0;
		background-size: 2*3.125rem;
		background-color: transparent;
		animation-name: loader;
		animation-duration: 300ms;
		animation-delay: 1s;
		animation-fill-mode: forwards;
	}
}

@keyframes loader {
	from  {opacity: 0;}
	to {opacity: 1;}
}

.loader--loaded {
	opacity: 1;

	&::after {
		display: none;
		animation: none;
	}

	/* .section--primary {
		background: $color-primary;
	}  */
}

.share-buttons {
	position: fixed;
	bottom: $cell;
	right: 0;
	z-index: 20;
	display: flex;
	flex-direction: column;
	padding: 0.625rem;
	background: $white--true;
	border-radius: 0.375rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;

	button {
		height: 2.5rem;
		width: 2.5rem;
		margin-bottom: 0.625rem;
		border-radius: 50%;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}

		svg {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
}


.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.85rem;
  padding-bottom: 1.85rem;
  border-bottom: 1px solid $color-gray--lighter;

  h1 {
    margin: 0;
  }
}