.slider--cards {
	.slider {
		position: relative;
	}

	.slick-slider {
		color: $white--true;
		position: relative;
		height: 100%;

		.slick-track {
			display: flex;

			&:after {
				content: '';
				height: 100%;
				width: 200vw;
				position: absolute;
				left: 100%;
				top: 0;
				left: 0;
				background: #678699;
				transform: translateX(-50%);
				z-index: -1;

				@include breakpoint($large) {
					left: 100%;
				}
			}
		}

		.slick-slide {
			>* {
				transition: $transition-time;
				color: $white--true;
			}

			.btn {
				border-color: $white--true;
				color: $white--true;

				&:hover {
					background-color: $white--true;
					color: $color-primary;
				}
			}
		}

		.slick-slide:not(.slick-active) {
			cursor: pointer;

			>* {
				opacity: 0.5;
			}

			&:before {
				opacity: 0.5;
			}

			&:after {
				opacity: 0.2;
			}
		}

		.slick-slide {
			background: $color-secondary;
			position: relative;
			overflow: visible;
			padding: 1/2 * 6.25rem 0;
			padding-right: 1/2 * $cell;
			padding-bottom: 1/2 * 4.75rem;

			@include breakpoint($large) {
				padding: 6.25rem 6.5rem;
				padding-bottom: 1/4 * 4.75rem;
				padding-right: 0;
			}

			&:before {
				content: '';
				position: absolute;
				top: 1/2 * 6.25rem;
				left: 0;
				height: 0.625rem;
				width: 0.625rem;
				border: 0.125rem solid $white--true;
				border-radius: 100%;

				@include breakpoint($large) {
					top: 6.25rem;
					left: 6.5rem;
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 1/2 * 6.25rem;
				left: 1.75rem;
				transform: translateY(0.2rem);
				height: 0.2rem;
				width: calc(100% - 3.125rem);
				overflow: hidden;
				border-bottom: 2px dashed $white--true;
				opacity: 0.5;
				z-index: 1;

				@include breakpoint($large) {
					top: 6.25rem;
					left: 7.8rem;
				}
			}
		}

		.slick-slide:nth-last-child(1) {
			overflow: hidden;
			//padding-right: 6.5rem;

			@include breakpoint($large) {
				padding-right: 6.5rem;
			}

			&:after {
				display: none;
			}
		}
	}

	.slick-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 1rem;
		z-index: 5;
		background-color: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
		font-size: 0;
		color: $white--true;

		@include breakpoint($large) {
			width: 5rem;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2.375rem;
			transition: $transition-time;

			@include breakpoint($large) {
				font-size: 8.375rem;
			}
		}

		&:hover:after {
			opacity: 0.7;
		}

		&:active:after {
			color: lighten($color-primary, 20%);
		}
	}

	.slick-prev {
		left: 0;
		transform: translateX(-2.5rem);

		@include breakpoint($large) {
			transform: translateX(-4.5rem);
		}

		&:after {
			content: '\2039';
		}
	}

	.slick-next {
		right: 0;
		transform: translateX(2.5rem);

		@include breakpoint($large) {
			transform: translateX(4.5rem);
		}

		&:after {
			content: '\203A';
		}
	}

	.slick-disabled {
		opacity: 0;
		pointer-events: none;
	}
}

.slider--gallery {
	//height: 100%;
	position: relative;
	width: 100%;

	.gallery-item__name {
		margin-top: 0.625rem;
	}

	.gallery-item__caption {
		position: absolute;
		bottom: 0;
		line-height: 1.75;
		left: 0;
		width: 100%;
		padding: 0.625rem;
		padding-top: 3rem;
		font-size: 1rem;
		color: $white--true;
		display: inline-block;
		height: auto;
		background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,0,0,0) 100%); 
	}

	div {
		height: 100%;
	}

	.slick-track {
		display: flex;
		min-width: 100%;
	}

	.slick-slide {
		transition: $transition-time;
		height: 21.875rem;

		@include breakpoint($large) {
			height: 25rem;
		}
	}

	.slick-slide>div {
		padding: 0 1.25rem;
	}

	.slick-slide:not(.slick-active) {
		opacity: 0.5;
	}

	.slick-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 1rem;
		z-index: 5;
		background-color: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
		font-size: 0;
		color: $white--true;

		@include breakpoint($large) {
			width: 5rem;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2.375rem;
			transition: $transition-time;
			color: $black;

			@include breakpoint($large) {
				font-size: 7.375rem;
			}
		}

		&:hover:after {
			opacity: 0.7;
		}

		&:active:after {
			color: lighten($color-primary, 20%);
		}
	}

	.slick-prev {
		left: 0;
		transform: translateX(-1.5rem);

		@include breakpoint($large) {
			transform: translateX(-2 * 6.25rem);
		}

		&:after {
			content: '\2039';
		}
	}

	.slick-next {
		right: 0;
		transform: translateX(1.5rem);

		@include breakpoint($large) {
			transform: translateX(2 * 6.25rem);
		}

		&:after {
			content: '\203A';
		}
	}

	img {
		position: relative;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.slider--whats-on {
	height: 100%;
	position: relative;

	iframe {
		border: none;
		width: 100%;
		min-height: 120%;
	}

	.slider {
		margin: 0 auto;
		width: calc(100% + 1 / 2 * 1.125rem);
	}

	.slick-list {
		margin: 0 -1.125rem;
	}

	.slick-track {
		display: flex;
	}

	.slick-slide {
		transition: $transition-time;
		padding: 0 1.125rem;
	}

	.slick-slide>div {
		//padding: 0 1.25rem;
		height: 100%;
	}

	.slick-slide {
		.card {
			display: flex !important;
			height: 100%;
		}
	}

	.slick-slide:not(.slick-active) {
		opacity: 0;
		pointer-events: none;
	}

	.slick-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 1rem;
		z-index: 5;
		background-color: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
		font-size: 0;
		color: $white--true;

		@include breakpoint($large) {
			width: 5rem;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 4.975rem;
			transition: $transition-time;

			@include breakpoint($large) {
				font-size: 12.375rem;
			}
		}

		&:hover:after {
			opacity: 0.7;
		}

		&:active:after {
			color: lighten($color-primary, 20%);
		}
	}

	.slick-disabled {
		opacity: 0;
		pointer-events: none;
	}

	.slick-prev {
		left: 0;
		transform: translateX(-2.5rem);

		@include breakpoint($large) {
			transform: translateX(-2 * 6.25rem);
		}

		&:after {
			content: '\2039';
		}
	}

	.slick-next {
		right: 0;
		transform: translateX(2.5rem);

		@include breakpoint($large) {
			transform: translateX(2 * 6.25rem);
		}

		&:after {
			content: '\203A';
		}
	}

	img {
		position: relative;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.slider--video {
	.slick-arrow:after {
		color: $black;
	}
}