.section--community {
	position: relative;
	margin-bottom: $cell;
	background-color: $color-primary;

	&:nth-child(even) .community {
		@include breakpoint($large) {
			flex-direction: row-reverse;
		}
	}

	&:nth-child(even) .community__img {
		@include breakpoint($large) {
			right: auto;
			left: 0;
		}
	}
}

.community {
	display: flex;
	flex-direction: column;

	@include breakpoint($large) {
		min-height: 40vh;
		align-items: center;
		flex-direction: row;
	}

	.card--circle {
		margin-bottom: $gutter;

		@include breakpoint($large) {
			margin-bottom: 0;
		}
	}
}

.community__img {
	height: 24.75rem;
	@include breakpoint($large) {
		position: absolute;
		top: 0;
		right: 0;
		left: auto;
		height: 100%;
		width: 40%;
		z-index: 0;
	}

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
