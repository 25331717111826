.icon--primary path {
	fill: $color-primary !important;
	stroke: $color-primary !important;
}

.icon--white path {
	fill: $white !important;
	stroke: $white !important;
}

.icon--black path {
	fill: $black !important;
	stroke: $black !important;
}

.icon--1x {
	width: 1.1875rem;
	height: 1.1875rem;
}

.icon--2x {
	width: 1.5rem;
	height: 1.5rem;
}

.icon--3x {
	width: 2rem;
	height: 2rem;
}

.icon--4x {
	width: 4rem;
	height: 4rem;
}

.icon--huge {
	max-width: 200px;
	max-height: 200px;
}

.icon--spacing {
	margin-left: 1rem;
	margin-right: 1rem;
	max-width: 1.4375rem;
}

.icon--spacing-s {
	margin-right: 0.8rem;

	@include breakpoint($medium) {
		margin-left: 0.8rem;
	}
}

.icon--offset-right {
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	object-fit: contain;
	padding-left: 0.625rem;
}

.icon--spacing--right {
	margin-right: 0.625rem;

	&:nth-last-child(1) {
		margin-right: 0;
	}
}

.icon-wrap--center {
	display: flex;
	justify-content: center;
	align-items: center;
}
