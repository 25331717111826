.article {
	p,
	div {
		font-family: $base-font-family !important;
	}

	span {
		border: none !important;
	}
}

.article--bookings {
	.article__content {
		padding: 0;
	}

	.article__header {
		width: 100%;

		img {
			object-fit: cover;
		}
	}

	.floorplan {
		margin-bottom: $cell;
		img {
			object-fit: contain;
			object-position: left;
		}
	}

	.bookings-info {
		text-align: left;
		background-color: $color-tertiary;
		padding: 1/2 * 6.25rem 2rem;

		@include breakpoint($large) {
			margin-left: $cell;
		}

		table {
			width: 100%;
		}

		th {
			margin: 0.625rem 0;
			margin-top: 1rem;
			display: block;
		}

		h2,
		h3 {
			font-family: $base-font-family;
			color: $color-primary;
			font-style: italic;
			font-weight: $bold;
		}

		h3 {
			font-style: normal;
			font-weight: $bold;
			margin-bottom: 1rem;
		}
	}

	.bookings-info__row {
		font-size: 1.125rem;
		margin-bottom: 0.625rem;
		display: flex;
		justify-content: space-between;
	}

	.bookings-info__category {
		margin-bottom: 1.875rem;
	}

	.article__main {
		margin-bottom: $cell;
	}
}

.article--news {
	@include breakpoint($large) {
	}
}

.article--services {
	@include breakpoint($large) {
	}

	.article__body {
		grid-template-columns: 8fr 3fr;
	}

	.article__footer {
		margin-bottom: 1/2 * 14.375rem;
	}
}

.article--about {
	@include breakpoint($large) {
		padding: 0;
	}

	.article__side {
		@include breakpoint($large) {
			position: relative;
		}
	}

	.cta {
		@include breakpoint($large) {
			position: absolute;
			top: 8.125rem;
			left: 0;
		}
	}

	.gallery-section {
		margin-bottom: $cell;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}

		h2 {
			margin-bottom: 1.75rem;
			opacity: 0.8;

			@include breakpoint ($large) {
			font-size: 2rem;
			}
		}

		.slider--gallery {
			margin-left: -1.25rem;
		}
	}
}

.article__header,
.article__footer {
	position: relative;
	//background-color: rgba($color-tertiary, 0.3);
	margin-bottom: 1/2 * $cell;
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	max-height: 50vh;

	@include breakpoint($large) {
		height: 34.375rem;
		margin-bottom: 6.25rem;
	}

	> img {
		height: 100%;
		width: 100%;
		max-height: inherit;
		object-fit: contain;
		object-position: left;
	}
}

.article__footer--gallery {
	display: flex;
	flex-direction: column;
	height: auto;
	max-height: unset;
}

.article__header--venue-hire {
	background-color: $color-gray--lighter;
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	text-align: center;
	color: $color-gray--light;
	font-weight: $bold;
	font-size: 2rem;

	@include breakpoint($large) {
		min-height: 34.375rem;
	}

	img {
		width: 100%;
		height: auto;
		object-fit: contain;
	}
}

.article--whats-on {
	.article__header {
		height: auto;
		max-height: 50vh;
		img {
			height: 100%;
			width: 100%;
			max-height: inherit;
			object-fit: contain;
			object-position: left;
		}
	}
}

.article--safeguarding {
	.article__body {
		padding-bottom: 0 !important;
	}

	.article__header img {
		object-fit: cover;
	}

	ul,
	ol {
		list-style-type: none;
		margin-bottom: 1/2 * $cell;
		line-height: inherit;
		position: relative;
	}

	ul,
	ol {
		padding-left: 1.5rem;
	}

	li {
		list-style-type: unset;
		line-height: 2;
		margin-bottom: 0.75rem;
		padding-left: 0.75rem;

		@include breakpoint($large) {
			padding-left: 1rem;
		}

		&::before {
			content: '';
			height: 2px;
			width: 1rem;
			background-color: $color-primary;
			display: block;
			position: absolute;
			left: 0;
			top: 1rem;
			border-radius: 0;
			transform: translateX(-100%);
		}
	}

	li::before,
	li::after {
		background: $white--true;
	}
}

.article__body {
	@include breakpoint($large) {
		display: grid;
		grid-template-columns: 9fr 3fr;
		padding-bottom: 10.5rem;
	}
}

.article__body--wide {
	@include breakpoint($large) {
		grid-template-columns: 1fr;
	}
}

.article__body--news {
	@include breakpoint($large) {
		grid-template-columns: 8fr 2fr;
	}
}

.article__main {
	margin-bottom: 0;

	@include breakpoint($large) {
		margin-bottom: 0;
	}
}

.article__content {
	font-size: 1rem;

	@include breakpoint($large) {
		padding-right: 2 * $cell;
	}

	p {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		font-size: 1.125rem;
	}

	code {
		background: $color-primary;
		padding: 2em;
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
		color: $white--true;
		margin-bottom: $cell;
	}

	p {
		margin-bottom: 1.75em;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: $bold;
		margin: 1.5em 0;
		font-family: $heading-font-family !important;
	}

	h1,
	h2 {
		margin-top: 0;
		margin-bottom: 2em;
	}

	a {
		color: lighten($dark-sky-blue, 5%) !important;
		word-break: break-all;
	}

	[style='text-align: center;'] {
		background: $color-primary;
		text-align: left !important;
		color: $white--true;
		padding: 2.75rem;

		@include breakpoint($large) {
			font-size: 1.375rem;
		}

		&:empty {
			padding: 0;
		}

		&:nth-last-child(1) {
			margin-bottom: $cell;
			padding-top: 0;
		}
	}

	blockquote {
		padding: 0;
		margin: 0;

		p {
			margin: 0;
		}

		a {
			position: relative;
			display: block;
			font-weight: $bold;
			padding: 1rem 2rem;
			background: $color-tertiary;
			width: 100%;
			margin-bottom: 1rem;
			color: $color-primary;

			@include breakpoint($large) {
				width: 50%;
			}
		}

		a:hover {
			opacity: 0.75;
		}

		a:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 0.5rem;
			height: 100%;
			background: $color-primary;
		}
	}
}

.article__side {
	@include breakpoint($large) {
		padding-top: 18.75rem;
	}
}

.article__header {
	@include breakpoint($large) {
	}
}

.sidebar {
}

.sidebar__title {
	@include breakpoint($large) {
		margin-bottom: 4.875rem;
	}
}

.sidebar__body {
	line-height: 2rem;
	margin-bottom: 1.875rem;

	@include breakpoint($large) {
		line-height: 3rem;
		margin-bottom: 5.875rem;
	}
}

.article-subsection {
	padding: 8.5rem 2 * $cell;
	padding-left: 0;
	position: relative;
	margin-bottom: 10.5rem;
	color: $white--true;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 2 * 100vw;
		background-color: $color-primary;
		z-index: -1;
	}
}

.safeguarding-team {
	.team-member {
		line-height: 2.75rem;
		margin: 2.5rem 0;

		a {
			color: $color-primary;
			line-height: 1rem !important;
			display: block;
		}
	}

	.team-member__title {
		width: 50%;
		margin-bottom: 1rem;
	}

	.team-member__img {
		width: 16.25rem;
		height: 18.75rem;
		margin-bottom: 2rem;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.article-text {
	margin-bottom: $cell;
	@include breakpoint($large) {
		display: flex;
		flex-direction: row;
		align-items: center;

		.article-text__copy {
			width: 50%;
		}

		.article-text__img {
			padding-top: 0;
		}
	}

	&:nth-last-child(1) {
		margin-bottom: 1/2 * $cell;
	}

	h1 {
		font-size: 3.125rem;
	}

	br {
		margin-bottom: 1.5rem;
	}
}

.article-text--left {
	@include breakpoint($large) {
		.article-text__img {
			margin-left: auto;
		}
	}
}

.article-text--right {
	@include breakpoint($large) {
		flex-direction: row-reverse;

		.article-text__img {
			margin-right: auto;
		}
	}
}

.article-text__copy {
	margin-bottom: $cell;
}

.article-text__img {
	width: 4 * $cell;
	max-width: 100%;
	height: auto;
	position: relative;

	@include breakpoint($large) {
	}

	img {
		display: block;
		height: auto;
		width: 100%;
		object-fit: cover;
	}
}

.article-text__small {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding-top: 1.5rem;
}

.text-with-img {
	display: grid;
	grid-gap: $gutter;
	margin-bottom: 3.75rem;

	@include breakpoint($medium) {
		margin-bottom: 1.5 * $cell;
		grid-gap: $cell 0;
		display: flex;
		flex-direction: row;
	}

	&:nth-last-child(1) {
		margin-bottom: 0;
	}

	h2 {
		margin-bottom: 3rem;
	}

	.btn {
		margin-top: 1.75rem;
		@include breakpoint($large) {
			margin-top: 5rem;
		}
	}

	&__img {
		@include breakpoint($large) {
			margin-top: 1/4 * 6.25rem;
		}
	}

	p {
		line-height: $base-line-height;
	}
}

.text-with-img--life-event {
	display: flex;
	flex-direction: column-reverse;
	margin-bottom: 1/2 * $cell;
	padding-bottom: 1/2 * $cell;
	border-bottom: 1px solid rgba($white, 0.25);

	@include breakpoint($medium) {
		padding-bottom: 0;
		border-bottom: 0;
		margin-bottom: 1.5 * $cell;
		grid-gap: $cell 0;
		display: flex;
		flex-direction: row;
	}

	&:nth-last-child(1) {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.text-with-img:nth-child(odd) {
	.text-with-img__img {
		@include breakpoint($medium) {
			margin-left: auto;
			height: auto;
		}
	}

	.text-with-img__body {
		padding-left: 0;
	}
}

.text-with-img:nth-child(even) {
	text-align: left;

	@include breakpoint($medium) {
		flex-direction: row-reverse;
	}

	.text-with-img__body {
		padding-right: 0;
	}

	.text-with-img__img {
		margin-right: auto;
	}
}

.text-with-img__body {
	//padding: 4rem 0;
	max-width: 100%;

	@include breakpoint($medium) {
		padding: 0 $cell;
		width: 6 * $cell;
	}
}

.text-with-img__img {
	height: auto;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
	padding-bottom: 2 * $gutter;

	@include breakpoint($medium) {
		padding-bottom: 1/2 * $cell;
		width: 5 * $cell;
	}

	img {
		height: auto;
		width: 100%;
		object-fit: cover;
	}
}

.text-with-img__img-description {
	position: absolute;
	top: calc(100% - #{$gutter});
	left: 0;
	right: 0;
	text-align: center;
	font-size: 0.75rem;

	@include breakpoint($large) {
		top: 100%;
		font-size: 1.125rem;
	}
}

.text-card {
	padding-bottom: $cell;

	@include breakpoint($large) {
		padding: 0 1/2 * $cell;
	}

	h2 {
		margin-bottom: 4rem;
	}

	h3 {
		margin-bottom: 2.5rem;
	}

	> div {
		margin-bottom: 1/2 * $cell;
	}

	.date-time-info {
		padding: 0.625rem 0;
		margin-bottom: 2rem;
	}
}

.text-card--padded--2x {
	@include breakpoint($large) {
		padding: 0 $cell;
	}
}
