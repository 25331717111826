.view--contact {
	//margin-bottom: 14.375rem;

	.section--padded--tb.section--offset {
		margin-bottom: 14.375rem;
	}

	.view__body {
		padding-top: 0;

		@include breakpoint($large) {
			padding-top: 0;
		}
	}

	.team {
		margin-bottom: 14.375rem;
		padding: 0 3.25rem;
	}

	.team h1 {
		margin-bottom: 6.25rem;
	}

	.contact__cta {
		padding-left: 2 * $cell;
		position: relative;

		.cta {
			position: absolute;
			top: 14.875rem;
			left: 2 * $cell;
		}
	}

	.contact__info {
		padding-bottom: 1/2 * 14.375rem;
	}

	.contact-opening-times {
		font-weight: $semibold;
		margin-top: 3.125rem;

		@include breakpoint($large) {
			padding-top: 0;
		}

		div {
			font-weight: $bold;
			margin-bottom: 2 * 0.625rem;
			display: flex;

			&::before {
				display: none;
			}
		}

		span {
			width: $cell;
			display: block;
			font-weight: $regular;
			margin-right: 0.625rem;
			opacity: 0.75;
		}

		p {
			font-size: 1.625rem;
			line-height: 1.2;
			margin-bottom: 3rem !important;
			font-family: 'Libre Baskerville', serif;
			font-weight: 400;
			margin: 0;
			padding: 0;

			@include breakpoint($large) {
				font-size: 2rem;
			}
		}
	}
}
