/*------------------------------------*\
** RESPONSIVE FONT SIZES
\*------------------------------------*/

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == small {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}



/*------------------------------------*\
** UTILIY FUNCTION FOR FONT-SIZE MIXIN
\*------------------------------------*/

@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}



/*------------------------------------*\
** BREAKPOINTS
\*------------------------------------*/

@mixin breakpoint($name){
  @media only screen and (min-width:$name) {
    @content;
  }
}



/*------------------------------------*\
** VERTICAL CENTERING
\*------------------------------------*/

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}



/*------------------------------------*\
** CARD DEPTH
\*------------------------------------*/

@mixin card($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}



/*------------------------------------*\
** UTILITY FUNCTION FOR CARD DEPTH MIXIN
\*------------------------------------*/

@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}



/*------------------------------------*\
** UTILITY FUNCTION FOR CARD DEPTH MIXIN
\*------------------------------------*/

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}
