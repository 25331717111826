.search-result {
	display: flex;
	flex-direction: column;
	width: 19.0625rem;
	margin: 0 auto;
	margin-bottom: 2.5rem;
}

.search-result__img {
	position: relative;
	// background: red;
	height: 8.75rem;
	width: 19.0625rem;
	margin-bottom: 0.75rem;

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}

.search-result__icons {
	padding: 1rem;
	border-top: 1px solid rgba(24, 24, 24, 0.05);
	border-bottom: 1px solid rgba(24, 24, 24, 0.05);
}

.tags {
	// height: 100%;
	width: calc(100% + 2*0.3125rem);
	position: absolute;
	top: 0; left: -0.3125rem;
	padding: 0.875rem 0;
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 0.5rem 0;
	justify-content: space-between;
}

.tags__tag {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.6875rem;
	height: 1.25rem;
	background: $color-secondary;
	color: $black--true;
	font-weight: $semibold;
	letter-spacing: 0.01875rem;
	font-size: 0.625rem;
	text-transform: uppercase;

	&:nth-child(2) {
		background: $color-green--light;
	}

	&:nth-child(3) {
		background: $color-primary;
	}
}

.info-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.info-row__items {

	span {
		font-weight: $semibold;
		font-size: 1rem;
		margin: 0 0.6875rem;
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			margin:  auto 0;
			top: 0;
			bottom: 0;
			right: -0.6875rem;
			height: 3px;
			width: 3px;
			border-radius: 50%;
			background: $black;
			justify-content: center;
			align-items: center;
		}

		&:nth-child(1) {
			margin-left: 0;
		}

		&:nth-last-child(1) {
			margin-right: 0;
			
			&:after {
				display: none;
			}
		}
	}
}

.info-row__price {
	font-weight: $bold;
	font-size: 1.125rem;
	display: flex;
	padding-left: 1.875rem;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		margin:  auto 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0.3125rem 0.375rem 0.3125rem;
		border-color: transparent transparent #007bff transparent;
	}
}

.info-row__price.pos {
	&:before {
		border-color: transparent transparent $color-green transparent;
	}
}

.info-row__price.neg {
	&:before {
		border-color: transparent transparent $red transparent;
		transform: rotateZ(180deg);
	}
}