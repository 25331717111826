.calendar-wrapper {
	position: relative;
	z-index: 1;

	h2 {
		font-size: 1rem;
		font-family: $base-font-family;
	}

	.fc-scroller {
		// by deafault it's very small, about 250px
		height: auto !important;
	}

	.fc-button {
		border-radius: 0;
		border: 1px solid $color-primary;
		color: $white;
		text-transform: capitalize;
	}
	.fc-day-grid-event {
		cursor: pointer;
		transition: $transition-time;
		min-height: 0.625rem;

		&:hover {
			opacity: 0.8;
		}
	}

	.fc-time {
		font-size: 0.55rem;
		display: none;

		@include breakpoint($large) {
			font-size: 0.625rem;
		}
	}

	.fc-title {
		font-weight: $bold;
		font-size: 0.625rem;

		@include breakpoint($medium) {
			display: inline-block;
			font-size: 0.625rem;
		}
	}

	.fc-today-button[disabled] {
		opacity: 1;
		background-color: $color-accent;
		border-color: $color-accent;
	}

	.fc-today-button {
		background-color: $color-primary;
	}

	.fc-button:hover,
	.fc-button:focus {
		box-shadow: none;
		transform: none;
		background: $color-primary;
		color: $white;
	}

	.fc-button:active {
		background: lighten($color-primary, 10%);
	}

	.rbc-toolbar-label {
		text-align: right;
	}

	.fc-toolbar {
		flex-wrap: wrap;

		h2 {
			width: $cell;
			white-space: nowrap;
		}
	}

	.fc-list-item {
		border-left-style: solid;
		border-left-width: 10px;
	}

	.fc-list-heading {
		font-weight: $semibold;
		background: transparent;

		> td {
			background: rgba($color-primary, 0.5);
			color: $white;
		}
	}

	.fc-list-table {
		tbody {
			display: flex;
			flex-direction: column;
		}

		tr {
			width: 100%;
			display: flex;
			cursor: pointer;
			margin-bottom: 0.5rem;

			td {
				width: 100%;
				border-top: none;
				border-bottom: 1px solid $color-gray--lighter;
			}
		}

		.fc-widget-header {
			width: 100%;
			border: none;
		}

		td {
			display: flex;
			align-items: center;
			padding: 0.625rem 1rem;
			border-right: 0;
			border-left: 0;
			font-size: 0.875rem;

			@include breakpoint($large) {
				font-size: 1rem;
			}
		}

		.fc-list-item-marker {
			display: none;
		}

		.fc-list-item-time {
			font-size: 0.625rem;
			padding-right: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 4.5rem;

			@include breakpoint($large) {
				font-size: 0.75rem;
				flex-direction: row;
				width: 2 * $cell;
				justify-content: space-between;
			}
		}

		.fc-list-item-title {
			text-align: right;
			justify-content: flex-end;
		}
	}

	.fc-list-heading-alt {
		margin-left: 0.625rem;
		font-weight: $regular;
		opacity: 0.85;
	}
}

.calendar-wrapper__actions {
	display: flex;
	flex-direction: column;
	margin-top: 1.5rem;

	@include breakpoint($large) {
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.btn {
		margin-bottom: 0.625rem;
		flex: 1;

		@include breakpoint($large) {
			margin-bottom: 0;
			margin-left: 0.625rem;
		}

		&:nth-child(1) {
			margin-left: 0;
		}

		img {
			height: auto;
			width: 1.5rem;
			object-fit: contain;
			margin-left: 0.625rem;
			filter: brightness(0) invert(1);
		}
	}
}

.calendar-wrapper__filters {
	font-size: 0.75rem;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	padding: 0;

	li {
		margin: 0.25rem 0.625rem;
		white-space: nowrap;
		list-style-type: none;

		&::before {
			display: none;
		}

		button {
			padding: 0.5rem 0.625rem;
			font-size: inherit;
			background: none;
		}
	}
}

.scroller {
	width: 100vw;
	overflow-x: scroll;
	display: flex;
	margin-left: 50%;
	transform: translateX(-50%);
	padding-left: 2.1875rem;
}

.calendar-wrapper--s {
	@include breakpoint($large) {
		display: none;
	}
}

.service {
	margin-bottom: 2.5rem;
	.bold {
		font-size: 1.5rem;
		margin-bottom: 0.625rem;
	}
}

.event-about {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	margin-bottom: 0.625rem;
	position: relative;
	padding: 0.625rem;
	padding-left: 0.75rem;
	padding-right: 2rem;
	border-radius: 0;
	width: 100%;
	border-width: 0.125rem;
	border-left-width: 0.375rem;
	background: none;

	@include breakpoint($large) {
		// margin-bottom: $cell;
		padding-right: $cell;
	}

	&:nth-child(1) {
		margin-top: 1.2rem;
	}

	&:hover {
		transform: none;
		box-shadow: none;
	}

	&:focus,
	&:active {
		border-width: 0.125rem;
		border-left-width: 0.375rem;
	}
}

.event-about__icon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0.625rem;
	opacity: 0.85;
	margin: auto;
	height: 100%;
	width: 1rem;
	display: flex;
	align-items: center;

	* {
		width: 100%;
	}
}

.event-about__title {
	margin-bottom: 0;
	font-size: 0.875rem;
	line-height: 1.45;
}

.event-about__color {
	background-color: $midnight-blue;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0.375rem;
}

.event-about__img {
	height: 1/2 * 9.375rem;
	width: 1/2 * 9.375rem;
	margin-bottom: 1.5rem;
	border-radius: 50%;
	overflow: hidden;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.event-about__description {
	font-size: 0.75rem;
}

.services-wrap {
	overflow-y: auto;
	// max-height: 25rem;
	// border-bottom: 1px solid $color-gray--lighter;
	border-top: 1px solid $color-gray--lighter;
	margin-bottom: 2rem;
	padding-right: 1rem;

	@include breakpoint($large) {
		// max-height: 47.7875rem;
		border: none;
		// border-bottom: 1px solid $color-gray--lighter;
		margin: none;
	}
}
