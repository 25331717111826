.clearfix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.right {
  float: right;
}

.left {
  float: left;
}

.no-padding {
  padding: 0;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.center-inblock {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.show {
  visibility: visible;
  opacity: 1;
}

.hide {
  visibility: hidden;
  opacity: 0;
}






.full--height {
  height: 100%;
}

.full--height-vh {
  height: 100vh;
}

.full--width {
  width: 100%;
}

.full--width-vw {
  width: 100vw;
}

.shade {
  background-color: $clouds;
}

.no-scroll {
  overflow: hidden;
}
