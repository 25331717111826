.input--datepicker {}


// CHECKBOX 

input[type="checkbox"] {
    width: auto;
    margin-right: 1.25rem;
    cursor: pointer;

    & + label {
        cursor: pointer;
        line-height: normal;
    }
}

.input--checkbox__wrap {
	display: flex;
	align-items: flex-start;
    margin-bottom: 1.5rem;
    line-height: normal;
    
}

.input--checkbox label {
	line-height: 1.5;
}

.input--checkbox {}

.input--checkbox:checked,
.input--checkbox:not(:checked) {
    position: absolute;
    left: -624.938rem;
}
.input--checkbox:checked + label,
.input--checkbox:not(:checked) + label
{
    position: relative;
    padding-left: 0;
    cursor: pointer;
    line-height: 1.25rem;
    display: inline-block;
    width: 100%;
}
.input--checkbox:checked + label:before,
.input--checkbox:not(:checked) + label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid $color-gray;
    border-radius: 0.125rem;
    background: $white--true;
}
.input--checkbox:checked + label:after,
.input--checkbox:not(:checked) + label:after {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    background-color: $color-primary;
    // background: image-url('..images/icons/icon-checkbox.svg')no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0.125rem;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.input--checkbox:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.input--checkbox:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

// RADIO BTN 

.input--radio__wrap {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
}

.input--radio label {
	line-height: 1.5;
}

.input--radio {}

.input--radio:checked,
.input--radio:not(:checked) {
    position: absolute;
    left: -624.938rem;
}
.input--radio:checked + label,
.input--radio:not(:checked) + label
{
    position: relative;
    padding-left: 0;
    cursor: pointer;
    line-height: 1.25rem;
    display: inline-block;
    width: 100%;
}
.input--radio:checked + label:before,
.input--radio:not(:checked) + label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid $color-gray;
    border-radius: 100%;
    background: $white--true;
}
.input--radio:checked + label:after,
.input--radio:not(:checked) + label:after {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    background-color: $color-primary;
    // background: image-url('icons/icon-radio.svg')no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.input--radio:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.input--radio:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.input--select {
	border: none;
	padding: 0;
	padding-right: 1.25rem;
	margin: 0;
	line-height: 1.85;
	font-weight: $semibold;
	font-size: 0.8125rem;
	-webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background: transparent;
}

.input--select__wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 1.25rem;
	margin-bottom: 1.25rem;
	border-bottom: 1px solid rgba(24, 24, 24, 0.05);

	& > div {
		// background: image-url('icons/icon-arrow-down.svg') no-repeat;
		background-position: center right; 
	}
	
}

.input--select__label	{
	line-height: 1.5;
}

.input--toggle {}

.input--url {}

.input--password {}

.input--mail {}

.input--attachment {}

.input--icon {}

.input--rangeslider {}

.input--disabled {}

.input--small {}

.input--large {}

.input--price-range {
	color: $color-gray;
	line-height: 1.5;
	padding-left: 10.1875rem;
	padding-bottom: 0.4375rem;
	border: none;
	padding: 0;
	border-bottom: 1px solid rgba(24, 24, 24, 0.05);
	text-align: right;
}

.input--price-range__wrap {
	max-width: calc(305/375*10vw);
	display: flex;
	justify-content: space-between;

	@include breakpoint ($large) {
		max-width: calc(305/1920*100vw);
	}
}

.input--price-range__label {
	line-height: 2;
	font-weight: 500;
	padding-bottom: 0.4375rem;
	display: block;
}