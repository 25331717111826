.breadcrumbs {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;

	div,
	a {
		padding-right: 1rem;
		margin-right: 1rem;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 100%;
			margin: auto 0;
			display: block;
			height: 0.25rem;
			width: 0.25rem;
			max-height: 0.25rem;
			max-width: 0.25rem;
			border-radius: 50%;
			background-color: $black;
		}

		&:nth-last-child(1):after {
			display: none;
		}
	}

	a {
		font-weight: 500;
		color: $black;
	}
}
