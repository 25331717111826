.nav {
	width: 100%;
	background: $white--true;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	z-index: 1000;
	transition: $transition-time;

	.layout--main {
		width: 100%;

		@include breakpoint ($xlarge) {
			max-width: calc(100% - 2 * 3.375rem);
		}

		@include breakpoint ($xxlarge) {
			max-width: calc(100% - 2 * (100% / 12));
		}
	}
}

.subnav {
	z-index: 10;
	position: relative;
	margin-top: 1rem;
	font-weight: $semibold;
	text-transform: uppercase;
	background-color: $white--true;
	white-space: nowrap;

	a {
		color: $black;
		transition: $transition-time;
		margin-right: 0.625rem;
		font-size: 0.625rem;
		transition: 0.3s;

		@include breakpoint($large) {
			font-size: 0.875rem;
			margin-right: 2 * $gutter;
			display: inline-block;
		}
	}

	a.subnav__tab {
		font-size: 0.75rem;
		font-weight: 500;
		position: relative;
		transform: translateY(0.3125rem);
		opacity: 0.5;
		text-transform: capitalize;

		@include breakpoint($medium) {
			font-size: 0.875rem;
		}

		@include breakpoint($large) {
			font-size: 1.125rem;
			transform: translateY(0.625rem);
		}

		&.active {
			opacity: 1;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 0.125rem;
				background: $black;
			}
		}
	}
}

.logo-subtitle {
	font-size: 0.625rem;
	padding-right: 2rem;
	font-family: $base-font-family;
	display: none;

	@include breakpoint($large) {
		display: block;
		line-height: 1rem;
		font-size: 0.875rem;
		padding: 0;
		margin: 0;
		margin-right: auto;
	}

	>div {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0;
		opacity: 1;

		>div {
			display: flex;
			flex-wrap: wrap;
		}

		div {
			list-style-type: none;
			line-height: 1.25;
			font-weight: $semibold;
			font-size: 0.875rem;
			margin-right: 0.625rem;


			&::before {
				display: none;
			}

			&:nth-last-child(1) {
				margin-right: 0;
			}
		}

		span {
			opacity: 0.5;
			font-weight: $regular;
			font-size: 0.95em;
		}

		div:nth-last-child(1) {
			border-right: none;
		}
	}
}

.logo-subtitle p {
	margin: 0;
	width: 100%;
	font-size: 0.75rem;
	margin-bottom: 1/2 * 0.625rem;
}

.opening-times-button {
	cursor: pointer;
	text-transform: capitalize;
	font-size: 0.625rem;
	padding: 0.625rem 0.875rem;
	margin-right: 1.375rem;

	@include breakpoint($large) {
		background-color: $color-secondary;
		padding: 0.75rem 1.375rem;
		font-size: 0.75rem;
	}

	img {
		width: 0.75rem;
		height: 0.75rem;
		object-fit: contain;
		margin-right: 0.625rem;

		@include breakpoint($large) {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

.opening-times-dropdown {
	position: fixed;
	top: auto;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #FFF;
	z-index: 1000;
	gap: 1rem;
	display: table;
	white-space: nowrap;
	padding: 2rem 2.5rem;
	padding-bottom: 2rem;
	box-shadow: 0px 0px 2.25rem 99999px rgba($black, 0.65);
	font-size: 0.75rem;
	height: auto;
	margin: auto;
	border-top: 6px solid $color-secondary;
	width: 100%;
	min-width: 300px;

	@include breakpoint($medium) {
		top: 0;
		font-size: 1rem;
		width: auto;
	}

	> div {
		display: grid;
		grid-template-columns: 2rem auto;
		font-size: inherit;

		@include breakpoint($medium) {
			grid-template-columns: 3.75rem auto;
		}

		& + div {
			margin-top: 1rem;
		}
	}

	span {
		opacity: 0.5;
		font-weight: $regular;
	}
}

.opening-times-dropdown__close {
	height: 2rem;
	width: 2rem;
	z-index: 1;
	position: absolute;
	top: 0.625rem;
	right: 0.625rem;
	padding: 0;
	background: none;
	font-size: 1.25rem;
	transform: none !important;
	box-shadow: none !important;
}

.headroom {
	position: relative;
	z-index: 999 !important;
	.nav__top {
		transition: $transition-time;
	}
}

.headroom--unpinned {
	position: fixed;
}

.logo-subtitle {
	transition: $transition-time;
}

.nav-offset {
	width: 100%;
	padding-top: 12.25rem;
	transition: $transition-time;
}

.headroom-wrapper {
	position: relative;
	margin-bottom: 0;
}

.headroom {
	position: fixed;
	top: 0;
	left: 0;
}

.headroom--pinned,
.headroom--unpinned {

	.nav {
		padding: 0.5rem;

		@include breakpoint($large) {
			padding: 1rem 0;
			box-shadow: 0px 0px 6px rgba($black, 0.3);
			transform: translateY(-4rem);

			.nav__logo {
				width: 12rem;
			}
		}
	}

	.search-form-wrap {
		opacity: 0;
		pointer-events: none;
	}

	.subnav {
		transform: none;
		margin-top: 1rem;
		margin-bottom: 0;

		a {
			font-size: 0.625rem;

			@include breakpoint($large) {
				font-size: 0.875rem;
				transform: translateY(-0.25rem);
			}
		}
	}
}

.nav__wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 6.75rem;
}

.nav__section {
	display: flex;
	align-items: center;
	width: 50%;
}

.nav__section--right {
	justify-content: flex-end;
}

.nav__link--main {
	color: $color-primary;
	font-size: 1.5rem;
	font-weight: $bold;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}

.nav__link-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 0.625rem;
	width: 0.4375rem;
}

.nav__links {
	display: flex;
}

.nav__link {
	position: relative;
	transition: $transition-time;
	display: inline-block;
	color: $black;
	display: inline-block;
	text-transform: uppercase;
}

.nav__logo {
	font-family: $heading-font-family;
	font-weight: $bold;
	color: $black;
	transition: $transition-time;
	display: inline-block;
	max-width: 4 * $cell;
	white-space: nowrap;

	&:hover,
	&:focus,
	&:active {
		color: $color-primary;
	}

  img {
    width: 6.25rem;
  }
}

.nav--desktop {
	//  position: relative;
	padding: 2.875rem 0;
	display: none;

	@include breakpoint($large) {
		display: block;
	}

	.nav__top {
		width: 100%;
		height: 3.75rem;

		.social-icons {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-weight: 500;
			text-transform: uppercase;
			font-size: 0.75rem;
			margin-left: 2 * 0.625rem;

			a {
				margin: 0 0.5rem;
				width: 0.875rem;
				transition: $transition-time;

				&:hover {
					opacity: 0.7;
				}
			}

			img {
				height: 1rem;
				width: 1rem;
				object-fit: contain;
				margin: 0;
			}

			span {
				margin-right: 0.75rem;
			}
		}

		.icon {
			margin-right: 0.8rem;
		}

		.btn {
			margin-right: 0.625rem;
			white-space: nowrap;
		}
	}

	.nav__top-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-weight: $bold;
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1;

		> .nav__link {
			padding-right: 2 * 0.625rem;
			margin-right: 0;
			border-right: 1px solid $black--true;
		}

		.nav__link {
			font-size: inherit;
			text-transform: uppercase;
			font-weight: 500;
		}

		.nav__link.active {
			color: $color-secondary;

			&:hover {
				color: $color-primary;
			}

			&:after {
				display: none;
			}
		}
	}

	.nav__logo {
		font-size: 2.25rem;
		line-height: 4rem;
	}

	.nav__link {
		margin: 0 1.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
		transition: $transition-time;

		&:after {
			content: '';
			display: block;
			z-index: 0;
			height: calc(100% + 2 * 0.375rem);
			width: calc(100% + 2 * 1.5rem;);
			background: $color-primary;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto 0;
			margin-left: 50%;
			transform: translateX(-50%) scale(0);
			border-radius: 0.375rem;
			opacity: 0;
			transition: $transition-time;
		}

		span {
			position: relative;
			z-index: 1;
		}
	}

	.nav__link.active {
		color: $white--true;

		&:after {
			opacity: 1;
			transform: translateX(-50%) scale(1);
			background-color: $color-primary;
		}
	}

	.nav__link:hover {
		color: $color-secondary;
	}

	.nav__link.active:hover {
		color: $white--true;
		opacity: 0.8;
	}

	.nav__link:nth-last-child(1) {
		margin-right: 0;
		padding-right: 0;
	}
}

.nav--mobile {
	@include breakpoint($large) {
		display: none;
	}

	.nav__logo--wrap {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.5rem;
	}

	.nav-burger__main {
		//display: none;
		position: absolute;
		top: 0;
		left: 0;
		transition: $transition-time;
		z-index: 20;
		background: $white--true;
		opacity: 0;
		pointer-events: none;
		transform: translateY(-100%);
		height: 100vh;
		width: 100%;
		overflow: auto;
	}

	.nav__logo {
		font-size: 1.25rem;

    img {
      width: 4rem;
    }
	}

	.burger-icon {
		display: block;
		position: relative;
		height: 1.25rem;
		width: 1.25rem;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 1.25rem;
			//height: 0.375rem;
			height: 2px;
			background: $black;
			transition: $transition-time;
		}

		&:before {
			transform: translateY(1/4 * 1.25rem);
		}

		&:after {
			transform: translateY(-1/4 * 1.25rem);
		}
	}

	.nav-burger .nav__links {
		display: flex;
		flex-direction: column;
	}

	.nav-burger .nav__link {
		font-size: 1.375rem;
		padding-bottom: 1rem;

		&:nth-last-child(1) {
			padding-bottom: 0;
		}
	}

	&.burger-active {
		.nav__logo {
			opacity: 0;
		}

		.burger-icon {
			z-index: 21;

			&::before {
				transform: rotateZ(-45deg);
			}

			&::after {
				transform: rotateZ(45deg);
			}
		}

		.nav-burger__main {
			padding: 9rem 0;
			opacity: 1;
			pointer-events: all;
			transform: translateY(0);
		}
	}

	.social-icons {
		padding-top: 2 * 1.25rem;
		margin-top: 2 * 1.25rem;
		border-top: 1px solid $black--true;

		a {
			display: inline-block;
		}
	}

	.social-icons span {
		display: block;
		text-transform: uppercase;
		font-size: 0.75rem;
		padding-bottom: 1.5rem;
		color: $black--true;
	}

	.social-icons a {
		height: 1.5rem;
		width: 1.5rem;
		padding: 0;
		margin-right: 1.25rem;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
}

.back-to-top {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	background: #0f4160;
	text-transform: capitalize;
	color: #ffffff;
	z-index: 5;
	border-radius: 50%;
	padding: 0;
	height: 3rem;
	width: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
	font-size: 2rem;

	@include breakpoint($large) {
		display: none;
	}
}

.important-link {
	margin-bottom: 2rem;
	font-size: 1.125rem;
}

.search-form-wrap {
	> button {
		margin-left: $gutter;
		background: transparent;
		height: 3rem;
		width: 3rem;
		border-radius: 50%;
		padding: 0;

		&:hover {
			box-shadow: none;
			transform: none;
			opacity: 0.75;
		}

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;

			path,
			circle {
				stroke-width: 2px;
			}
		}
	}

	.search-form-wrap__form {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		transform: translateY(-20%);
		opacity: 0;
		visibility: hidden;
		transition: $transition-time;
		background-color: #ffffff;
		z-index: 1000;
		padding: 2rem;

		input {
			border: 2px solid $color-secondary;
			padding: 1rem;
			margin: 0;
			border-radius: 0.625rem;
		}

		fieldset {
			margin: 0;
			display: flex;
			gap: 1rem;
		}

	}

	.search-form-wrap__close {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0.5rem;
		height: 100%;
		width: 2 * $gutter;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 3rem;
		box-shadow: none;
		background: transparent;
		color: $color-secondary;

		&:hover {
			box-shadow: none;
			transform: none;
		}
	}

	&.active {
		.search-form-wrap__form {
			transform: translateY(0);
			opacity: 1;
			visibility: visible;
			box-shadow: 0px 0px 10px rgba($black, 0.3);
		}
	}
}
