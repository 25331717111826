/*------------------------------------*\
** FOOTER
\*------------------------------------*/

.footer {
	padding-top: 1/2 * $cell;

	@include breakpoint($large) {
		padding-top: 1/2 * 14.375rem;
	}
}

.footer__logos {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;

	@include breakpoint($large) {
		margin-top: 1rem;
		margin-bottom: 2.5rem;
		justify-content: center;
		align-items: center;
	}
}

.footer__logos > div {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	@include breakpoint($large) {
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	a {
		display: block;
		height: 100%;
		height: 2rem;
		padding: 0;
		margin-right: 1.5rem;

		@include breakpoint($large) {
			margin: 0 1.5rem;
			padding: 1rem 0;
			height: 5rem;
		}

		&:nth-child(1) {
			transform: translateY(0.25rem);
		}

		&:nth-child(2) {
			height: 3.5rem;

			@include breakpoint($large) {
				height: 6.5rem;
			}
		}
	}

	img {
		min-height: 100%;
		height: 100%;
		width: auto;
		object-fit: contain;
	}
}

.logo-digital {
	display: flex;
	color: $black;
	font-size: inherit;
	margin-bottom: 3rem;
	flex-direction: column;

	@include breakpoint($large) {
		flex-direction: row;
		align-items: center;
		margin-bottom: 0;
	}

	img {
		width: 3.5rem;
		height: auto;
		object-fit: contain;
		margin-top: 1rem;

		@include breakpoint($large) {
			margin-top: 0;
			width: 2.5rem;
			margin-left: 1.5rem;
		}
	}
}

.footer__body {
	padding-top: 1/2 * 8.625rem;
	padding-bottom: 3rem;
	background: $color-tertiary;

	@include breakpoint($large) {
		padding-top: 8.625rem;
		padding-bottom: 3rem;
	}
}

.footer__bottom {
	font-size: 0.875rem;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	color: $black;

	@include breakpoint($large) {
		flex-direction: row;
		align-items: center;
	}

	.footer__link,
	.social-link {
		display: inline-block;
		margin-right: 3rem;
		font-size: inherit;

		&:nth-last-child(1) {
			margin-right: 0;
		}
	}

	.footer__links {
		margin-bottom: 1.875rem;

		@include breakpoint($large) {
			margin-bottom: 0;
		}
	}
}

.footer__group {
	line-height: 2rem;
	margin-bottom: 1/4 * $cell;
	padding-bottom: 1/4 * $cell;
	border-bottom: 1px solid rgba($color-primary, 0.25);

	@include breakpoint($large) {
		margin-bottom: 0;
		padding-bottom: 0;
		border: none;
	}

	p {
		line-height: inherit;
		margin: 0;

		@include breakpoint($large) {
			margin-top: 0;
			margin-bottom: 1.875rem;
		}
	}
}

.footer__group--row {
	display: grid;
	grid-gap: 1/2 * $cell;

	@include breakpoint($large) {
		grid-template-columns: 1fr 1fr;
	}

	p {
		margin-right: $cell;
	}
}

.footer__link,
.social-link {
	display: flex;
	align-items: center;
	color: $black;
	transition: $transition-time;
	font-size: 0.75rem;

	@include breakpoint($large) {
		font-size: 1rem;
	}

	img,
	svg {
		height: 1rem;
		width: 1rem;
		object-fit: contain;
		margin-right: 1rem;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color-primary;
	}
}

/*------------------------------------*\
** COPYRIGHT
\*------------------------------------*/

.copyright {
	font-size: 0.625rem;

	@include breakpoint($large) {
		font-size: 0.875rem;
	}
}

// CREATE A STICKY FOOTER WITH FLEXBOX
