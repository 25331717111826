.header--full {
  background-color: $color-primary;
  padding: $gutter;
}

.header--full__link {
  font-weight: $bold;
  color: $white--true;
  display: flex;
  align-items: center;
}

.header--full__arrow {
  height: 1rem;
  margin-right: 1/2*$gutter;
}
