/*------------------------------------*\
** GLOBAL
\*------------------------------------*/

html {
	font-family: $base-font-family;
	font-size: calc(16 / 375 * 100vw);
	line-height: $base-line-height;
	color: $base-font-color;
	overflow-y: scroll;

	@include breakpoint($medium) {
		font-size: calc(16 / 900 * 100vw);
	}

	@include breakpoint($large) {
		font-size: calc(16 / 1440 * 100vw);
	}

	@include breakpoint($xlarge) {
		font-size: calc(16 / 1680 * 100vw);
	}

	@include breakpoint($xxlarge) {
		font-size: calc(16 / 1920 * 100vw);
	}

	&.burger-is-active {
		overflow: hidden;
	}
}

body {
	box-sizing: border-box;
	margin: auto;
}

.main {
	overflow: hidden;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	&::-moz-focus-inner {
		border: 0;
		outline: none;
	}
}

img {
	max-width: 100%;
	display: block;
}

.post__image {
	-webkit-touch-callout: none !important;
	pointer-events: none; // for Android
	-webkit-touch-callout: none; // for iOS
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a:active,
a:focus {
	outline: 0;
	//border: none;
	-moz-outline-style: none;
}

/*a:not(.nav__logo):focus {
  pointer-events: none;
}*/

div.cookieConsent {
	background-color: $white !important;
	color: $black !important;

	button {
		border: none;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-size: 0.75rem;
		line-height: normal;
		font-weight: $regular;
		padding: 0.75rem 1.375rem !important;
		transition: $transition-time;
		border: 1px solid transparent;
		border-radius: 0.375rem !important;
		overflow: hidden;
		position: relative;
		outline: none;
		background-color: $color-accent !important;
		color: $white--true !important;
		border-color: $color-accent;

		@include breakpoint($large) {
			font-size: 1rem;
		}

		&:focus {
			outline: none;
			border-width: 1px;
			box-shadow: 0 0 0px 0px transparent;
		}

		&:hover {
			cursor: pointer;
			transform: translateY(-0.3125rem);
			box-shadow: 0.25rem 0 0.625rem rgba($black, 0.25);
		}

		&:focus,
		&:active,
		&:hover {
			background-color: rgba($color-accent, 0.9) !important;
			border-color: rgba($color-accent, 0.9);
			color: $white--true;
		}
	}
}


.loading-block {
	height: 100vh;
	width: 100%;
	background: $color-gray--lighter;
}

.splash-screen {
	display: block;
	position: fixed;
	top: 0;
	z-index: 9999;
	background: $color-primary;
	color: $white;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: $transition-time;
	font-family: $heading-font-family;
	font-size: 2rem;

	p {
		font-family: inherit;
		font-size: inherit;
	}
}

.not-found {
	height: 100%;
	width: 100%;
	background: $white;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
		font-size: 4rem;
		flex-direction: column;
	padding: 6.25rem 2.1875rem;
	text-align: center;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 100;

	h1 {
		margin-bottom: 1rem;
		font-size: 2.5rem;

		@include breakpoint($large) {
			font-size: 4rem;
		}
	}

	p {
		color: $color-gray--darker;
		font-size: 1.125rem;

		@include breakpoint($large) {
			font-size: 1.35rem;
			margin-bottom: 3rem;
		}
	}
}

.error-msg {
	padding: 8.75rem 0;
}
