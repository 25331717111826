/*------------------------------------*\
** FORMS
\*------------------------------------*/

.validation-error {
	display: inline-block;
	font-size: 0.8rem;
}

.label {
	font-size: 0.8rem;
	text-transform: uppercase;
}

.input {
	width: 100%;
	outline: none;
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	padding: 1/2 * $gutter;
}

.input__wrapper {
	margin-bottom: 1/2 * $gutter;
	margin-top: 1/2 * $gutter;
}

.input--login__wrapper {
	position: relative;
}

.input--login__icon {
	position: absolute;
	left: 1/2 * $gutter;
	top: 1/2 * $gutter;
	width: 16px;
}

.input--login {
	background-color: rgba($white--true, 0.2);
	color: $white--true;
	padding-left: 2 * $gutter;
	border-bottom: 2px solid $white--true;
}

.input--register {
	background-color: rgba($color-gray, 0.05);
	color: $black;
}

.input--dob__wrapper {
	display: flex;
	flex-wrap: nowrap;
}

.input--dob--limiter {
	width: 20%;
	margin-right: 3px;
}

.input--dob--unlimiter {
	width: 100%;
}

.input--dob {
	outline: none;
	border: none;
	background-image: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	padding: 1/2 * $gutter;
	background-color: rgba($color-gray, 0.05);
	color: $black;
}

.input--toggle__wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.input--toggle {
	outline: none;
	border: none;
	background-image: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background-color: rgba($color-gray, 0.05);
	color: $black;
	width: 50%;

	&:not(last-child) {
		margin-right: 3px;
	}
}

.gender {
	padding: 1/2 * $gutter;
	font-size: 0.8rem;
}

.gender--selected {
	background-color: $color-primary;
	color: $white--true;
}

.recents-search__wrapper {
}

.recents-search {
	input {
		padding: $gutter;
		width: 100%;
		outline: none;
		border: none;
		background-image: none;
		background-color: transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}

.newsletter-form {
	display: flex;
	justify-content: space-between;
	width: 26rem;
	max-width: 100%;
	margin: 0 auto;
	border-bottom: 0.125rem solid $color-gray;

	@include breakpoint($large) {
	}
}

.newsletter-form__input {
	width: 100%;
	padding: 0;
	padding-right: 0.75rem;
	font-style: italic;
	background: transparent;
	outline: none;
	border: none;
	font-size: 1.125rem;

	&:placeholder {
		color: $color-gray--light;
		font-weight: $regular;
	}
}

.newsletter-form__btn {
	background: transparent;
	border: none;
	outline: none;
	color: $color-primary;
	padding: 0.75rem;
	padding-right: 0;
}

.form {
	.form__fieldset {
		margin: 0;
		margin-bottom: 2.25rem;
		border: none;
		outline: none;
		padding: 0;
	}

	label {
		display: block;
		margin-bottom: 0.875rem;
		font-size: 1.125rem;

		&.no-margin {
			margin-bottom: 0;
		}
	}

	.input,
	input,
	.textarea {
		border: none;
		outline: none;
		width: 100%;
		resize: none;
		font-size: 1.375rem;
		background-color: $white--true;
	}

	input[type="submit"] {
		@extend .btn;
		@extend .btn--primary;
	}

	.input--text,
	[type="text"] {
		padding: 0;
		height: 3.75rem;
		width: 100%;
	}

	.textarea {
		padding: 1.25rem;
		width: 100%;
	}
}

.form__status {
	font-size: 1rem;
	font-weight: $semibold;
	color: $white;
	text-align: center;
	padding: 0.625rem;
	border-radius: 0.375rem;
	margin-top: 2 * 1.25rem;
	text-transform: capitalize;
}

.form__status--ok {
	background-color: $color-green--light;
}

.form__status--error {
	background-color: $color-alert;
}

.contact-form {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: $cell;

	@include breakpoint($large) {
		grid-template-columns: 1fr 1fr;
	}

	p {
		line-height: 2.25rem;
		font-size: 1.375rem;
		margin-bottom: 5rem;

		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}

	strong,
	em {
		font-weight: $regular;
	}
}

.contact-form__info {
	margin-right: 0;
}

.contact-form__main {
	@include breakpoint($large) {
	}
}

.form--border {
	.input,
	.textarea {
		border: 1px solid $black;
		border-radius: 0.375rem;
		padding: 1.25rem;
	}

	.input {
	
	}

	.btn {
		height: 3.75rem;
		font-size: 1.125rem;
	}
}


.mailchimp-form {

	form {
		display: flex;
		flex-direction: column;
	}

	input {
		border: $input-border;
		outline: none;
		margin-bottom: 0.625rem;
		padding: $input-padding;
		border-radius: $input-radius;
	}

	button {
		background: $color-accent;
		color: $white;
		margin: 0 auto;
		margin-top: 1.25rem;
	}

	.msg-alert {
		text-align: center;
		margin-top: 1.5rem;

		p {
			margin: 0;
		}
	}
}