/*------------------------------------*\
# LAYOUT
\*------------------------------------*/

.display {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: $gutter;

	max-width: $medium;
	margin-left: auto;
	margin-right: auto;
}

.display--between {
	min-height: 100vh;
	justify-content: space-between;
}

.display--stretch {
	align-items: stretch;
}

.block--full {
	width: 100%;
}

.flex {
	display: flex;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-end {
	justify-content: flex-end;
}

.display--columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: $gutter;
}

.layout {
	&--text {
		max-width: $text-width;
		margin-left: auto;
		margin-right: auto;
		padding-left: $gutter;
		padding-right: $gutter;
	}

	&--main {
		max-width: 19.0625rem;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint($medium) {
			max-width: 51.25rem;
		}

		@include breakpoint($large) {
			max-width: 78rem;
		}

		iframe {
			border: none;
			width: 100%;
		}
	}

	&--full {
		width: 100%;
		padding-left: $gutter;
		padding-right: $gutter;

		@include breakpoint($medium) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&--padded {
		padding-top: $gutter;
		padding-bottom: $gutter;

		@include breakpoint($medium) {
			padding-top: 2 * $gutter;
			padding-bottom: 2 * $gutter;
		}
	}

	&--padded--double {
		padding-top: $gutter;
		padding-bottom: $gutter;

		@include breakpoint($medium) {
			padding-top: 4 * $gutter;
			padding-bottom: 4 * $gutter;
		}
	}

	&--soft-horizontal-pad {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}

.section {
	position: relative;
	transition: $transition-time;
}

.section__bg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: $color-primary;
	z-index: -1;

	img {
		height: 100%;
		width: 100%;
		transition: $transition-time;
		object-fit: cover;
		z-index: 0;
		position: relative;
		opacity: 0.8;
	}
}

.section__bg--padded {
	img {
		height: calc(100% - 18.75rem);
	}
}

.section__bg--half {
	width: 50%;
}

.section__bg--right {
	left: auto;
	right: 0;
}

.section__bg--padded {
}

.subsection {
	margin-bottom: $gutter;
}

.subsection--padded {
	padding: 0 $cell;
}

.subsection--padded--l {
	padding-left: $cell;
}

.subsection--text {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 100%;
}

.subsection__body {
	height: 100%;
	margin-bottom: $cell;

	p {
		font-size: 1rem;
	}

	h2 {
		margin-bottom: 5rem;
	}
}

.subsection__img {
	height: 100%;
	width: 100%;
	display: block;

	img {
		height: 20rem;
		width: 100%;
		object-fit: cover;
	}
}

.section--header {
	padding-bottom: 3.75rem;

	@include breakpoint($large) {
		padding-bottom: 5rem;
	}

	p {
		margin: 0;
	}
}

.section--double {
	margin-bottom: 2 * $gutter;

	@include breakpoint($medium) {
		margin-bottom: 4 * $gutter;
	}
}

.section--dark {
	background-color: $black;
	color: $color-primary;
}

.section--primary {
	background-color: $color-primary;
}

.section--light {
	background-color: #dfe8f1;
}

.section--offset {
	background: transparent;
	position: relative;
}

.section--offset:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin: 0 auto;
	transform: translateX(-50%);
	height: 100%;
	width: 2 * 100vw;
	z-index: -1;
	background-color: $color-tertiary;
}

.section--offset.section--primary::after {
	background: $color-primary;
}

.section--center-inblock {
	text-align: center;
}

.section--padded--tb {
	padding: 0.5 * 6.25rem 0;

	@include breakpoint ($large) {
		padding: 0.85 * 6.25rem 0;
	}
}

.section--padded--bottom {
	padding-bottom: $cell;
}

.section--padded--tb--2x {
	padding: 3.25rem 0;

	@include breakpoint ($large) {
		padding: 1.5 * 6.25rem 0;
	}
}

.section--padded {
	@include breakpoint($large) {
		padding-left: $cell;
		padding-top: 6.25rem;
		padding-bottom: 5.25rem;
	}
}

.section--color-tertiary {
	background-color: $color-secondary;
}

.section__title {
	h1 {
		margin-bottom: 5rem;
	}
}

/*------------------------------------*\
# GRID
\*------------------------------------*/

.grid {
	display: flex;
	flex-wrap: wrap;

	&--reverse-mobile {
		flex-wrap: wrap-reverse;

		@include breakpoint($large) {
			flex-wrap: wrap-reverse;
		}
	}
}

.no-wrap {
	flex-wrap: no-wrap !important;
}

.grid--gutters {
	margin-left: calc(#{$gutter} / -2);
	margin-right: calc(#{$gutter} / -2);
}

.grid--gutters > .cell {
	padding-left: calc(#{$gutter} / 2);
	padding-right: calc(#{$gutter} / 2);
}

.grid--gutters--double {
	margin-left: -$gutter;
	margin-right: -$gutter;
}

.grid--gutters--double > .cell {
	padding-left: $gutter;
	padding-right: $gutter;
}

.cell {
	box-sizing: border-box;
}

.cell--auto {
	flex: 1;
}

@for $i from 1 through 12 {
	.cell--#{$i} {
		flex-shrink: 0;
		flex-grow: 0;
		width: calc(100% * (#{$i} / 12));
	}
}

@each $key, $value in $breakpoints {
	@include breakpoint($value) {
		@for $i from 1 through 12 {
			.cell--#{$i}--#{$key} {
				flex-shrink: 0;
				flex-grow: 0;
				width: calc(100% * (#{$i} / 12));
			}
		}
	}
}

.grid--2--medium {
	@include breakpoint($medium) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $gutter;
	}
}

.grid--3--large {
	display: grid;
	grid-gap: 1/2*$cell;

	@include breakpoint($large) {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 1/2 * $cell;
	}
}

.grid--4--large {
	display: grid;
	grid-gap: $gutter;

	@include breakpoint($large) {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1/4 * $cell;
	}
}

.grid--2--large {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: $cell 2 * $cell;
}

.grid--3--extra {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16.25rem;
}

.grid--gap--small {
	grid-gap: $cell;
}

.grid--contacts {
	display: grid;
	grid-gap: $gutter;

	@include breakpoint($large) {
		grid-gap: $gutter $cell;
		grid-template-columns: repeat(2, 1fr);
	}
}

/*------------------------------------*\
# GRID UTILITIES
\*------------------------------------*/

.grid--center {
	justify-content: center;
}

.grid--end {
	@include breakpoint($large) {
		justify-content: flex-end;
	}
}

.show--large {
	display: none;
	
	@include breakpoint ($large) {
		display: inline-block;
	}
}

.hide--large {

	@include breakpoint ($large) {
		display: none;
	}
}