.event {
	padding: 3.625rem 0;
	padding-bottom: 6.25rem;
	outline: none !important;

	@include breakpoint($large) {
		max-width: 4 * $cell;
	}
}

.event__date {
	@include breakpoint($large) {
		margin-bottom: 1rem;
	}
}

.event__title {
	margin-bottom: 0.75rem;
	line-height: 1.75rem;

	@include breakpoint($large) {
	}
}

.event__desc {
	line-height: 1.5rem;

	@include breakpoint($large) {
	}
}

.slider-wrapper {
	position: relative;

	.link--section {
		position: absolute;
		bottom: 3.75rem;
		left: $cell;
	}
}
