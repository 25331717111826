.feed__header-wrapper {
  display: flex;
  flex-direction: column;
}

.feed__header-menu {
  display: flex;
  justify-content: space-around;

  & > a {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $color-gray;
  }

  .menu-active {
    border-bottom: 1px solid $color-primary;
  }
}
