@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Roboto:400,500,700,900&display=swap);
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
@charset "UTF-8";
/*------------------------------------*\
# CONFIG
\*------------------------------------*/
/*------------------------------------*\
** COLORS
\*------------------------------------*/
/*------------------------------------*\
** BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*\
# GRID
\*------------------------------------*/
/*------------------------------------*\
** BUTTONS
\*------------------------------------*/
/*------------------------------------*\
** INPUTS
\*------------------------------------*/
/*------------------------------------*\
** FONT WEIGHTS
\*------------------------------------*/
/*------------------------------------*\
** FONT CONFIG
\*------------------------------------*/
/*------------------------------------*\
** FONT SIZES
\*------------------------------------*/
/*------------------------------------*\
# Transitions
\*------------------------------------*/
.clearfix {
  overflow: hidden; }

.clear {
  clear: both; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.right {
  float: right; }

.left {
  float: left; }

.no-padding {
  padding: 0; }

.center-block {
  margin-left: auto;
  margin-right: auto; }

.center-inblock {
  text-align: center; }

.align-right {
  text-align: right; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.show {
  visibility: visible;
  opacity: 1; }

.hide {
  visibility: hidden;
  opacity: 0; }

.full--height {
  height: 100%; }

.full--height-vh {
  height: 100vh; }

.full--width {
  width: 100%; }

.full--width-vw {
  width: 100vw; }

.shade {
  background-color: #ecf0f1; }

.no-scroll {
  overflow: hidden; }

/*------------------------------------*\
** RESPONSIVE FONT SIZES
\*------------------------------------*/
/*------------------------------------*\
** UTILIY FUNCTION FOR FONT-SIZE MIXIN
\*------------------------------------*/
/*------------------------------------*\
** BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*\
** VERTICAL CENTERING
\*------------------------------------*/
/*------------------------------------*\
** CARD DEPTH
\*------------------------------------*/
/*------------------------------------*\
** UTILITY FUNCTION FOR CARD DEPTH MIXIN
\*------------------------------------*/
/*------------------------------------*\
** UTILITY FUNCTION FOR CARD DEPTH MIXIN
\*------------------------------------*/
/*------------------------------------*\
# BASE
\*------------------------------------*/
/*------------------------------------*\
** HEADINGS
\*------------------------------------*/
.heading-fill--primary {
  display: inline-block;
  padding: 1.5rem;
  background-color: #1B1464; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  margin: 0;
  padding: 0; }
  h1 img,
  h2 img,
  h3 img,
  h4 img,
  h5 img,
  h6 img {
    display: inline-block;
    object-fit: contain; }
  h1.white > img,
  h2.white > img,
  h3.white > img,
  h4.white > img,
  h5.white > img,
  h6.white > img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1); }

h1 {
  font-size: 1.625rem;
  line-height: 1.2;
  margin-bottom: 3rem; }
  @media screen and (min-width: 640px) {
    h1 {
      font-size: 2.25rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 3rem;
      line-height: 1; } }
  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 3rem;
      line-height: 1; } }
  @media screen and (min-width: 1440px) {
    h1 {
      font-size: 3rem;
      line-height: 1; } }
  @media only screen and (min-width: 1024px) {
    h1 {
      margin-bottom: 4rem; } }
  h1 img {
    height: 1ch;
    width: 1ch;
    margin-right: 1ch; }
  h1 .heading-icon--2x {
    width: 2ch;
    height: 2ch; }

h2 {
  font-size: 1.365rem;
  line-height: 1;
  margin-bottom: 1.25rem; }
  @media screen and (min-width: 640px) {
    h2 {
      font-size: 1.875rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    h2 {
      font-size: 2.625rem;
      line-height: 3rem; } }
  @media only screen and (min-width: 1024px) {
    h2 {
      margin-bottom: 1.875rem; } }

h3 {
  font-size: 1.125rem;
  line-height: normal; }
  @media screen and (min-width: 640px) {
    h3 {
      font-size: 1.375rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    h3 {
      font-size: 1.375rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1200px) {
    h3 {
      font-size: 1.375rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1440px) {
    h3 {
      font-size: 1.375rem;
      line-height: 1.2; } }

h4 {
  font-size: 0.75rem;
  line-height: 1; }
  @media screen and (min-width: 640px) {
    h4 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    h4 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1200px) {
    h4 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1440px) {
    h4 {
      font-size: 1rem;
      line-height: 1.2; } }

h5 {
  font-size: 1rem;
  line-height: 1.2; }
  @media screen and (min-width: 640px) {
    h5 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    h5 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1200px) {
    h5 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1440px) {
    h5 {
      font-size: 1rem;
      line-height: 1.2; } }

h6 {
  font-size: 1rem;
  line-height: 1.2; }
  @media screen and (min-width: 640px) {
    h6 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1024px) {
    h6 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1200px) {
    h6 {
      font-size: 1rem;
      line-height: 1.2; } }
  @media screen and (min-width: 1440px) {
    h6 {
      font-size: 1rem;
      line-height: 1.2; } }

/*------------------------------------*\
** LISTS
\*------------------------------------*/
ul {
  padding-left: 2.5rem;
  margin-bottom: 3rem; }

ul li {
  list-style-type: none;
  position: relative;
  line-height: inherit;
  font-size: inherit;
  margin-bottom: 0.625rem; }
  ul li::before {
    content: '';
    display: block;
    position: absolute;
    left: -1.5rem;
    top: 0.875rem;
    margin: auto 0;
    background: #0c0c0c;
    height: 0.25rem;
    width: 0.25rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-radius: 50%;
    overflow: hidden; }

/*------------------------------------*\
** LINKS
\*------------------------------------*/
a {
  text-decoration: none; }

a.link {
  outline-width: 0;
  text-decoration: none;
  color: #1B1464;
  transition: 0.2s;
  font-size: 1rem;
  line-height: 1.6; }

a.link--big {
  font-size: 1.375rem;
  font-weight: 700;
  padding: 2.25rem 0;
  color: #1B1464;
  transition: 0.3s; }
  a.link--big:hover {
    color: #EABB5F; }

a.link:hover,
a.link:focus,
a.link:active {
  color: #261d8f; }

a.link--light {
  outline-width: 0;
  text-decoration: none;
  color: #ffffff;
  transition: 0.2s;
  font-size: 1rem;
  line-height: 1.6; }

a.link--light:hover,
a.link--light:focus,
a.link--light:active {
  color: #261d8f; }

a.link--small {
  font-size: 0.8rem; }

a.link--center {
  text-align: center;
  display: block; }

.link--section {
  font-size: 0.875rem;
  font-weight: 640px;
  text-transform: uppercase;
  color: #1B1464;
  transition: 0.3s; }
  @media only screen and (min-width: 1024px) {
    .link--section {
      font-size: 1.25rem; } }
  .link--section:hover, .link--section:focus {
    opacity: 0.75; }

/*------------------------------------*\
** OTHER
\*------------------------------------*/
b,
strong,
.strong {
  font-weight: 700; }

p,
ul {
  font-size: 0.875rem;
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 640px) {
    p,
    ul {
      font-size: 1rem;
      line-height: 1.6; } }
  @media screen and (min-width: 1024px) {
    p,
    ul {
      font-size: 1rem;
      line-height: 1.6; } }
  @media screen and (min-width: 1200px) {
    p,
    ul {
      font-size: 1rem;
      line-height: 1.6; } }
  @media screen and (min-width: 1440px) {
    p,
    ul {
      font-size: 1rem;
      line-height: 1.6; } }

.text {
  font-size: 1rem;
  color: rgba(12, 12, 12, 0.7); }
  @media only screen and (min-width: 1024px) {
    .text {
      font-size: 1.375rem;
      line-height: 2.25rem; } }
  .text:nth-last-child(1) {
    margin-bottom: 0; }

pre,
code {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  display: block; }

/*------------------------------------*\
** TEXT MODIFIERS
\*------------------------------------*/
.text--small {
  font-size: 0.8rem; }

.text--slarge {
  font-size: 1.125rem; }

.text--large {
  font-size: 1.2rem; }

p.text--large {
  line-height: 2.25rem; }

.text--xlarge {
  font-size: 1.75rem; }

.text--double {
  font-size: 2rem; }

.text--center {
  text-align: center; }

.text--light {
  color: #ffffff; }

.text--dark {
  color: #0c0c0c; }

.text--alert {
  color: #f44336; }

.text--limiter {
  max-width: 40rem; }

.medium {
  font-weight: 640px; }

.semibold {
  font-weight: 600; }

.bold {
  font-weight: 700; }

.color-primary {
  color: #1B1464; }

.color-tertiary {
  color: #dfe8f1; }

.color-accent {
  color: #EABB5F; }

.color-blue {
  color: #1b4c8b !important; }

.white {
  color: #ffffff; }
  .white li::before,
  .white li::after {
    background: #ffffff; }

.white--children * {
  color: #ffffff !important; }

.text--legend {
  display: block;
  text-align: center;
  line-height: 1rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem; }

.gray {
  color: rgba(12, 12, 12, 0.7); }

.font--small {
  font-size: 0.875rem; }

@media only screen and (min-width: 1024px) {
  .font--medium {
    font-size: 1.375rem;
    line-height: 2.25rem; } }

.font--medium > * {
  font-size: inherit; }

.font--heading {
  font-family: "Libre Baskerville", serif; }

.margin--double {
  margin-bottom: 3rem; }

@media only screen and (min-width: 1024px) {
  .margin--80--l {
    margin-bottom: 5rem; } }

.page-content {
  font-size: 1.125rem; }
  .page-content p {
    font-size: inherit; }
  .page-content a {
    color: #EABB5F;
    font-weight: 500; }

.multicolumn--3-2-1 {
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  -webkit-column-count: 1;
          column-count: 1; }
  @media only screen and (min-width: 640px) {
    .multicolumn--3-2-1 {
      -webkit-column-count: 2;
              column-count: 2; } }
  @media only screen and (min-width: 1024px) {
    .multicolumn--3-2-1 {
      -webkit-column-count: 3;
              column-count: 3; } }

.link.-store {
  height: 6.25rem;
  width: 100%; }
  .link.-store img {
    height: 100%;
    width: 100%; }

.base-font-family {
  font-family: "Roboto", sans-serif; }

.no-margin {
  margin-bottom: 0; }

/*------------------------------------*\
** GLOBAL
\*------------------------------------*/
html {
  font-family: "Roboto", sans-serif;
  font-size: calc(16 / 375 * 100vw);
  line-height: 1.6;
  color: #0c0c0c;
  overflow-y: scroll; }
  @media only screen and (min-width: 640px) {
    html {
      font-size: calc(16 / 900 * 100vw); } }
  @media only screen and (min-width: 1024px) {
    html {
      font-size: calc(16 / 1440 * 100vw); } }
  @media only screen and (min-width: 1200px) {
    html {
      font-size: calc(16 / 1680 * 100vw); } }
  @media only screen and (min-width: 1440px) {
    html {
      font-size: calc(16 / 1920 * 100vw); } }
  html.burger-is-active {
    overflow: hidden; }

body {
  box-sizing: border-box;
  margin: auto; }

.main {
  overflow: hidden; }

*,
*:before,
*:after {
  box-sizing: inherit; }
  *::-moz-focus-inner,
  *:before::-moz-focus-inner,
  *:after::-moz-focus-inner {
    border: 0;
    outline: none; }

img {
  max-width: 100%;
  display: block; }

.post__image {
  -webkit-touch-callout: none !important;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

a:active,
a:focus {
  outline: 0;
  -moz-outline-style: none; }

/*a:not(.nav__logo):focus {
  pointer-events: none;
}*/
div.cookieConsent {
  background-color: #f9f9f9 !important;
  color: #0c0c0c !important; }
  div.cookieConsent button {
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    line-height: normal;
    font-weight: 400;
    padding: 0.75rem 1.375rem !important;
    transition: 0.3s;
    border: 1px solid transparent;
    border-radius: 0.375rem !important;
    overflow: hidden;
    position: relative;
    outline: none;
    background-color: #EABB5F !important;
    color: #ffffff !important;
    border-color: #EABB5F; }
    @media only screen and (min-width: 1024px) {
      div.cookieConsent button {
        font-size: 1rem; } }
    div.cookieConsent button:focus {
      outline: none;
      border-width: 1px;
      box-shadow: 0 0 0px 0px transparent; }
    div.cookieConsent button:hover {
      cursor: pointer;
      -webkit-transform: translateY(-0.3125rem);
              transform: translateY(-0.3125rem);
      box-shadow: 0.25rem 0 0.625rem rgba(12, 12, 12, 0.25); }
    div.cookieConsent button:focus, div.cookieConsent button:active, div.cookieConsent button:hover {
      background-color: rgba(234, 187, 95, 0.9) !important;
      border-color: rgba(234, 187, 95, 0.9);
      color: #ffffff; }

.loading-block {
  height: 100vh;
  width: 100%;
  background: rgba(12, 12, 12, 0.1); }

.splash-screen {
  display: block;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #1B1464;
  color: #f9f9f9;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  font-family: "Libre Baskerville", serif;
  font-size: 2rem; }
  .splash-screen p {
    font-family: inherit;
    font-size: inherit; }

.not-found {
  height: 100%;
  width: 100%;
  background: #f9f9f9;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  flex-direction: column;
  padding: 6.25rem 2.1875rem;
  text-align: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100; }
  .not-found h1 {
    margin-bottom: 1rem;
    font-size: 2.5rem; }
    @media only screen and (min-width: 1024px) {
      .not-found h1 {
        font-size: 4rem; } }
  .not-found p {
    color: #323232;
    font-size: 1.125rem; }
    @media only screen and (min-width: 1024px) {
      .not-found p {
        font-size: 1.35rem;
        margin-bottom: 3rem; } }

.error-msg {
  padding: 8.75rem 0; }

/*------------------------------------*\
# MODULES
\*------------------------------------*/
/*------------------------------------*\
# CORE BUTTONS
\*------------------------------------*/
button,
.btn,
.form input[type="submit"] {
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 400;
  padding: 0.75rem 1.375rem;
  transition: 0.3s;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  overflow: hidden;
  position: relative;
  outline: none; }
  @media only screen and (min-width: 1024px) {
    button,
    .btn,
    .form input[type="submit"] {
      font-size: 1rem; } }
  button:focus,
  .btn:focus,
  .form input:focus[type="submit"] {
    outline: none;
    border-width: 1px;
    box-shadow: 0 0 0px 0px transparent; }
  button:hover,
  .btn:hover,
  .form input:hover[type="submit"] {
    cursor: pointer;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    box-shadow: 0.25rem 0 0.625rem rgba(12, 12, 12, 0.25); }

.btn__text:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.btn--margin {
  margin-right: 0.625rem; }

.btn--big {
  padding: 1.0125rem 2.40625rem;
  font-size: 1.25rem; }

/*------------------------------------*\
# COLOR
\*------------------------------------*/
.btn--primary, .btn--arrow, .btn--like, .btn--add, .btn--mail, .btn--download, .btn--play, .btn--share, .btn--thumbs, .form input[type="submit"] {
  background-color: #EABB5F;
  color: #ffffff;
  border-color: #EABB5F; }
  .btn--primary:focus, .btn--arrow:focus, .btn--like:focus, .btn--add:focus, .btn--mail:focus, .btn--download:focus, .btn--play:focus, .btn--share:focus, .btn--thumbs:focus, .form input:focus[type="submit"], .btn--primary:active, .btn--arrow:active, .btn--like:active, .btn--add:active, .btn--mail:active, .btn--download:active, .btn--play:active, .btn--share:active, .btn--thumbs:active, .form input:active[type="submit"], .btn--primary:hover, .btn--arrow:hover, .btn--like:hover, .btn--add:hover, .btn--mail:hover, .btn--download:hover, .btn--play:hover, .btn--share:hover, .btn--thumbs:hover, .form input:hover[type="submit"] {
    background-color: rgba(234, 187, 95, 0.9);
    border-color: rgba(234, 187, 95, 0.9);
    color: #ffffff; }

.btn--blue {
  background-color: #1B1464;
  color: #ffffff;
  border-color: #1B1464; }
  .btn--blue:focus, .btn--blue:active, .btn--blue:hover {
    background-color: rgba(27, 20, 100, 0.9);
    border-color: rgba(27, 20, 100, 0.9);
    color: #ffffff; }

.btn--transparent {
  background-color: transparent;
  color: #EABB5F;
  border-color: #EABB5F; }
  .btn--transparent:focus, .btn--transparent:active, .btn--transparent:hover {
    background-color: #EABB5F;
    border-color: #EABB5F;
    color: #ffffff; }

.btn--white--transparent {
  border-color: #ffffff;
  color: #ffffff; }
  .btn--white--transparent:hover {
    background-color: #ffffff;
    color: #1B1464;
    border-color: #ffffff; }

.btn--fill {
  background-color: #1B1464;
  border: none; }

.btn--upload {
  display: flex !important;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem; }
  .btn--upload input {
    height: 0; }

/*------------------------------------*\
# WIDTH
\*------------------------------------*/
.btn--full {
  display: block;
  width: 100%;
  text-align: center; }

/*------------------------------------*\
# UTILITIES
\*------------------------------------*/
.btn--spacing {
  margin-bottom: 1.5rem; }

/*------------------------------------*\
# STAY AWAY
\*------------------------------------*/
/*------------------------------------*\
# PRIMARY / SECONDARY / TERTIARY
\*------------------------------------*/
.btn--primary--light {
  background-color: #3225b9;
  color: #f9f9f9; }
  .btn--primary--light:focus, .btn--primary--light:active, .btn--primary--light:hover {
    background-color: #3829ce; }

.btn--secondary {
  background-color: #678699;
  color: #f9f9f9; }
  .btn--secondary:focus, .btn--secondary:active, .btn--secondary:hover {
    background-color: #5d798a; }

.btn--tertiary {
  background-color: #dfe8f1;
  color: #f9f9f9; }
  .btn--tertiary:focus, .btn--tertiary:active, .btn--tertiary:hover {
    background-color: #cddbe9; }

/*------------------------------------*\
# LARGE / SMALL
\*------------------------------------*/
.btn--large {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem; }
  .btn--large.btn__icon {
    background-position: 3rem center;
    padding-left: 5.25rem; }

.btn--small, .btn--create, .btn--update, .btn--remove {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  padding-top: 0.21875rem;
  padding-bottom: 0.21875rem; }
  .btn--small.btn__icon, .btn__icon.btn--create, .btn__icon.btn--update, .btn__icon.btn--remove {
    background-position: 3rem center;
    padding-left: 5.25rem; }

.btn--spacing {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

/*------------------------------------*\
# UNDERLINE
\*------------------------------------*/
.btn--underline {
  background-color: transparent;
  color: #0c0c0c;
  border-bottom: 2px solid #0c0c0c;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin-bottom: 1rem; }
  .btn--underline:active {
    border-bottom: 2px solid #211879; }
  .btn--underline:focus, .btn--underline:hover {
    border-bottom: 2px solid #1B1464; }

/*------------------------------------*\
# ARROW
\*------------------------------------*/
.btn--arrow {
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: right 1.5rem center;
  padding-right: 3.75rem; }

.btn--arrow--simple {
  color: #1B1464;
  font-weight: 700;
  display: block;
  margin-top: 1rem;
  font-size: 1.2rem; }
  .btn--arrow--simple:hover .icon--arrow::before {
    width: 0.75rem; }
  .btn--arrow--simple .icon--arrow::before {
    content: '';
    margin-left: 0.5rem;
    width: 0.5rem;
    border-bottom: 4px solid #1B1464;
    display: inline-block;
    vertical-align: middle;
    transition: 0.3s;
    margin-top: -2px; }
  .btn--arrow--simple .icon--arrow::after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 3px solid #1B1464;
    border-right: 3px solid #1B1464;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-left: -6px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px; }

/*------------------------------------*\
# DISABLED
\*------------------------------------*/
.btn--disabled {
  background-color: rgba(12, 12, 12, 0.7);
  color: #ffffff;
  cursor: default;
  pointer-events: none;
  opacity: 0.5; }
  .btn--disabled:focus, .btn--disabled:active, .btn--disabled:hover {
    background-color: rgba(12, 12, 12, 0.7);
    color: #323232;
    cursor: default;
    pointer-events: none;
    opacity: 0.5; }

/*------------------------------------*\
# ICONS
\*------------------------------------*/
.btn__icon {
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: 1.5rem center;
  padding-left: 3.75rem; }

.btn--like--full {
  -webkit-animation: pulse 0.5s 1;
          animation: pulse 0.5s 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out; }

@-webkit-keyframes pulse {
  0% {
    background-size: 1.5rem;
    background-position: 1.5rem center; }
  50% {
    background-size: 2rem;
    background-position: 1.25rem center; }
  100% {
    background-size: 1.5rem;
    background-position: 1.5rem center; } }

@keyframes pulse {
  0% {
    background-size: 1.5rem;
    background-position: 1.5rem center; }
  50% {
    background-size: 2rem;
    background-position: 1.25rem center; }
  100% {
    background-size: 1.5rem;
    background-position: 1.5rem center; } }

.btn--add {
  transition: background-position 0s ease; }

/*------------------------------------*\
# Utility
\*------------------------------------*/
.btn--create {
  background-color: #4caf50;
  color: #f9f9f9; }
  .btn--create:focus, .btn--create:active, .btn--create:hover {
    background-color: #449d48; }

.btn--update {
  background-color: #f1c40f;
  color: #0c0c0c; }
  .btn--update:focus, .btn--update:active, .btn--update:hover {
    background-color: #dab10d; }

.btn--remove {
  background-color: #f44336;
  color: #f9f9f9; }
  .btn--remove:focus, .btn--remove:active, .btn--remove:hover {
    background-color: #f32c1e; }

/*------------------------------------*\
# BUTTON GROUP
\*------------------------------------*/
.btn-group {
  display: inline-block;
  vertical-align: middle;
  display: flex;
  flex-wrap: nowrap; }
  .btn-group .btn, .btn-group .form input[type="submit"], .form .btn-group input[type="submit"] {
    border-radius: 0; }
  .btn-group .btn:first-child, .btn-group .form input:first-child[type="submit"], .form .btn-group input:first-child[type="submit"] {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .btn-group .btn:last-child, .btn-group .form input:last-child[type="submit"], .form .btn-group input:last-child[type="submit"] {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }

/*------------------------------------*\
# BUTTON GOOGLE
\*------------------------------------*/
.btn--google {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19.0625rem;
  height: 3.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  position: relative; }
  .btn--google button {
    padding: 1.125rem 1rem !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: none !important;
    border: 1px solid #0c0c0c !important;
    border-radius: 0.375rem !important; }
    .btn--google button > div {
      position: absolute;
      border-radius: 0 !important; }
      .btn--google button > div svg {
        height: 100%;
        width: 100%; }
    .btn--google button > span {
      color: #0c0c0c !important;
      font-size: 0.875rem !important;
      padding: 0 !important;
      margin: 0 auto; }

.btn--center {
  display: flex;
  margin-left: auto;
  margin-right: auto; }

/*------------------------------------*\
# FIXED BUTTONS
\*------------------------------------*/
.btn--fixed {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 20;
  display: none;
  padding: 0;
  border-radius: 0;
  overflow: visible;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  -webkit-transform: translateX(1px);
          transform: translateX(1px); }
  @media only screen and (min-width: 1024px) {
    .btn--fixed {
      display: block; } }
  .btn--fixed:hover {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
    opacity: 0.75;
    -webkit-transform: translateX(1px);
            transform: translateX(1px); }
  .btn--fixed img {
    transition: 0.3s;
    width: 6.25rem;
    height: auto; }

.btn--see-more {
  margin: auto;
  margin-top: 6.5rem; }

.like-btn {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  color: rgba(12, 12, 12, 0.7);
  line-height: 0;
  padding: 0;
  background: transparent;
  border: none;
  overflow: visible;
  justify-content: flex-start; }
  .like-btn:hover {
    box-shadow: none;
    -webkit-transform: none;
            transform: none;
    opacity: 0.75; }

.like-btn__icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 1px solid rgba(12, 12, 12, 0.1);
  overflow: hidden;
  padding: 0.25rem;
  box-shadow: 2px 2px 0.375rem 0px rgba(12, 12, 12, 0.15);
  margin-right: 1.25rem; }
  .like-btn__icon img {
    width: 100%; }

/*------------------------------------*\
# HAMBURGER
\*------------------------------------*/
.nav--user__burger {
  display: inline-block;
  position: absolute;
  height: 1rem;
  right: 1.5rem;
  top: 1.75rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  z-index: 3; }
  .nav--user__burger:hover {
    cursor: pointer; }

.burger {
  display: block;
  height: 0.25rem;
  width: 2rem;
  background: #1B1464;
  position: relative;
  transition-all: 0.2s; }
  .burger:before {
    content: '';
    position: absolute;
    top: -0.5rem;
    width: 2rem;
    left: 0;
    height: 0.25rem;
    background: #1B1464;
    transition: 0.2s; }
  .burger:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 2rem;
    height: 0.25rem;
    background: #1B1464;
    transition: 0.2s; }

.burger--active {
  background: transparent; }
  .burger--active:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0; }
  .burger--active:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 0; }

/*------------------------------------*\
** FORMS
\*------------------------------------*/
.validation-error {
  display: inline-block;
  font-size: 0.8rem; }

.label {
  font-size: 0.8rem;
  text-transform: uppercase; }

.input {
  width: 100%;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0.75rem; }

.input__wrapper {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem; }

.input--login__wrapper {
  position: relative; }

.input--login__icon {
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  width: 16px; }

.input--login {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  padding-left: 3rem;
  border-bottom: 2px solid #ffffff; }

.input--register {
  background-color: rgba(12, 12, 12, 0.05);
  color: #0c0c0c; }

.input--dob__wrapper {
  display: flex;
  flex-wrap: nowrap; }

.input--dob--limiter {
  width: 20%;
  margin-right: 3px; }

.input--dob--unlimiter {
  width: 100%; }

.input--dob {
  outline: none;
  border: none;
  background-image: none;
  box-shadow: none;
  padding: 0.75rem;
  background-color: rgba(12, 12, 12, 0.05);
  color: #0c0c0c; }

.input--toggle__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.input--toggle {
  outline: none;
  border: none;
  background-image: none;
  box-shadow: none;
  background-color: rgba(12, 12, 12, 0.05);
  color: #0c0c0c;
  width: 50%; }
  .input--toggle:not(last-child) {
    margin-right: 3px; }

.gender {
  padding: 0.75rem;
  font-size: 0.8rem; }

.gender--selected {
  background-color: #1B1464;
  color: #ffffff; }

.recents-search input {
  padding: 1.5rem;
  width: 100%;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none; }

.newsletter-form {
  display: flex;
  justify-content: space-between;
  width: 26rem;
  max-width: 100%;
  margin: 0 auto;
  border-bottom: 0.125rem solid rgba(12, 12, 12, 0.7); }

.newsletter-form__input {
  width: 100%;
  padding: 0;
  padding-right: 0.75rem;
  font-style: italic;
  background: transparent;
  outline: none;
  border: none;
  font-size: 1.125rem; }
  .newsletter-form__input:placeholder {
    color: rgba(17, 17, 17, 0.4);
    font-weight: 400; }

.newsletter-form__btn {
  background: transparent;
  border: none;
  outline: none;
  color: #1B1464;
  padding: 0.75rem;
  padding-right: 0; }

.form .form__fieldset {
  margin: 0;
  margin-bottom: 2.25rem;
  border: none;
  outline: none;
  padding: 0; }

.form label {
  display: block;
  margin-bottom: 0.875rem;
  font-size: 1.125rem; }
  .form label.no-margin {
    margin-bottom: 0; }

.form .input,
.form input,
.form .textarea {
  border: none;
  outline: none;
  width: 100%;
  resize: none;
  font-size: 1.375rem;
  background-color: #ffffff; }

.form .input--text,
.form [type="text"] {
  padding: 0;
  height: 3.75rem;
  width: 100%; }

.form .textarea {
  padding: 1.25rem;
  width: 100%; }

.form__status {
  font-size: 1rem;
  font-weight: 600;
  color: #f9f9f9;
  text-align: center;
  padding: 0.625rem;
  border-radius: 0.375rem;
  margin-top: 2.5rem;
  text-transform: capitalize; }

.form__status--ok {
  background-color: #3bd45c; }

.form__status--error {
  background-color: #f44336; }

.contact-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6.5rem; }
  @media only screen and (min-width: 1024px) {
    .contact-form {
      grid-template-columns: 1fr 1fr; } }
  .contact-form p {
    line-height: 2.25rem;
    font-size: 1.375rem;
    margin-bottom: 5rem; }
    .contact-form p:nth-last-child(1) {
      margin-bottom: 0; }
  .contact-form strong,
  .contact-form em {
    font-weight: 400; }

.contact-form__info {
  margin-right: 0; }

.form--border .input,
.form--border .textarea {
  border: 1px solid #0c0c0c;
  border-radius: 0.375rem;
  padding: 1.25rem; }

.form--border .btn, .form--border .form input[type="submit"], .form .form--border input[type="submit"] {
  height: 3.75rem;
  font-size: 1.125rem; }

.mailchimp-form form {
  display: flex;
  flex-direction: column; }

.mailchimp-form input {
  border: 1px solid rgba(17, 17, 17, 0.4);
  outline: none;
  margin-bottom: 0.625rem;
  padding: 0.75rem;
  border-radius: 0.25rem; }

.mailchimp-form button {
  background: #EABB5F;
  color: #f9f9f9;
  margin: 0 auto;
  margin-top: 1.25rem; }

.mailchimp-form .msg-alert {
  text-align: center;
  margin-top: 1.5rem; }
  .mailchimp-form .msg-alert p {
    margin: 0; }

input[type="checkbox"] {
  width: auto;
  margin-right: 1.25rem;
  cursor: pointer; }
  input[type="checkbox"] + label {
    cursor: pointer;
    line-height: normal; }

.input--checkbox__wrap {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  line-height: normal; }

.input--checkbox label {
  line-height: 1.5; }

.input--checkbox:checked,
.input--checkbox:not(:checked) {
  position: absolute;
  left: -624.938rem; }

.input--checkbox:checked + label,
.input--checkbox:not(:checked) + label {
  position: relative;
  padding-left: 0;
  cursor: pointer;
  line-height: 1.25rem;
  display: inline-block;
  width: 100%; }

.input--checkbox:checked + label:before,
.input--checkbox:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid rgba(12, 12, 12, 0.7);
  border-radius: 0.125rem;
  background: #ffffff; }

.input--checkbox:checked + label:after,
.input--checkbox:not(:checked) + label:after {
  content: '';
  width: 1.25rem;
  height: 1.25rem;
  background-color: #1B1464;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.125rem;
  transition: all 0.2s ease; }

.input--checkbox:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.input--checkbox:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.input--radio__wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem; }

.input--radio label {
  line-height: 1.5; }

.input--radio:checked,
.input--radio:not(:checked) {
  position: absolute;
  left: -624.938rem; }

.input--radio:checked + label,
.input--radio:not(:checked) + label {
  position: relative;
  padding-left: 0;
  cursor: pointer;
  line-height: 1.25rem;
  display: inline-block;
  width: 100%; }

.input--radio:checked + label:before,
.input--radio:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid rgba(12, 12, 12, 0.7);
  border-radius: 100%;
  background: #ffffff; }

.input--radio:checked + label:after,
.input--radio:not(:checked) + label:after {
  content: '';
  width: 1.25rem;
  height: 1.25rem;
  background-color: #1B1464;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100%;
  transition: all 0.2s ease; }

.input--radio:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.input--radio:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.input--select {
  border: none;
  padding: 0;
  padding-right: 1.25rem;
  margin: 0;
  line-height: 1.85;
  font-weight: 600;
  font-size: 0.8125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: transparent; }

.input--select__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid rgba(24, 24, 24, 0.05); }
  .input--select__wrap > div {
    background-position: center right; }

.input--select__label {
  line-height: 1.5; }

.input--price-range {
  color: rgba(12, 12, 12, 0.7);
  line-height: 1.5;
  padding-left: 10.1875rem;
  padding-bottom: 0.4375rem;
  border: none;
  padding: 0;
  border-bottom: 1px solid rgba(24, 24, 24, 0.05);
  text-align: right; }

.input--price-range__wrap {
  max-width: calc(305/375*10vw);
  display: flex;
  justify-content: space-between; }
  @media only screen and (min-width: 1024px) {
    .input--price-range__wrap {
      max-width: calc(305/1920*100vw); } }

.input--price-range__label {
  line-height: 2;
  font-weight: 500;
  padding-bottom: 0.4375rem;
  display: block; }

.tabs__nav {
  line-height: 1.5rem;
  color: #0c0c0c;
  font-size: 0.75rem;
  flex-direction: column;
  margin-bottom: 1.25rem; }
  @media only screen and (min-width: 640px) {
    .tabs__nav {
      flex-direction: row;
      font-size: 1.25rem;
      margin-bottom: 3.125rem; } }
  @media only screen and (min-width: 1024px) {
    .tabs__nav {
      font-size: 1.5rem;
      margin-bottom: 6.25rem; } }
  .tabs__nav p {
    font-size: inherit; }
  .tabs__nav * {
    line-height: 1; }
  .tabs__nav > div {
    display: flex;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 640px) {
      .tabs__nav > div {
        margin-bottom: 0; } }
    .tabs__nav > div > div {
      margin-right: 1.75rem; }
      @media only screen and (min-width: 1024px) {
        .tabs__nav > div > div {
          margin-right: 3.75rem; } }
  .tabs__nav p {
    margin: 0; }
  .tabs__nav b {
    font-weight: 400;
    position: relative;
    display: inline-block; }
    .tabs__nav b::after {
      content: '';
      width: 100%;
      height: 0;
      border-bottom: 1px solid #0c0c0c;
      position: absolute;
      top: 100%;
      left: 0; }
  .tabs__nav a {
    color: #0c0c0c; }

.react-tabs__tab-list {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem; }

.react-tabs__tab-list {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1024px) {
    .react-tabs__tab-list {
      flex-direction: row; } }
  .react-tabs__tab-list li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    outline: none !important;
    box-shadow: none !important; }
    @media only screen and (min-width: 1024px) {
      .react-tabs__tab-list li {
        margin-right: 3rem;
        font-size: 1.25rem; } }
    .react-tabs__tab-list li::before, .react-tabs__tab-list li::after {
      display: none; }

.react-tabs__tab-panel .tab-content--books > .tab-grid * {
  display: none; }

.react-tabs__tab-panel .tab-content > .tab-grid {
  display: grid !important;
  flex-direction: column;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem; }
  @media only screen and (min-width: 640px) {
    .react-tabs__tab-panel .tab-content > .tab-grid {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media only screen and (min-width: 1024px) {
    .react-tabs__tab-panel .tab-content > .tab-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }

.react-tabs__tab-panel .tab-content > .tab-grid--books {
  grid-template-columns: 1fr 1fr; }
  @media only screen and (min-width: 640px) {
    .react-tabs__tab-panel .tab-content > .tab-grid--books {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  @media only screen and (min-width: 1024px) {
    .react-tabs__tab-panel .tab-content > .tab-grid--books {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      padding-right: 19.5rem; } }

.react-tabs__tab-panel .tab-content a:not(.btn) {
  width: 100%;
  height: auto;
  display: block; }
  .react-tabs__tab-panel .tab-content a:not(.btn) img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%; }

.react-tabs__tab-panel .card {
  width: 100%; }

.react-tabs__tab--selected {
  border: none;
  font-weight: 700;
  color: #1B1464; }

.tab-content__logo {
  width: 12.5rem;
  height: auto;
  display: inline-block;
  margin-top: 2.5rem; }
  @media only screen and (min-width: 1024px) {
    .tab-content__logo {
      margin-top: 0;
      margin-left: 1.25rem; } }

.tab-content__row {
  display: flex;
  flex-direction: column;
  margin-top: 6.5rem; }
  @media only screen and (min-width: 640px) {
    .tab-content__row {
      flex-direction: row;
      align-items: center; } }

.accordion__item {
  margin-bottom: 2rem;
  position: relative; }

.accordion__head {
  color: #1B1464;
  border-bottom: 2px solid #1B1464;
  font-size: 1.25rem;
  transition: 0.3s;
  padding-bottom: 1rem; }
  .accordion__head:hover {
    cursor: pointer;
    color: #1B1464; }

.accordion__body {
  display: none; }
  .accordion__body > p:not(:last-child) {
    margin-bottom: 1rem;
    display: inline-block; }

.accordion__arrow {
  position: absolute;
  right: 0;
  top: 0.5rem;
  display: inline-block;
  -webkit-transform: translate(-16px, 0);
          transform: translate(-16px, 0); }
  .accordion__arrow:before, .accordion__arrow:after {
    content: "";
    transition: all 0.3s ease-in-out;
    position: absolute;
    background-color: #1B1464;
    width: 3px;
    height: 16px; }
  .accordion__arrow:before {
    -webkit-transform: translate(8px, 0) rotate(45deg);
            transform: translate(8px, 0) rotate(45deg); }
  .accordion__arrow:after {
    -webkit-transform: translate(-2px, 0) rotate(-45deg);
            transform: translate(-2px, 0) rotate(-45deg); }

.accordion__item.active .accordion__arrow:before {
  -webkit-transform: translate(-2px, 0) rotate(45deg);
          transform: translate(-2px, 0) rotate(45deg); }

.accordion__item.active .accordion__arrow:after {
  -webkit-transform: translate(8px, 0) rotate(-45deg);
          transform: translate(8px, 0) rotate(-45deg); }

.accordion__item.active .accordion__head {
  border-bottom: none; }

.icon--primary path {
  fill: #1B1464 !important;
  stroke: #1B1464 !important; }

.icon--white path {
  fill: #f9f9f9 !important;
  stroke: #f9f9f9 !important; }

.icon--black path {
  fill: #0c0c0c !important;
  stroke: #0c0c0c !important; }

.icon--1x {
  width: 1.1875rem;
  height: 1.1875rem; }

.icon--2x {
  width: 1.5rem;
  height: 1.5rem; }

.icon--3x {
  width: 2rem;
  height: 2rem; }

.icon--4x {
  width: 4rem;
  height: 4rem; }

.icon--huge {
  max-width: 200px;
  max-height: 200px; }

.icon--spacing {
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 1.4375rem; }

.icon--spacing-s {
  margin-right: 0.8rem; }
  @media only screen and (min-width: 640px) {
    .icon--spacing-s {
      margin-left: 0.8rem; } }

.icon--offset-right {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  object-fit: contain;
  padding-left: 0.625rem; }

.icon--spacing--right {
  margin-right: 0.625rem; }
  .icon--spacing--right:nth-last-child(1) {
    margin-right: 0; }

.icon-wrap--center {
  display: flex;
  justify-content: center;
  align-items: center; }

.cta {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  justify-items: center;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  text-align: center; }
  @media only screen and (min-width: 1024px) {
    .cta {
      text-align: left;
      justify-items: start; } }
  .cta .btn, .cta .form input[type="submit"], .form .cta input[type="submit"] {
    margin-top: auto; }

.cta__img {
  margin-bottom: 2.44375rem; }
  @media only screen and (min-width: 1024px) {
    .cta__img {
      margin-bottom: 2.875rem; } }
  .cta__img > div {
    height: 6.25rem;
    width: 6.25rem;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto; }
    @media only screen and (min-width: 1024px) {
      .cta__img > div {
        height: 8.5rem;
        width: 8.5rem; } }
  .cta__img img {
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.cta__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media only screen and (min-width: 1024px) {
    .cta__body {
      align-items: flex-start;
      display: block; } }

.cta__title {
  font-size: 1.5rem; }
  @media only screen and (min-width: 1024px) {
    .cta__title {
      font-size: 2rem;
      padding-right: 1rem;
      min-height: 6rem; } }

.cta__desc {
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  width: 60%; }
  @media only screen and (min-width: 1024px) {
    .cta__desc {
      width: 13rem;
      font-size: 1.125rem;
      margin-bottom: 3rem; } }
  .cta__desc p {
    margin: 0; }

.cta__wrap {
  display: grid;
  grid-gap: 3.75rem; }
  @media only screen and (min-width: 1024px) {
    .cta__wrap {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.25rem; } }

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 2rem; }
  .breadcrumbs div,
  .breadcrumbs a {
    padding-right: 1rem;
    margin-right: 1rem;
    position: relative; }
    .breadcrumbs div:after,
    .breadcrumbs a:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      margin: auto 0;
      display: block;
      height: 0.25rem;
      width: 0.25rem;
      max-height: 0.25rem;
      max-width: 0.25rem;
      border-radius: 50%;
      background-color: #0c0c0c; }
    .breadcrumbs div:nth-last-child(1):after,
    .breadcrumbs a:nth-last-child(1):after {
      display: none; }
  .breadcrumbs a {
    font-weight: 500;
    color: #0c0c0c; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  .pagination--prev, .pagination--next {
    color: #7f8c8d;
    text-decoration: none;
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
    .pagination--prev:hover, .pagination--prev:focus, .pagination--prev:active, .pagination--next:hover, .pagination--next:focus, .pagination--next:active {
      color: #9aa4a5; }
      .pagination--prev:hover .pagination__icon path, .pagination--prev:focus .pagination__icon path, .pagination--prev:active .pagination__icon path, .pagination--next:hover .pagination__icon path, .pagination--next:focus .pagination__icon path, .pagination--next:active .pagination__icon path {
        fill: #9aa4a5 !important; }
  .pagination--next .pagination__icon {
    -webkit-transform: rotate(180deg) translateY(-18%);
            transform: rotate(180deg) translateY(-18%); }
  .pagination__icon {
    width: 1rem;
    height: 1rem;
    -webkit-transform: translateY(18%);
            transform: translateY(18%); }
    .pagination__icon path {
      fill: #7f8c8d !important; }
  .pagination__link--wrap {
    padding-left: 0; }
    .pagination__link--wrap li {
      display: inline-block;
      margin-left: 0.5rem;
      margin-right: 0.5rem; }
  .pagination__link {
    display: inline-block;
    color: #7f8c8d;
    text-decoration: none;
    border-radius: 100%;
    padding: 0.25rem 0.75rem; }
    .pagination__link:hover, .pagination__link:active, .pagination__link:focus {
      color: #9aa4a5; }
    .pagination__link--active {
      color: #ffffff;
      background-color: #1B1464;
      transition: 0.3s; }
      .pagination__link--active:hover, .pagination__link--active:active, .pagination__link--active:focus {
        color: #f2f2f2;
        background-color: #15104f; }

.slider--cards .slider {
  position: relative; }

.slider--cards .slick-slider {
  color: #ffffff;
  position: relative;
  height: 100%; }
  .slider--cards .slick-slider .slick-track {
    display: flex; }
    .slider--cards .slick-slider .slick-track:after {
      content: '';
      height: 100%;
      width: 200vw;
      position: absolute;
      left: 100%;
      top: 0;
      left: 0;
      background: #678699;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      z-index: -1; }
      @media only screen and (min-width: 1024px) {
        .slider--cards .slick-slider .slick-track:after {
          left: 100%; } }
  .slider--cards .slick-slider .slick-slide > * {
    transition: 0.3s;
    color: #ffffff; }
  .slider--cards .slick-slider .slick-slide .btn, .slider--cards .slick-slider .slick-slide .form input[type="submit"], .form .slider--cards .slick-slider .slick-slide input[type="submit"] {
    border-color: #ffffff;
    color: #ffffff; }
    .slider--cards .slick-slider .slick-slide .btn:hover, .slider--cards .slick-slider .slick-slide .form input:hover[type="submit"], .form .slider--cards .slick-slider .slick-slide input:hover[type="submit"] {
      background-color: #ffffff;
      color: #1B1464; }
  .slider--cards .slick-slider .slick-slide:not(.slick-active) {
    cursor: pointer; }
    .slider--cards .slick-slider .slick-slide:not(.slick-active) > * {
      opacity: 0.5; }
    .slider--cards .slick-slider .slick-slide:not(.slick-active):before {
      opacity: 0.5; }
    .slider--cards .slick-slider .slick-slide:not(.slick-active):after {
      opacity: 0.2; }
  .slider--cards .slick-slider .slick-slide {
    background: #678699;
    position: relative;
    overflow: visible;
    padding: 3.125rem 0;
    padding-right: 3.25rem;
    padding-bottom: 2.375rem; }
    @media only screen and (min-width: 1024px) {
      .slider--cards .slick-slider .slick-slide {
        padding: 6.25rem 6.5rem;
        padding-bottom: 1.1875rem;
        padding-right: 0; } }
    .slider--cards .slick-slider .slick-slide:before {
      content: '';
      position: absolute;
      top: 3.125rem;
      left: 0;
      height: 0.625rem;
      width: 0.625rem;
      border: 0.125rem solid #ffffff;
      border-radius: 100%; }
      @media only screen and (min-width: 1024px) {
        .slider--cards .slick-slider .slick-slide:before {
          top: 6.25rem;
          left: 6.5rem; } }
    .slider--cards .slick-slider .slick-slide:after {
      content: '';
      position: absolute;
      top: 3.125rem;
      left: 1.75rem;
      -webkit-transform: translateY(0.2rem);
              transform: translateY(0.2rem);
      height: 0.2rem;
      width: calc(100% - 3.125rem);
      overflow: hidden;
      border-bottom: 2px dashed #ffffff;
      opacity: 0.5;
      z-index: 1; }
      @media only screen and (min-width: 1024px) {
        .slider--cards .slick-slider .slick-slide:after {
          top: 6.25rem;
          left: 7.8rem; } }
  .slider--cards .slick-slider .slick-slide:nth-last-child(1) {
    overflow: hidden; }
    @media only screen and (min-width: 1024px) {
      .slider--cards .slick-slider .slick-slide:nth-last-child(1) {
        padding-right: 6.5rem; } }
    .slider--cards .slick-slider .slick-slide:nth-last-child(1):after {
      display: none; }

.slider--cards .slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 1rem;
  z-index: 5;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 0;
  color: #ffffff; }
  @media only screen and (min-width: 1024px) {
    .slider--cards .slick-arrow {
      width: 5rem; } }
  .slider--cards .slick-arrow:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.375rem;
    transition: 0.3s; }
    @media only screen and (min-width: 1024px) {
      .slider--cards .slick-arrow:after {
        font-size: 8.375rem; } }
  .slider--cards .slick-arrow:hover:after {
    opacity: 0.7; }
  .slider--cards .slick-arrow:active:after {
    color: #3225b9; }

.slider--cards .slick-prev {
  left: 0;
  -webkit-transform: translateX(-2.5rem);
          transform: translateX(-2.5rem); }
  @media only screen and (min-width: 1024px) {
    .slider--cards .slick-prev {
      -webkit-transform: translateX(-4.5rem);
              transform: translateX(-4.5rem); } }
  .slider--cards .slick-prev:after {
    content: '\2039'; }

.slider--cards .slick-next {
  right: 0;
  -webkit-transform: translateX(2.5rem);
          transform: translateX(2.5rem); }
  @media only screen and (min-width: 1024px) {
    .slider--cards .slick-next {
      -webkit-transform: translateX(4.5rem);
              transform: translateX(4.5rem); } }
  .slider--cards .slick-next:after {
    content: '\203A'; }

.slider--cards .slick-disabled {
  opacity: 0;
  pointer-events: none; }

.slider--gallery {
  position: relative;
  width: 100%; }
  .slider--gallery .gallery-item__name {
    margin-top: 0.625rem; }
  .slider--gallery .gallery-item__caption {
    position: absolute;
    bottom: 0;
    line-height: 1.75;
    left: 0;
    width: 100%;
    padding: 0.625rem;
    padding-top: 3rem;
    font-size: 1rem;
    color: #ffffff;
    display: inline-block;
    height: auto;
    background: linear-gradient(0deg, black 0%, rgba(255, 0, 0, 0) 100%); }
  .slider--gallery div {
    height: 100%; }
  .slider--gallery .slick-track {
    display: flex;
    min-width: 100%; }
  .slider--gallery .slick-slide {
    transition: 0.3s;
    height: 21.875rem; }
    @media only screen and (min-width: 1024px) {
      .slider--gallery .slick-slide {
        height: 25rem; } }
  .slider--gallery .slick-slide > div {
    padding: 0 1.25rem; }
  .slider--gallery .slick-slide:not(.slick-active) {
    opacity: 0.5; }
  .slider--gallery .slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 1rem;
    z-index: 5;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 0;
    color: #ffffff; }
    @media only screen and (min-width: 1024px) {
      .slider--gallery .slick-arrow {
        width: 5rem; } }
    .slider--gallery .slick-arrow:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.375rem;
      transition: 0.3s;
      color: #0c0c0c; }
      @media only screen and (min-width: 1024px) {
        .slider--gallery .slick-arrow:after {
          font-size: 7.375rem; } }
    .slider--gallery .slick-arrow:hover:after {
      opacity: 0.7; }
    .slider--gallery .slick-arrow:active:after {
      color: #3225b9; }
  .slider--gallery .slick-prev {
    left: 0;
    -webkit-transform: translateX(-1.5rem);
            transform: translateX(-1.5rem); }
    @media only screen and (min-width: 1024px) {
      .slider--gallery .slick-prev {
        -webkit-transform: translateX(-12.5rem);
                transform: translateX(-12.5rem); } }
    .slider--gallery .slick-prev:after {
      content: '\2039'; }
  .slider--gallery .slick-next {
    right: 0;
    -webkit-transform: translateX(1.5rem);
            transform: translateX(1.5rem); }
    @media only screen and (min-width: 1024px) {
      .slider--gallery .slick-next {
        -webkit-transform: translateX(12.5rem);
                transform: translateX(12.5rem); } }
    .slider--gallery .slick-next:after {
      content: '\203A'; }
  .slider--gallery img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.slider--whats-on {
  height: 100%;
  position: relative; }
  .slider--whats-on iframe {
    border: none;
    width: 100%;
    min-height: 120%; }
  .slider--whats-on .slider {
    margin: 0 auto;
    width: calc(100% + 1 / 2 * 1.125rem); }
  .slider--whats-on .slick-list {
    margin: 0 -1.125rem; }
  .slider--whats-on .slick-track {
    display: flex; }
  .slider--whats-on .slick-slide {
    transition: 0.3s;
    padding: 0 1.125rem; }
  .slider--whats-on .slick-slide > div {
    height: 100%; }
  .slider--whats-on .slick-slide .card {
    display: flex !important;
    height: 100%; }
  .slider--whats-on .slick-slide:not(.slick-active) {
    opacity: 0;
    pointer-events: none; }
  .slider--whats-on .slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 1rem;
    z-index: 5;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 0;
    color: #ffffff; }
    @media only screen and (min-width: 1024px) {
      .slider--whats-on .slick-arrow {
        width: 5rem; } }
    .slider--whats-on .slick-arrow:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4.975rem;
      transition: 0.3s; }
      @media only screen and (min-width: 1024px) {
        .slider--whats-on .slick-arrow:after {
          font-size: 12.375rem; } }
    .slider--whats-on .slick-arrow:hover:after {
      opacity: 0.7; }
    .slider--whats-on .slick-arrow:active:after {
      color: #3225b9; }
  .slider--whats-on .slick-disabled {
    opacity: 0;
    pointer-events: none; }
  .slider--whats-on .slick-prev {
    left: 0;
    -webkit-transform: translateX(-2.5rem);
            transform: translateX(-2.5rem); }
    @media only screen and (min-width: 1024px) {
      .slider--whats-on .slick-prev {
        -webkit-transform: translateX(-12.5rem);
                transform: translateX(-12.5rem); } }
    .slider--whats-on .slick-prev:after {
      content: '\2039'; }
  .slider--whats-on .slick-next {
    right: 0;
    -webkit-transform: translateX(2.5rem);
            transform: translateX(2.5rem); }
    @media only screen and (min-width: 1024px) {
      .slider--whats-on .slick-next {
        -webkit-transform: translateX(12.5rem);
                transform: translateX(12.5rem); } }
    .slider--whats-on .slick-next:after {
      content: '\203A'; }
  .slider--whats-on img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.slider--video .slick-arrow:after {
  color: #0c0c0c; }

.event {
  padding: 3.625rem 0;
  padding-bottom: 6.25rem;
  outline: none !important; }
  @media only screen and (min-width: 1024px) {
    .event {
      max-width: 26rem; } }

@media only screen and (min-width: 1024px) {
  .event__date {
    margin-bottom: 1rem; } }

.event__title {
  margin-bottom: 0.75rem;
  line-height: 1.75rem; }

.event__desc {
  line-height: 1.5rem; }

.slider-wrapper {
  position: relative; }
  .slider-wrapper .link--section {
    position: absolute;
    bottom: 3.75rem;
    left: 6.5rem; }

.article p,
.article div {
  font-family: "Roboto", sans-serif !important; }

.article span {
  border: none !important; }

.article--bookings .article__content {
  padding: 0; }

.article--bookings .article__header {
  width: 100%; }
  .article--bookings .article__header img {
    object-fit: cover; }

.article--bookings .floorplan {
  margin-bottom: 6.5rem; }
  .article--bookings .floorplan img {
    object-fit: contain;
    object-position: left; }

.article--bookings .bookings-info {
  text-align: left;
  background-color: #dfe8f1;
  padding: 3.125rem 2rem; }
  @media only screen and (min-width: 1024px) {
    .article--bookings .bookings-info {
      margin-left: 6.5rem; } }
  .article--bookings .bookings-info table {
    width: 100%; }
  .article--bookings .bookings-info th {
    margin: 0.625rem 0;
    margin-top: 1rem;
    display: block; }
  .article--bookings .bookings-info h2,
  .article--bookings .bookings-info h3 {
    font-family: "Roboto", sans-serif;
    color: #1B1464;
    font-style: italic;
    font-weight: 700; }
  .article--bookings .bookings-info h3 {
    font-style: normal;
    font-weight: 700;
    margin-bottom: 1rem; }

.article--bookings .bookings-info__row {
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between; }

.article--bookings .bookings-info__category {
  margin-bottom: 1.875rem; }

.article--bookings .article__main {
  margin-bottom: 6.5rem; }

.article--services .article__body {
  grid-template-columns: 8fr 3fr; }

.article--services .article__footer {
  margin-bottom: 7.1875rem; }

@media only screen and (min-width: 1024px) {
  .article--about {
    padding: 0; } }

@media only screen and (min-width: 1024px) {
  .article--about .article__side {
    position: relative; } }

@media only screen and (min-width: 1024px) {
  .article--about .cta {
    position: absolute;
    top: 8.125rem;
    left: 0; } }

.article--about .gallery-section {
  margin-bottom: 6.5rem; }
  .article--about .gallery-section:nth-last-child(1) {
    margin-bottom: 0; }
  .article--about .gallery-section h2 {
    margin-bottom: 1.75rem;
    opacity: 0.8; }
    @media only screen and (min-width: 1024px) {
      .article--about .gallery-section h2 {
        font-size: 2rem; } }
  .article--about .gallery-section .slider--gallery {
    margin-left: -1.25rem; }

.article__header,
.article__footer {
  position: relative;
  margin-bottom: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  max-height: 50vh; }
  @media only screen and (min-width: 1024px) {
    .article__header,
    .article__footer {
      height: 34.375rem;
      margin-bottom: 6.25rem; } }
  .article__header > img,
  .article__footer > img {
    height: 100%;
    width: 100%;
    max-height: inherit;
    object-fit: contain;
    object-position: left; }

.article__footer--gallery {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: unset; }

.article__header--venue-hire {
  background-color: rgba(12, 12, 12, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: center;
  color: rgba(17, 17, 17, 0.4);
  font-weight: 700;
  font-size: 2rem; }
  @media only screen and (min-width: 1024px) {
    .article__header--venue-hire {
      min-height: 34.375rem; } }
  .article__header--venue-hire img {
    width: 100%;
    height: auto;
    object-fit: contain; }

.article--whats-on .article__header {
  height: auto;
  max-height: 50vh; }
  .article--whats-on .article__header img {
    height: 100%;
    width: 100%;
    max-height: inherit;
    object-fit: contain;
    object-position: left; }

.article--safeguarding .article__body {
  padding-bottom: 0 !important; }

.article--safeguarding .article__header img {
  object-fit: cover; }

.article--safeguarding ul,
.article--safeguarding ol {
  list-style-type: none;
  margin-bottom: 3.25rem;
  line-height: inherit;
  position: relative; }

.article--safeguarding ul,
.article--safeguarding ol {
  padding-left: 1.5rem; }

.article--safeguarding li {
  list-style-type: unset;
  line-height: 2;
  margin-bottom: 0.75rem;
  padding-left: 0.75rem; }
  @media only screen and (min-width: 1024px) {
    .article--safeguarding li {
      padding-left: 1rem; } }
  .article--safeguarding li::before {
    content: '';
    height: 2px;
    width: 1rem;
    background-color: #1B1464;
    display: block;
    position: absolute;
    left: 0;
    top: 1rem;
    border-radius: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }

.article--safeguarding li::before,
.article--safeguarding li::after {
  background: #ffffff; }

@media only screen and (min-width: 1024px) {
  .article__body {
    display: grid;
    grid-template-columns: 9fr 3fr;
    padding-bottom: 10.5rem; } }

@media only screen and (min-width: 1024px) {
  .article__body--wide {
    grid-template-columns: 1fr; } }

@media only screen and (min-width: 1024px) {
  .article__body--news {
    grid-template-columns: 8fr 2fr; } }

.article__main {
  margin-bottom: 0; }
  @media only screen and (min-width: 1024px) {
    .article__main {
      margin-bottom: 0; } }

.article__content {
  font-size: 1rem; }
  @media only screen and (min-width: 1024px) {
    .article__content {
      padding-right: 13rem; } }
  .article__content p {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    font-size: 1.125rem; }
  .article__content code {
    background: #1B1464;
    padding: 2em;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: #ffffff;
    margin-bottom: 6.5rem; }
  .article__content p {
    margin-bottom: 1.75em; }
  .article__content h1,
  .article__content h2,
  .article__content h3,
  .article__content h4,
  .article__content h5 {
    font-weight: 700;
    margin: 1.5em 0;
    font-family: "Libre Baskerville", serif !important; }
  .article__content h1,
  .article__content h2 {
    margin-top: 0;
    margin-bottom: 2em; }
  .article__content a {
    color: #5e97e0 !important;
    word-break: break-all; }
  .article__content [style='text-align: center;'] {
    background: #1B1464;
    text-align: left !important;
    color: #ffffff;
    padding: 2.75rem; }
    @media only screen and (min-width: 1024px) {
      .article__content [style='text-align: center;'] {
        font-size: 1.375rem; } }
    .article__content [style='text-align: center;']:empty {
      padding: 0; }
    .article__content [style='text-align: center;']:nth-last-child(1) {
      margin-bottom: 6.5rem;
      padding-top: 0; }
  .article__content blockquote {
    padding: 0;
    margin: 0; }
    .article__content blockquote p {
      margin: 0; }
    .article__content blockquote a {
      position: relative;
      display: block;
      font-weight: 700;
      padding: 1rem 2rem;
      background: #dfe8f1;
      width: 100%;
      margin-bottom: 1rem;
      color: #1B1464; }
      @media only screen and (min-width: 1024px) {
        .article__content blockquote a {
          width: 50%; } }
    .article__content blockquote a:hover {
      opacity: 0.75; }
    .article__content blockquote a:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 0.5rem;
      height: 100%;
      background: #1B1464; }

@media only screen and (min-width: 1024px) {
  .article__side {
    padding-top: 18.75rem; } }

@media only screen and (min-width: 1024px) {
  .sidebar__title {
    margin-bottom: 4.875rem; } }

.sidebar__body {
  line-height: 2rem;
  margin-bottom: 1.875rem; }
  @media only screen and (min-width: 1024px) {
    .sidebar__body {
      line-height: 3rem;
      margin-bottom: 5.875rem; } }

.article-subsection {
  padding: 8.5rem 13rem;
  padding-left: 0;
  position: relative;
  margin-bottom: 10.5rem;
  color: #ffffff; }
  .article-subsection:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 200vw;
    background-color: #1B1464;
    z-index: -1; }

.safeguarding-team .team-member {
  line-height: 2.75rem;
  margin: 2.5rem 0; }
  .safeguarding-team .team-member a {
    color: #1B1464;
    line-height: 1rem !important;
    display: block; }

.safeguarding-team .team-member__title {
  width: 50%;
  margin-bottom: 1rem; }

.safeguarding-team .team-member__img {
  width: 16.25rem;
  height: 18.75rem;
  margin-bottom: 2rem; }
  .safeguarding-team .team-member__img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.article-text {
  margin-bottom: 6.5rem; }
  @media only screen and (min-width: 1024px) {
    .article-text {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .article-text .article-text__copy {
        width: 50%; }
      .article-text .article-text__img {
        padding-top: 0; } }
  .article-text:nth-last-child(1) {
    margin-bottom: 3.25rem; }
  .article-text h1 {
    font-size: 3.125rem; }
  .article-text br {
    margin-bottom: 1.5rem; }

@media only screen and (min-width: 1024px) {
  .article-text--left .article-text__img {
    margin-left: auto; } }

@media only screen and (min-width: 1024px) {
  .article-text--right {
    flex-direction: row-reverse; }
    .article-text--right .article-text__img {
      margin-right: auto; } }

.article-text__copy {
  margin-bottom: 6.5rem; }

.article-text__img {
  width: 26rem;
  max-width: 100%;
  height: auto;
  position: relative; }
  .article-text__img img {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover; }

.article-text__small {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 1.5rem; }

.text-with-img {
  display: grid;
  grid-gap: 1.5rem;
  margin-bottom: 3.75rem; }
  @media only screen and (min-width: 640px) {
    .text-with-img {
      margin-bottom: 9.75rem;
      grid-gap: 6.5rem 0;
      display: flex;
      flex-direction: row; } }
  .text-with-img:nth-last-child(1) {
    margin-bottom: 0; }
  .text-with-img h2 {
    margin-bottom: 3rem; }
  .text-with-img .btn, .text-with-img .form input[type="submit"], .form .text-with-img input[type="submit"] {
    margin-top: 1.75rem; }
    @media only screen and (min-width: 1024px) {
      .text-with-img .btn, .text-with-img .form input[type="submit"], .form .text-with-img input[type="submit"] {
        margin-top: 5rem; } }
  @media only screen and (min-width: 1024px) {
    .text-with-img__img {
      margin-top: 1.5625rem; } }
  .text-with-img p {
    line-height: 1.6; }

.text-with-img--life-event {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 3.25rem;
  padding-bottom: 3.25rem;
  border-bottom: 1px solid rgba(249, 249, 249, 0.25); }
  @media only screen and (min-width: 640px) {
    .text-with-img--life-event {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 9.75rem;
      grid-gap: 6.5rem 0;
      display: flex;
      flex-direction: row; } }
  .text-with-img--life-event:nth-last-child(1) {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }

@media only screen and (min-width: 640px) {
  .text-with-img:nth-child(odd) .text-with-img__img {
    margin-left: auto;
    height: auto; } }

.text-with-img:nth-child(odd) .text-with-img__body {
  padding-left: 0; }

.text-with-img:nth-child(even) {
  text-align: left; }
  @media only screen and (min-width: 640px) {
    .text-with-img:nth-child(even) {
      flex-direction: row-reverse; } }
  .text-with-img:nth-child(even) .text-with-img__body {
    padding-right: 0; }
  .text-with-img:nth-child(even) .text-with-img__img {
    margin-right: auto; }

.text-with-img__body {
  max-width: 100%; }
  @media only screen and (min-width: 640px) {
    .text-with-img__body {
      padding: 0 6.5rem;
      width: 39rem; } }

.text-with-img__img {
  height: auto;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  padding-bottom: 3rem; }
  @media only screen and (min-width: 640px) {
    .text-with-img__img {
      padding-bottom: 3.25rem;
      width: 32.5rem; } }
  .text-with-img__img img {
    height: auto;
    width: 100%;
    object-fit: cover; }

.text-with-img__img-description {
  position: absolute;
  top: calc(100% - 1.5rem);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.75rem; }
  @media only screen and (min-width: 1024px) {
    .text-with-img__img-description {
      top: 100%;
      font-size: 1.125rem; } }

.text-card {
  padding-bottom: 6.5rem; }
  @media only screen and (min-width: 1024px) {
    .text-card {
      padding: 0 3.25rem; } }
  .text-card h2 {
    margin-bottom: 4rem; }
  .text-card h3 {
    margin-bottom: 2.5rem; }
  .text-card > div {
    margin-bottom: 3.25rem; }
  .text-card .date-time-info {
    padding: 0.625rem 0;
    margin-bottom: 2rem; }

@media only screen and (min-width: 1024px) {
  .text-card--padded--2x {
    padding: 0 6.5rem; } }

.card {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative; }
  @media only screen and (min-width: 1024px) {
    .card {
      box-shadow: 0 0.125rem 0.375rem 0 rgba(12, 12, 12, 0.12); } }
  .card .like-btn {
    margin-top: auto;
    margin-bottom: 0.5rem; }
  .card .overlay-link {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100%;
    opacity: 0; }
  .card iframe {
    background: #0c0c0c;
    border: none; }

.card__stores {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.625rem;
  margin-top: auto;
  padding-right: 3rem; }
  .card__stores a > img {
    object-fit: contain !important; }

.card--left .card__img img {
  object-position: left;
  object-fit: contain; }

.card--left .card__body {
  padding-left: 0;
  margin-bottom: 1.75rem; }

.card--small .card__btn {
  margin-top: auto; }

.card__link {
  color: #0c0c0c;
  transition: 0.3s; }
  .card__link .card__img {
    overflow: hidden; }
  .card__link * {
    transition: 0.3s; }
  .card__link:hover {
    color: #678699; }
    .card__link:hover img {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.card--compact .card__title {
  margin-bottom: 1.75rem; }

.card--compact .card__description {
  margin-bottom: 0.625rem;
  font-size: 0.875rem; }
  @media only screen and (min-width: 1024px) {
    .card--compact .card__description {
      font-size: 1rem;
      margin-bottom: 1.75rem; } }

.card--compact .card__copy {
  display: none; }

.card--horizontal {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #0c0c0c; }
  @media only screen and (min-width: 1024px) {
    .card--horizontal {
      flex-direction: row; } }
  .card--horizontal:nth-last-child(1) {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 6.25rem; }
  .card--horizontal .card__body {
    padding: 0; }
    @media only screen and (min-width: 1024px) {
      .card--horizontal .card__body {
        width: auto; } }
  .card--horizontal .card__copy {
    line-height: 1.5rem; }
  .card--horizontal .card__btn {
    padding: 0; }
    @media only screen and (min-width: 1024px) {
      .card--horizontal .card__btn {
        margin-top: 3.25rem;
        margin-left: auto; } }

.card--horizontal.white {
  border-color: #ffffff; }

.card--news {
  padding: 0;
  margin: 0 auto;
  border: none;
  max-width: 26rem;
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 1024px) {
    .card--news {
      max-width: unset;
      display: flex;
      position: relative;
      margin-bottom: 5rem; } }
  .card--news .card__img {
    display: flex;
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 1024px) {
      .card--news .card__img {
        width: 26rem;
        min-width: 26rem;
        height: auto;
        display: inline-block;
        position: relative;
        top: 0;
        left: 0; } }
    .card--news .card__img img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
  .card--news .card__social {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    margin: 1.5rem 0; }
    @media only screen and (min-width: 1024px) {
      .card--news .card__social {
        justify-content: flex-start;
        margin: 0;
        padding-right: 3rem; } }
    .card--news .card__social button {
      margin-left: 1rem; }
      .card--news .card__social button:nth-child(1) {
        margin-left: 0; }
  .card--news .card__body {
    padding: 0;
    padding: 1rem; }
    @media only screen and (min-width: 1024px) {
      .card--news .card__body {
        padding: 2.875rem 6.5rem;
        padding-right: 13rem;
        display: inline-block; } }
  @media only screen and (min-width: 1024px) {
    .card--news .card__copy {
      line-height: 1.5rem; } }
  .card--news .card__btn {
    padding: 0; }
    @media only screen and (min-width: 1024px) {
      .card--news .card__btn {
        margin-top: 3.25rem;
        margin-left: auto; } }

.card--transparent {
  background: transparent;
  box-shadow: none; }

.card--big .card__img {
  padding: 1rem; }
  @media only screen and (min-width: 1024px) {
    .card--big .card__img {
      height: 12.5rem; } }

@media only screen and (min-width: 1024px) {
  .card--big .card__body {
    padding: 1.5rem 1.5rem;
    padding-bottom: 1.5rem; } }

@media only screen and (min-width: 1024px) {
  .card--big .card__title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem; } }

@media only screen and (min-width: 1024px) {
  .card--big .card__copy {
    margin-bottom: 1.25rem;
    font-size: 1.125rem; } }

@media only screen and (min-width: 1024px) {
  .card--big .card__btn {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2.125rem; } }

.card--team {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1024px) {
    .card--team {
      flex-direction: row;
      margin-bottom: 3.25rem; } }
  .card--team a {
    color: #5e97e0 !important; }
  .card--team .card__img {
    height: 13rem;
    min-width: 13rem;
    width: 13rem;
    background-color: #dfe8f1;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 1024px) {
      .card--team .card__img {
        margin-bottom: 5rem; } }
  .card--team .card__body {
    padding: 0; }
    @media only screen and (min-width: 1024px) {
      .card--team .card__body {
        margin-left: 3.25rem;
        padding-right: 3.25rem; } }
  .card--team .card__title {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 0; }
    @media only screen and (min-width: 1024px) {
      .card--team .card__title {
        font-size: 1.75rem;
        margin-top: 1.75rem; } }
  .card--team .card__info {
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
    font-weight: 500;
    opacity: 0.86; }
  .card--team .card__description {
    font-size: 0.75rem; }
    @media only screen and (min-width: 1024px) {
      .card--team .card__description {
        margin-bottom: 0;
        font-size: 0.875rem; } }
  .card--team .card__link a {
    color: rgba(12, 12, 12, 0.7);
    font-size: 1rem;
    text-decoration: none; }

.card__img {
  height: 12.5rem;
  width: 100%; }
  .card__img img {
    height: 100%;
    width: 100%;
    object-fit: contain; }
  .card__img .empty-image {
    background-color: rgba(17, 17, 17, 0.4);
    height: 100%;
    width: 100%;
    opacity: 0.25; }

.card__body {
  padding: 1.25rem;
  padding-bottom: 0; }

.card__title {
  font-weight: 640px;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  line-height: 1.75rem; }
  @media only screen and (min-width: 1024px) {
    .card__title {
      font-size: 1.375rem;
      margin-bottom: 1rem; } }
  .card__title a {
    color: inherit;
    transition: 0.3s; }
    .card__title a:hover {
      opacity: 0.65; }

.card__copy {
  color: rgba(12, 12, 12, 0.7);
  font-size: 0.75rem; }
  @media only screen and (min-width: 1024px) {
    .card__copy {
      line-height: 1.5rem;
      margin-top: 0;
      font-size: 1rem;
      margin-bottom: 1.5rem; } }
  .card__copy.white {
    color: #ffffff; }

.date-time-info {
  border-top: 1px solid #EABB5F;
  border-bottom: 1px solid #EABB5F;
  font-weight: 640px;
  color: #EABB5F; }
  @media only screen and (min-width: 1024px) {
    .date-time-info {
      padding: 1.5rem 0; } }
  .date-time-info span:nth-child(1) {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid #1B1464; }

.date-time-info--no-border {
  border: none;
  font-weight: 640px;
  padding: 0;
  margin-bottom: 1.125rem; }
  .date-time-info--no-border span:nth-last-child(1) {
    border-right: none; }

.date-time-info--small {
  padding: 0;
  border: none;
  margin-bottom: 1.25rem; }
  .date-time-info--small div {
    line-height: 1;
    display: block;
    margin-bottom: 0.5rem; }
  .date-time-info--small div:nth-child(1),
  .date-time-info--small span:nth-last-child(1) {
    margin-right: 0;
    padding-right: 0;
    border: none; }
  .date-time-info--small div:nth-last-child(1) {
    margin-bottom: 0; }

.card__btn {
  margin-top: auto;
  padding: 0 1.25rem;
  padding-bottom: 2rem; }

.card-padding {
  padding: 1.275rem;
  padding-bottom: 0; }
  @media only screen and (min-width: 1024px) {
    .card-padding {
      padding: 1.5rem; } }
  .card-padding .card__body,
  .card-padding .card__btn {
    padding-left: 0;
    padding-right: 0; }

@media only screen and (min-width: 1024px) {
  .card--circle {
    width: 26rem; } }

.card--circle h1,
.card--circle h2 {
  font-size: 32px;
  margin-bottom: 1rem;
  margin-top: 0; }

.card--circle .btn, .card--circle .form input[type="submit"], .form .card--circle input[type="submit"] {
  margin-top: auto; }

.card--video {
  background: transparent;
  box-shadow: none;
  padding: 0; }
  .card--video .card__title {
    font-size: 1.5rem;
    line-height: 1.5; }
  .card--video .card__date {
    margin-bottom: 0.5rem;
    opacity: 0.75; }

.card--prayer {
  border-top: 0.625rem solid #EABB5F;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(12, 12, 12, 0.12);
  padding-bottom: 0.875rem;
  margin-bottom: 0.3125rem; }
  .card--prayer .card__body > div {
    font-size: 1rem;
    margin-bottom: 0.625rem; }
  .card--prayer strong {
    color: #1B1464; }
  .card--prayer .card__description {
    font-size: 1rem;
    line-height: 2; }
    @media only screen and (min-width: 1024px) {
      .card--prayer .card__description {
        font-size: 1.25rem; } }

.card--search {
  padding: 1.5rem;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(12, 12, 12, 0.12);
  margin-bottom: 1.5rem; }
  .card--search p {
    margin-bottom: 0;
    color: rgba(12, 12, 12, 0.7); }

.calendar-wrapper {
  position: relative;
  z-index: 1; }
  .calendar-wrapper h2 {
    font-size: 1rem;
    font-family: "Roboto", sans-serif; }
  .calendar-wrapper .fc-scroller {
    height: auto !important; }
  .calendar-wrapper .fc-button {
    border-radius: 0;
    border: 1px solid #1B1464;
    color: #f9f9f9;
    text-transform: capitalize; }
  .calendar-wrapper .fc-day-grid-event {
    cursor: pointer;
    transition: 0.3s;
    min-height: 0.625rem; }
    .calendar-wrapper .fc-day-grid-event:hover {
      opacity: 0.8; }
  .calendar-wrapper .fc-time {
    font-size: 0.55rem;
    display: none; }
    @media only screen and (min-width: 1024px) {
      .calendar-wrapper .fc-time {
        font-size: 0.625rem; } }
  .calendar-wrapper .fc-title {
    font-weight: 700;
    font-size: 0.625rem; }
    @media only screen and (min-width: 640px) {
      .calendar-wrapper .fc-title {
        display: inline-block;
        font-size: 0.625rem; } }
  .calendar-wrapper .fc-today-button[disabled] {
    opacity: 1;
    background-color: #EABB5F;
    border-color: #EABB5F; }
  .calendar-wrapper .fc-today-button {
    background-color: #1B1464; }
  .calendar-wrapper .fc-button:hover,
  .calendar-wrapper .fc-button:focus {
    box-shadow: none;
    -webkit-transform: none;
            transform: none;
    background: #1B1464;
    color: #f9f9f9; }
  .calendar-wrapper .fc-button:active {
    background: #261d8f; }
  .calendar-wrapper .rbc-toolbar-label {
    text-align: right; }
  .calendar-wrapper .fc-toolbar {
    flex-wrap: wrap; }
    .calendar-wrapper .fc-toolbar h2 {
      width: 6.5rem;
      white-space: nowrap; }
  .calendar-wrapper .fc-list-item {
    border-left-style: solid;
    border-left-width: 10px; }
  .calendar-wrapper .fc-list-heading {
    font-weight: 600;
    background: transparent; }
    .calendar-wrapper .fc-list-heading > td {
      background: rgba(27, 20, 100, 0.5);
      color: #f9f9f9; }
  .calendar-wrapper .fc-list-table tbody {
    display: flex;
    flex-direction: column; }
  .calendar-wrapper .fc-list-table tr {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin-bottom: 0.5rem; }
    .calendar-wrapper .fc-list-table tr td {
      width: 100%;
      border-top: none;
      border-bottom: 1px solid rgba(12, 12, 12, 0.1); }
  .calendar-wrapper .fc-list-table .fc-widget-header {
    width: 100%;
    border: none; }
  .calendar-wrapper .fc-list-table td {
    display: flex;
    align-items: center;
    padding: 0.625rem 1rem;
    border-right: 0;
    border-left: 0;
    font-size: 0.875rem; }
    @media only screen and (min-width: 1024px) {
      .calendar-wrapper .fc-list-table td {
        font-size: 1rem; } }
  .calendar-wrapper .fc-list-table .fc-list-item-marker {
    display: none; }
  .calendar-wrapper .fc-list-table .fc-list-item-time {
    font-size: 0.625rem;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 4.5rem; }
    @media only screen and (min-width: 1024px) {
      .calendar-wrapper .fc-list-table .fc-list-item-time {
        font-size: 0.75rem;
        flex-direction: row;
        width: 13rem;
        justify-content: space-between; } }
  .calendar-wrapper .fc-list-table .fc-list-item-title {
    text-align: right;
    justify-content: flex-end; }
  .calendar-wrapper .fc-list-heading-alt {
    margin-left: 0.625rem;
    font-weight: 400;
    opacity: 0.85; }

.calendar-wrapper__actions {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem; }
  @media only screen and (min-width: 1024px) {
    .calendar-wrapper__actions {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end; } }
  .calendar-wrapper__actions .btn, .calendar-wrapper__actions .form input[type="submit"], .form .calendar-wrapper__actions input[type="submit"] {
    margin-bottom: 0.625rem;
    flex: 1 1; }
    @media only screen and (min-width: 1024px) {
      .calendar-wrapper__actions .btn, .calendar-wrapper__actions .form input[type="submit"], .form .calendar-wrapper__actions input[type="submit"] {
        margin-bottom: 0;
        margin-left: 0.625rem; } }
    .calendar-wrapper__actions .btn:nth-child(1), .calendar-wrapper__actions .form input:nth-child(1)[type="submit"], .form .calendar-wrapper__actions input:nth-child(1)[type="submit"] {
      margin-left: 0; }
    .calendar-wrapper__actions .btn img, .calendar-wrapper__actions .form input[type="submit"] img, .form .calendar-wrapper__actions input[type="submit"] img {
      height: auto;
      width: 1.5rem;
      object-fit: contain;
      margin-left: 0.625rem;
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }

.calendar-wrapper__filters {
  font-size: 0.75rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0; }
  .calendar-wrapper__filters li {
    margin: 0.25rem 0.625rem;
    white-space: nowrap;
    list-style-type: none; }
    .calendar-wrapper__filters li::before {
      display: none; }
    .calendar-wrapper__filters li button {
      padding: 0.5rem 0.625rem;
      font-size: inherit;
      background: none; }

.scroller {
  width: 100vw;
  overflow-x: scroll;
  display: flex;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding-left: 2.1875rem; }

@media only screen and (min-width: 1024px) {
  .calendar-wrapper--s {
    display: none; } }

.service {
  margin-bottom: 2.5rem; }
  .service .bold {
    font-size: 1.5rem;
    margin-bottom: 0.625rem; }

.event-about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0.625rem;
  position: relative;
  padding: 0.625rem;
  padding-left: 0.75rem;
  padding-right: 2rem;
  border-radius: 0;
  width: 100%;
  border-width: 0.125rem;
  border-left-width: 0.375rem;
  background: none; }
  @media only screen and (min-width: 1024px) {
    .event-about {
      padding-right: 6.5rem; } }
  .event-about:nth-child(1) {
    margin-top: 1.2rem; }
  .event-about:hover {
    -webkit-transform: none;
            transform: none;
    box-shadow: none; }
  .event-about:focus, .event-about:active {
    border-width: 0.125rem;
    border-left-width: 0.375rem; }

.event-about__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.625rem;
  opacity: 0.85;
  margin: auto;
  height: 100%;
  width: 1rem;
  display: flex;
  align-items: center; }
  .event-about__icon * {
    width: 100%; }

.event-about__title {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.45; }

.event-about__color {
  background-color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0.375rem; }

.event-about__img {
  height: 4.6875rem;
  width: 4.6875rem;
  margin-bottom: 1.5rem;
  border-radius: 50%;
  overflow: hidden; }
  .event-about__img img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.event-about__description {
  font-size: 0.75rem; }

.services-wrap {
  overflow-y: auto;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  margin-bottom: 2rem;
  padding-right: 1rem; }
  @media only screen and (min-width: 1024px) {
    .services-wrap {
      border: none;
      margin: none; } }

.map-wrap {
  height: 22rem;
  width: 100%;
  margin-bottom: 4rem;
  position: relative; }
  .map-wrap > div {
    height: 100% !important;
    width: 100% !important; }

.tweet {
  background-color: #ffffff;
  padding: 1.25rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.035); }

.tweet__date {
  font-size: 1.125rem;
  color: #EABB5F;
  margin-bottom: 0.75rem; }

.tweet__name {
  margin-bottom: 0.625rem;
  color: #2196f3; }

@media only screen and (min-width: 1024px) {
  .tweet__content {
    font-size: 1rem; } }

.tweet-feed {
  display: grid;
  grid-gap: 1.5rem; }

.tweets-section {
  padding: 3.25rem 0; }
  @media only screen and (min-width: 1024px) {
    .tweets-section {
      padding: 13.25rem 0;
      padding-bottom: 7.1875rem; } }

.tweets__subsection {
  padding-bottom: 8rem; }
  .tweets__subsection .tweets__title {
    display: block;
    color: #1B1464;
    font-size: 2rem;
    margin-bottom: 4rem; }
    .tweets__subsection .tweets__title:hover {
      transition: 0.3s;
      opacity: 0.7; }
  .tweets__subsection:nth-last-child(1) {
    padding-bottom: 0; }

.tweets-section__wrap {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1.5rem; }
  @media only screen and (min-width: 640px) {
    .tweets-section__wrap {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (min-width: 1024px) {
    .tweets-section__wrap {
      grid-template-columns: repeat(4, 1fr); } }
  .tweets-section__wrap .tweet {
    border: 1px solid rgba(0, 0, 0, 0.1); }

.homepage-tweets {
  display: grid;
  grid-gap: 1.5rem; }

.search-result {
  display: flex;
  flex-direction: column;
  width: 19.0625rem;
  margin: 0 auto;
  margin-bottom: 2.5rem; }

.search-result__img {
  position: relative;
  height: 8.75rem;
  width: 19.0625rem;
  margin-bottom: 0.75rem; }
  .search-result__img img {
    object-fit: cover;
    height: 100%;
    width: 100%; }

.search-result__icons {
  padding: 1rem;
  border-top: 1px solid rgba(24, 24, 24, 0.05);
  border-bottom: 1px solid rgba(24, 24, 24, 0.05); }

.tags {
  width: calc(100% + 2*0.3125rem);
  position: absolute;
  top: 0;
  left: -0.3125rem;
  padding: 0.875rem 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem 0;
  justify-content: space-between; }

.tags__tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.6875rem;
  height: 1.25rem;
  background: #678699;
  color: #0c0c0c;
  font-weight: 600;
  letter-spacing: 0.01875rem;
  font-size: 0.625rem;
  text-transform: uppercase; }
  .tags__tag:nth-child(2) {
    background: #3bd45c; }
  .tags__tag:nth-child(3) {
    background: #1B1464; }

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }

.info-row__items span {
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0.6875rem;
  position: relative; }
  .info-row__items span:after {
    content: '';
    display: block;
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    right: -0.6875rem;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background: #0c0c0c;
    justify-content: center;
    align-items: center; }
  .info-row__items span:nth-child(1) {
    margin-left: 0; }
  .info-row__items span:nth-last-child(1) {
    margin-right: 0; }
    .info-row__items span:nth-last-child(1):after {
      display: none; }

.info-row__price {
  font-weight: 700;
  font-size: 1.125rem;
  display: flex;
  padding-left: 1.875rem;
  position: relative; }
  .info-row__price:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.3125rem 0.375rem 0.3125rem;
    border-color: transparent transparent #007bff transparent; }

.info-row__price.pos:before {
  border-color: transparent transparent #07a028 transparent; }

.info-row__price.neg:before {
  border-color: transparent transparent #ff0500 transparent;
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg); }

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

/* Modal Content/Box */
.modal-content {
  max-width: 50%;
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  z-index: 2;
  width: 80%;
  /* Could be more or less, depending on screen size */ }

/* Modal injected content */
#modal-injected_content {
  max-height: calc(100vh - 210px);
  overflow-y: auto; }

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 1.75rem;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

body.modal-open {
  overflow: hidden; }

.ReactModal__Overlay {
  z-index: 20;
  height: 100%;
  overflow-y: scroll !important;
  background: rgba(255, 255, 255, 0.5) !important;
  outline: none !important; }
  @media only screen and (min-width: 640px) {
    .ReactModal__Overlay {
      padding: 6.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.5) !important; } }
  .ReactModal__Overlay .modal__close {
    text-align: right;
    padding-bottom: 2rem;
    font-size: 1.85rem;
    line-height: 1;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    transition: 0.3s; }
    .ReactModal__Overlay .modal__close:hover {
      color: #678699; }

.ReactModal__Content {
  border-radius: 0;
  border: none !important;
  padding: 3.125rem 2rem !important;
  top: auto !important;
  left: auto !important;
  bottom: auto !important;
  right: auto !important;
  margin: auto !important;
  margin-top: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  position: relative !important;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.25); }
  @media only screen and (min-width: 640px) {
    .ReactModal__Content {
      padding: 2rem 2rem !important;
      padding-bottom: 4rem !important;
      width: 26rem; } }

.ReactModal__Body--open {
  overflow: hidden; }

.modal__bottom {
  height: 6.5rem;
  display: block;
  background: #EABB5F; }

.modal--mail {
  width: 100%; }
  @media only screen and (min-width: 640px) {
    .modal--mail {
      max-width: 37.5rem; } }

.modal__title {
  font-family: "Libre Baskerville", serif;
  font-size: 1.5rem;
  line-height: 1.25; }
  @media only screen and (min-width: 1024px) {
    .modal__title {
      font-size: 2.25rem; } }

.image-fixed-wrapper {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.image {
  max-width: 100%; }

.image-fixed {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover; }

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, #1B1464 100%); }

.feed__header-wrapper {
  display: flex;
  flex-direction: column; }

.feed__header-menu {
  display: flex;
  justify-content: space-around; }
  .feed__header-menu > a {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(12, 12, 12, 0.7); }
  .feed__header-menu .menu-active {
    border-bottom: 1px solid #1B1464; }

.partners {
  display: grid;
  grid-gap: 3rem 3rem;
  grid-template-columns: repeat(2, 1fr);
  min-height: 18.5rem; }
  @media only screen and (min-width: 640px) {
    .partners {
      grid-template-columns: repeat(6, 1fr); } }
  @media only screen and (min-width: 1024px) {
    .partners {
      grid-template-columns: repeat(8, 1fr); } }

.partner {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;
  color: #0c0c0c; }

.club-header {
  padding-bottom: 3rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #aaa; }

.partner--big {
  font-size: 1.25rem;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 0; }
  @media only screen and (min-width: 1024px) {
    .partner--big {
      width: 19.5rem; } }
  .partner--big a {
    font-size: 1.25em;
    color: #EABB5F; }
  .partner--big .partner__img {
    width: 9.75rem;
    height: auto;
    margin-bottom: 1.5rem; }
    .partner--big .partner__img img {
      height: 100%; }

.partner__img {
  margin-bottom: 1.25rem;
  width: 100%;
  height: 12.5rem;
  display: flex;
  justify-content: center; }
  .partner__img img {
    width: 100%;
    height: 100%;
    object-fit: contain !important; }

/*------------------------------------*\
# LAYOUT
\*------------------------------------*/
/*------------------------------------*\
# LAYOUT
\*------------------------------------*/
.display {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto; }

.display--between {
  min-height: 100vh;
  justify-content: space-between; }

.display--stretch {
  align-items: stretch; }

.block--full {
  width: 100%; }

.flex {
  display: flex; }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-end {
  justify-content: flex-end; }

.display--columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem; }

.layout--text {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.layout--main {
  max-width: 19.0625rem;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 640px) {
    .layout--main {
      max-width: 51.25rem; } }
  @media only screen and (min-width: 1024px) {
    .layout--main {
      max-width: 78rem; } }
  .layout--main iframe {
    border: none;
    width: 100%; }

.layout--full {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  @media only screen and (min-width: 640px) {
    .layout--full {
      padding-left: 0;
      padding-right: 0; } }

.layout--padded {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media only screen and (min-width: 640px) {
    .layout--padded {
      padding-top: 3rem;
      padding-bottom: 3rem; } }

.layout--padded--double {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media only screen and (min-width: 640px) {
    .layout--padded--double {
      padding-top: 6rem;
      padding-bottom: 6rem; } }

.layout--soft-horizontal-pad {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.section {
  position: relative;
  transition: 0.3s; }

.section__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1B1464;
  z-index: -1; }
  .section__bg img {
    height: 100%;
    width: 100%;
    transition: 0.3s;
    object-fit: cover;
    z-index: 0;
    position: relative;
    opacity: 0.8; }

.section__bg--padded img {
  height: calc(100% - 18.75rem); }

.section__bg--half {
  width: 50%; }

.section__bg--right {
  left: auto;
  right: 0; }

.subsection {
  margin-bottom: 1.5rem; }

.subsection--padded {
  padding: 0 6.5rem; }

.subsection--padded--l {
  padding-left: 6.5rem; }

.subsection--text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%; }

.subsection__body {
  height: 100%;
  margin-bottom: 6.5rem; }
  .subsection__body p {
    font-size: 1rem; }
  .subsection__body h2 {
    margin-bottom: 5rem; }

.subsection__img {
  height: 100%;
  width: 100%;
  display: block; }
  .subsection__img img {
    height: 20rem;
    width: 100%;
    object-fit: cover; }

.section--header {
  padding-bottom: 3.75rem; }
  @media only screen and (min-width: 1024px) {
    .section--header {
      padding-bottom: 5rem; } }
  .section--header p {
    margin: 0; }

.section--double {
  margin-bottom: 3rem; }
  @media only screen and (min-width: 640px) {
    .section--double {
      margin-bottom: 6rem; } }

.section--dark {
  background-color: #0c0c0c;
  color: #1B1464; }

.section--primary {
  background-color: #1B1464; }

.section--light {
  background-color: #dfe8f1; }

.section--offset {
  background: transparent;
  position: relative; }

.section--offset:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 100%;
  width: 200vw;
  z-index: -1;
  background-color: #dfe8f1; }

.section--offset.section--primary::after {
  background: #1B1464; }

.section--center-inblock {
  text-align: center; }

.section--padded--tb {
  padding: 3.125rem 0; }
  @media only screen and (min-width: 1024px) {
    .section--padded--tb {
      padding: 5.3125rem 0; } }

.section--padded--bottom {
  padding-bottom: 6.5rem; }

.section--padded--tb--2x {
  padding: 3.25rem 0; }
  @media only screen and (min-width: 1024px) {
    .section--padded--tb--2x {
      padding: 9.375rem 0; } }

@media only screen and (min-width: 1024px) {
  .section--padded {
    padding-left: 6.5rem;
    padding-top: 6.25rem;
    padding-bottom: 5.25rem; } }

.section--color-tertiary {
  background-color: #678699; }

.section__title h1 {
  margin-bottom: 5rem; }

/*------------------------------------*\
# GRID
\*------------------------------------*/
.grid {
  display: flex;
  flex-wrap: wrap; }
  .grid--reverse-mobile {
    flex-wrap: wrap-reverse; }
    @media only screen and (min-width: 1024px) {
      .grid--reverse-mobile {
        flex-wrap: wrap-reverse; } }

.no-wrap {
  flex-wrap: no-wrap !important; }

.grid--gutters {
  margin-left: calc(1.5rem / -2);
  margin-right: calc(1.5rem / -2); }

.grid--gutters > .cell {
  padding-left: calc(1.5rem / 2);
  padding-right: calc(1.5rem / 2); }

.grid--gutters--double {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.grid--gutters--double > .cell {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.cell {
  box-sizing: border-box; }

.cell--auto {
  flex: 1 1; }

.cell--1 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (1 / 12)); }

.cell--2 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (2 / 12)); }

.cell--3 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (3 / 12)); }

.cell--4 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (4 / 12)); }

.cell--5 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (5 / 12)); }

.cell--6 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (6 / 12)); }

.cell--7 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (7 / 12)); }

.cell--8 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (8 / 12)); }

.cell--9 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (9 / 12)); }

.cell--10 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (10 / 12)); }

.cell--11 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (11 / 12)); }

.cell--12 {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% * (12 / 12)); }

@media only screen and (min-width: 0) {
  .cell--1--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (1 / 12)); }
  .cell--2--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (2 / 12)); }
  .cell--3--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (3 / 12)); }
  .cell--4--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (4 / 12)); }
  .cell--5--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (5 / 12)); }
  .cell--6--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (6 / 12)); }
  .cell--7--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (7 / 12)); }
  .cell--8--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (8 / 12)); }
  .cell--9--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (9 / 12)); }
  .cell--10--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (10 / 12)); }
  .cell--11--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (11 / 12)); }
  .cell--12--small {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (12 / 12)); } }

@media only screen and (min-width: 640px) {
  .cell--1--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (1 / 12)); }
  .cell--2--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (2 / 12)); }
  .cell--3--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (3 / 12)); }
  .cell--4--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (4 / 12)); }
  .cell--5--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (5 / 12)); }
  .cell--6--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (6 / 12)); }
  .cell--7--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (7 / 12)); }
  .cell--8--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (8 / 12)); }
  .cell--9--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (9 / 12)); }
  .cell--10--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (10 / 12)); }
  .cell--11--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (11 / 12)); }
  .cell--12--medium {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (12 / 12)); } }

@media only screen and (min-width: 1024px) {
  .cell--1--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (1 / 12)); }
  .cell--2--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (2 / 12)); }
  .cell--3--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (3 / 12)); }
  .cell--4--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (4 / 12)); }
  .cell--5--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (5 / 12)); }
  .cell--6--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (6 / 12)); }
  .cell--7--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (7 / 12)); }
  .cell--8--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (8 / 12)); }
  .cell--9--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (9 / 12)); }
  .cell--10--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (10 / 12)); }
  .cell--11--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (11 / 12)); }
  .cell--12--large {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (12 / 12)); } }

@media only screen and (min-width: 1200px) {
  .cell--1--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (1 / 12)); }
  .cell--2--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (2 / 12)); }
  .cell--3--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (3 / 12)); }
  .cell--4--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (4 / 12)); }
  .cell--5--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (5 / 12)); }
  .cell--6--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (6 / 12)); }
  .cell--7--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (7 / 12)); }
  .cell--8--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (8 / 12)); }
  .cell--9--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (9 / 12)); }
  .cell--10--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (10 / 12)); }
  .cell--11--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (11 / 12)); }
  .cell--12--xlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (12 / 12)); } }

@media only screen and (min-width: 1440px) {
  .cell--1--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (1 / 12)); }
  .cell--2--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (2 / 12)); }
  .cell--3--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (3 / 12)); }
  .cell--4--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (4 / 12)); }
  .cell--5--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (5 / 12)); }
  .cell--6--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (6 / 12)); }
  .cell--7--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (7 / 12)); }
  .cell--8--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (8 / 12)); }
  .cell--9--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (9 / 12)); }
  .cell--10--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (10 / 12)); }
  .cell--11--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (11 / 12)); }
  .cell--12--xxlarge {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% * (12 / 12)); } }

@media only screen and (min-width: 640px) {
  .grid--2--medium {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem; } }

.grid--3--large {
  display: grid;
  grid-gap: 3.25rem; }
  @media only screen and (min-width: 1024px) {
    .grid--3--large {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3.25rem; } }

.grid--4--large {
  display: grid;
  grid-gap: 1.5rem; }
  @media only screen and (min-width: 1024px) {
    .grid--4--large {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.625rem; } }

.grid--2--large {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6.5rem 13rem; }

.grid--3--extra {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16.25rem; }

.grid--gap--small {
  grid-gap: 6.5rem; }

.grid--contacts {
  display: grid;
  grid-gap: 1.5rem; }
  @media only screen and (min-width: 1024px) {
    .grid--contacts {
      grid-gap: 1.5rem 6.5rem;
      grid-template-columns: repeat(2, 1fr); } }

/*------------------------------------*\
# GRID UTILITIES
\*------------------------------------*/
.grid--center {
  justify-content: center; }

@media only screen and (min-width: 1024px) {
  .grid--end {
    justify-content: flex-end; } }

.show--large {
  display: none; }
  @media only screen and (min-width: 1024px) {
    .show--large {
      display: inline-block; } }

@media only screen and (min-width: 1024px) {
  .hide--large {
    display: none; } }

.header--full {
  background-color: #1B1464;
  padding: 1.5rem; }

.header--full__link {
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center; }

.header--full__arrow {
  height: 1rem;
  margin-right: 0.75rem; }

/*------------------------------------*\
** FOOTER
\*------------------------------------*/
.footer {
  padding-top: 3.25rem; }
  @media only screen and (min-width: 1024px) {
    .footer {
      padding-top: 7.1875rem; } }

.footer__logos {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1024px) {
    .footer__logos {
      margin-top: 1rem;
      margin-bottom: 2.5rem;
      justify-content: center;
      align-items: center; } }

.footer__logos > div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }
  @media only screen and (min-width: 1024px) {
    .footer__logos > div {
      align-items: center;
      justify-content: center;
      margin: 0; } }
  .footer__logos > div a {
    display: block;
    height: 100%;
    height: 2rem;
    padding: 0;
    margin-right: 1.5rem; }
    @media only screen and (min-width: 1024px) {
      .footer__logos > div a {
        margin: 0 1.5rem;
        padding: 1rem 0;
        height: 5rem; } }
    .footer__logos > div a:nth-child(1) {
      -webkit-transform: translateY(0.25rem);
              transform: translateY(0.25rem); }
    .footer__logos > div a:nth-child(2) {
      height: 3.5rem; }
      @media only screen and (min-width: 1024px) {
        .footer__logos > div a:nth-child(2) {
          height: 6.5rem; } }
  .footer__logos > div img {
    min-height: 100%;
    height: 100%;
    width: auto;
    object-fit: contain; }

.logo-digital {
  display: flex;
  color: #0c0c0c;
  font-size: inherit;
  margin-bottom: 3rem;
  flex-direction: column; }
  @media only screen and (min-width: 1024px) {
    .logo-digital {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0; } }
  .logo-digital img {
    width: 3.5rem;
    height: auto;
    object-fit: contain;
    margin-top: 1rem; }
    @media only screen and (min-width: 1024px) {
      .logo-digital img {
        margin-top: 0;
        width: 2.5rem;
        margin-left: 1.5rem; } }

.footer__body {
  padding-top: 4.3125rem;
  padding-bottom: 3rem;
  background: #dfe8f1; }
  @media only screen and (min-width: 1024px) {
    .footer__body {
      padding-top: 8.625rem;
      padding-bottom: 3rem; } }

.footer__bottom {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  color: #0c0c0c; }
  @media only screen and (min-width: 1024px) {
    .footer__bottom {
      flex-direction: row;
      align-items: center; } }
  .footer__bottom .footer__link,
  .footer__bottom .social-link {
    display: inline-block;
    margin-right: 3rem;
    font-size: inherit; }
    .footer__bottom .footer__link:nth-last-child(1),
    .footer__bottom .social-link:nth-last-child(1) {
      margin-right: 0; }
  .footer__bottom .footer__links {
    margin-bottom: 1.875rem; }
    @media only screen and (min-width: 1024px) {
      .footer__bottom .footer__links {
        margin-bottom: 0; } }

.footer__group {
  line-height: 2rem;
  margin-bottom: 1.625rem;
  padding-bottom: 1.625rem;
  border-bottom: 1px solid rgba(27, 20, 100, 0.25); }
  @media only screen and (min-width: 1024px) {
    .footer__group {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; } }
  .footer__group p {
    line-height: inherit;
    margin: 0; }
    @media only screen and (min-width: 1024px) {
      .footer__group p {
        margin-top: 0;
        margin-bottom: 1.875rem; } }

.footer__group--row {
  display: grid;
  grid-gap: 3.25rem; }
  @media only screen and (min-width: 1024px) {
    .footer__group--row {
      grid-template-columns: 1fr 1fr; } }
  .footer__group--row p {
    margin-right: 6.5rem; }

.footer__link,
.social-link {
  display: flex;
  align-items: center;
  color: #0c0c0c;
  transition: 0.3s;
  font-size: 0.75rem; }
  @media only screen and (min-width: 1024px) {
    .footer__link,
    .social-link {
      font-size: 1rem; } }
  .footer__link img,
  .footer__link svg,
  .social-link img,
  .social-link svg {
    height: 1rem;
    width: 1rem;
    object-fit: contain;
    margin-right: 1rem; }
  .footer__link:hover, .footer__link:focus, .footer__link:active,
  .social-link:hover,
  .social-link:focus,
  .social-link:active {
    color: #1B1464; }

/*------------------------------------*\
** COPYRIGHT
\*------------------------------------*/
.copyright {
  font-size: 0.625rem; }
  @media only screen and (min-width: 1024px) {
    .copyright {
      font-size: 0.875rem; } }

.nav {
  width: 100%;
  background: #ffffff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  z-index: 1000;
  transition: 0.3s; }
  .nav .layout--main {
    width: 100%; }
    @media only screen and (min-width: 1200px) {
      .nav .layout--main {
        max-width: calc(100% - 2 * 3.375rem); } }
    @media only screen and (min-width: 1440px) {
      .nav .layout--main {
        max-width: calc(100% - 2 * (100% / 12)); } }

.subnav {
  z-index: 10;
  position: relative;
  margin-top: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #ffffff;
  white-space: nowrap; }
  .subnav a {
    color: #0c0c0c;
    transition: 0.3s;
    margin-right: 0.625rem;
    font-size: 0.625rem;
    transition: 0.3s; }
    @media only screen and (min-width: 1024px) {
      .subnav a {
        font-size: 0.875rem;
        margin-right: 3rem;
        display: inline-block; } }
  .subnav a.subnav__tab {
    font-size: 0.75rem;
    font-weight: 500;
    position: relative;
    -webkit-transform: translateY(0.3125rem);
            transform: translateY(0.3125rem);
    opacity: 0.5;
    text-transform: capitalize; }
    @media only screen and (min-width: 640px) {
      .subnav a.subnav__tab {
        font-size: 0.875rem; } }
    @media only screen and (min-width: 1024px) {
      .subnav a.subnav__tab {
        font-size: 1.125rem;
        -webkit-transform: translateY(0.625rem);
                transform: translateY(0.625rem); } }
    .subnav a.subnav__tab.active {
      opacity: 1; }
      .subnav a.subnav__tab.active:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background: #0c0c0c; }

.logo-subtitle {
  font-size: 0.625rem;
  padding-right: 2rem;
  font-family: "Roboto", sans-serif;
  display: none; }
  @media only screen and (min-width: 1024px) {
    .logo-subtitle {
      display: block;
      line-height: 1rem;
      font-size: 0.875rem;
      padding: 0;
      margin: 0;
      margin-right: auto; } }
  .logo-subtitle > div {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    opacity: 1; }
    .logo-subtitle > div > div {
      display: flex;
      flex-wrap: wrap; }
    .logo-subtitle > div div {
      list-style-type: none;
      line-height: 1.25;
      font-weight: 600;
      font-size: 0.875rem;
      margin-right: 0.625rem; }
      .logo-subtitle > div div::before {
        display: none; }
      .logo-subtitle > div div:nth-last-child(1) {
        margin-right: 0; }
    .logo-subtitle > div span {
      opacity: 0.5;
      font-weight: 400;
      font-size: 0.95em; }
    .logo-subtitle > div div:nth-last-child(1) {
      border-right: none; }

.logo-subtitle p {
  margin: 0;
  width: 100%;
  font-size: 0.75rem;
  margin-bottom: 0.3125rem; }

.opening-times-button {
  cursor: pointer;
  text-transform: capitalize;
  font-size: 0.625rem;
  padding: 0.625rem 0.875rem;
  margin-right: 1.375rem; }
  @media only screen and (min-width: 1024px) {
    .opening-times-button {
      background-color: #678699;
      padding: 0.75rem 1.375rem;
      font-size: 0.75rem; } }
  .opening-times-button img {
    width: 0.75rem;
    height: 0.75rem;
    object-fit: contain;
    margin-right: 0.625rem; }
    @media only screen and (min-width: 1024px) {
      .opening-times-button img {
        width: 1.25rem;
        height: 1.25rem; } }

.opening-times-dropdown {
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  z-index: 1000;
  grid-gap: 1rem;
  gap: 1rem;
  display: table;
  white-space: nowrap;
  padding: 2rem 2.5rem;
  padding-bottom: 2rem;
  box-shadow: 0px 0px 2.25rem 99999px rgba(12, 12, 12, 0.65);
  font-size: 0.75rem;
  height: auto;
  margin: auto;
  border-top: 6px solid #678699;
  width: 100%;
  min-width: 300px; }
  @media only screen and (min-width: 640px) {
    .opening-times-dropdown {
      top: 0;
      font-size: 1rem;
      width: auto; } }
  .opening-times-dropdown > div {
    display: grid;
    grid-template-columns: 2rem auto;
    font-size: inherit; }
    @media only screen and (min-width: 640px) {
      .opening-times-dropdown > div {
        grid-template-columns: 3.75rem auto; } }
    .opening-times-dropdown > div + div {
      margin-top: 1rem; }
  .opening-times-dropdown span {
    opacity: 0.5;
    font-weight: 400; }

.opening-times-dropdown__close {
  height: 2rem;
  width: 2rem;
  z-index: 1;
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  padding: 0;
  background: none;
  font-size: 1.25rem;
  -webkit-transform: none !important;
          transform: none !important;
  box-shadow: none !important; }

.headroom {
  position: relative;
  z-index: 999 !important; }
  .headroom .nav__top {
    transition: 0.3s; }

.headroom--unpinned {
  position: fixed; }

.logo-subtitle {
  transition: 0.3s; }

.nav-offset {
  width: 100%;
  padding-top: 12.25rem;
  transition: 0.3s; }

.headroom-wrapper {
  position: relative;
  margin-bottom: 0; }

.headroom {
  position: fixed;
  top: 0;
  left: 0; }

.headroom--pinned .nav,
.headroom--unpinned .nav {
  padding: 0.5rem; }
  @media only screen and (min-width: 1024px) {
    .headroom--pinned .nav,
    .headroom--unpinned .nav {
      padding: 1rem 0;
      box-shadow: 0px 0px 6px rgba(12, 12, 12, 0.3);
      -webkit-transform: translateY(-4rem);
              transform: translateY(-4rem); }
      .headroom--pinned .nav .nav__logo,
      .headroom--unpinned .nav .nav__logo {
        width: 12rem; } }

.headroom--pinned .search-form-wrap,
.headroom--unpinned .search-form-wrap {
  opacity: 0;
  pointer-events: none; }

.headroom--pinned .subnav,
.headroom--unpinned .subnav {
  -webkit-transform: none;
          transform: none;
  margin-top: 1rem;
  margin-bottom: 0; }
  .headroom--pinned .subnav a,
  .headroom--unpinned .subnav a {
    font-size: 0.625rem; }
    @media only screen and (min-width: 1024px) {
      .headroom--pinned .subnav a,
      .headroom--unpinned .subnav a {
        font-size: 0.875rem;
        -webkit-transform: translateY(-0.25rem);
                transform: translateY(-0.25rem); } }

.nav__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.75rem; }

.nav__section {
  display: flex;
  align-items: center;
  width: 50%; }

.nav__section--right {
  justify-content: flex-end; }

.nav__link--main {
  color: #1B1464;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center; }

.nav__link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.625rem;
  width: 0.4375rem; }

.nav__links {
  display: flex; }

.nav__link {
  position: relative;
  transition: 0.3s;
  display: inline-block;
  color: #0c0c0c;
  display: inline-block;
  text-transform: uppercase; }

.nav__logo {
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  color: #0c0c0c;
  transition: 0.3s;
  display: inline-block;
  max-width: 26rem;
  white-space: nowrap; }
  .nav__logo:hover, .nav__logo:focus, .nav__logo:active {
    color: #1B1464; }
  .nav__logo img {
    width: 6.25rem; }

.nav--desktop {
  padding: 2.875rem 0;
  display: none; }
  @media only screen and (min-width: 1024px) {
    .nav--desktop {
      display: block; } }
  .nav--desktop .nav__top {
    width: 100%;
    height: 3.75rem; }
    .nav--desktop .nav__top .social-icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 0.75rem;
      margin-left: 1.25rem; }
      .nav--desktop .nav__top .social-icons a {
        margin: 0 0.5rem;
        width: 0.875rem;
        transition: 0.3s; }
        .nav--desktop .nav__top .social-icons a:hover {
          opacity: 0.7; }
      .nav--desktop .nav__top .social-icons img {
        height: 1rem;
        width: 1rem;
        object-fit: contain;
        margin: 0; }
      .nav--desktop .nav__top .social-icons span {
        margin-right: 0.75rem; }
    .nav--desktop .nav__top .icon {
      margin-right: 0.8rem; }
    .nav--desktop .nav__top .btn, .nav--desktop .nav__top .form input[type="submit"], .form .nav--desktop .nav__top input[type="submit"] {
      margin-right: 0.625rem;
      white-space: nowrap; }
  .nav--desktop .nav__top-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1; }
    .nav--desktop .nav__top-wrap > .nav__link {
      padding-right: 1.25rem;
      margin-right: 0;
      border-right: 1px solid #0c0c0c; }
    .nav--desktop .nav__top-wrap .nav__link {
      font-size: inherit;
      text-transform: uppercase;
      font-weight: 500; }
    .nav--desktop .nav__top-wrap .nav__link.active {
      color: #678699; }
      .nav--desktop .nav__top-wrap .nav__link.active:hover {
        color: #1B1464; }
      .nav--desktop .nav__top-wrap .nav__link.active:after {
        display: none; }
  .nav--desktop .nav__logo {
    font-size: 2.25rem;
    line-height: 4rem; }
  .nav--desktop .nav__link {
    margin: 0 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: 0.3s; }
    .nav--desktop .nav__link:after {
      content: '';
      display: block;
      z-index: 0;
      height: calc(100% + 2 * 0.375rem);
      width: calc(100% + 2 * 1.5rem);
      background: #1B1464;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto 0;
      margin-left: 50%;
      -webkit-transform: translateX(-50%) scale(0);
              transform: translateX(-50%) scale(0);
      border-radius: 0.375rem;
      opacity: 0;
      transition: 0.3s; }
    .nav--desktop .nav__link span {
      position: relative;
      z-index: 1; }
  .nav--desktop .nav__link.active {
    color: #ffffff; }
    .nav--desktop .nav__link.active:after {
      opacity: 1;
      -webkit-transform: translateX(-50%) scale(1);
              transform: translateX(-50%) scale(1);
      background-color: #1B1464; }
  .nav--desktop .nav__link:hover {
    color: #678699; }
  .nav--desktop .nav__link.active:hover {
    color: #ffffff;
    opacity: 0.8; }
  .nav--desktop .nav__link:nth-last-child(1) {
    margin-right: 0;
    padding-right: 0; }

@media only screen and (min-width: 1024px) {
  .nav--mobile {
    display: none; } }

.nav--mobile .nav__logo--wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1.5rem;
  gap: 1.5rem; }

.nav--mobile .nav-burger__main {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
  z-index: 20;
  background: #ffffff;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  height: 100vh;
  width: 100%;
  overflow: auto; }

.nav--mobile .nav__logo {
  font-size: 1.25rem; }
  .nav--mobile .nav__logo img {
    width: 4rem; }

.nav--mobile .burger-icon {
  display: block;
  position: relative;
  height: 1.25rem;
  width: 1.25rem; }
  .nav--mobile .burger-icon:before, .nav--mobile .burger-icon:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1.25rem;
    height: 2px;
    background: #0c0c0c;
    transition: 0.3s; }
  .nav--mobile .burger-icon:before {
    -webkit-transform: translateY(0.3125rem);
            transform: translateY(0.3125rem); }
  .nav--mobile .burger-icon:after {
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem); }

.nav--mobile .nav-burger .nav__links {
  display: flex;
  flex-direction: column; }

.nav--mobile .nav-burger .nav__link {
  font-size: 1.375rem;
  padding-bottom: 1rem; }
  .nav--mobile .nav-burger .nav__link:nth-last-child(1) {
    padding-bottom: 0; }

.nav--mobile.burger-active .nav__logo {
  opacity: 0; }

.nav--mobile.burger-active .burger-icon {
  z-index: 21; }
  .nav--mobile.burger-active .burger-icon::before {
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg); }
  .nav--mobile.burger-active .burger-icon::after {
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg); }

.nav--mobile.burger-active .nav-burger__main {
  padding: 9rem 0;
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.nav--mobile .social-icons {
  padding-top: 2.5rem;
  margin-top: 2.5rem;
  border-top: 1px solid #0c0c0c; }
  .nav--mobile .social-icons a {
    display: inline-block; }

.nav--mobile .social-icons span {
  display: block;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-bottom: 1.5rem;
  color: #0c0c0c; }

.nav--mobile .social-icons a {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  margin-right: 1.25rem; }
  .nav--mobile .social-icons a img {
    height: 100%;
    width: 100%;
    object-fit: contain; }

.back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: #0f4160;
  text-transform: capitalize;
  color: #ffffff;
  z-index: 5;
  border-radius: 50%;
  padding: 0;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  font-size: 2rem; }
  @media only screen and (min-width: 1024px) {
    .back-to-top {
      display: none; } }

.important-link {
  margin-bottom: 2rem;
  font-size: 1.125rem; }

.search-form-wrap > button {
  margin-left: 1.5rem;
  background: transparent;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  padding: 0; }
  .search-form-wrap > button:hover {
    box-shadow: none;
    -webkit-transform: none;
            transform: none;
    opacity: 0.75; }
  .search-form-wrap > button svg {
    width: 100%;
    height: 100%;
    object-fit: contain; }
    .search-form-wrap > button svg path,
    .search-form-wrap > button svg circle {
      stroke-width: 2px; }

.search-form-wrap .search-form-wrap__form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  background-color: #ffffff;
  z-index: 1000;
  padding: 2rem; }
  .search-form-wrap .search-form-wrap__form input {
    border: 2px solid #678699;
    padding: 1rem;
    margin: 0;
    border-radius: 0.625rem; }
  .search-form-wrap .search-form-wrap__form fieldset {
    margin: 0;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem; }

.search-form-wrap .search-form-wrap__close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  height: 100%;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  box-shadow: none;
  background: transparent;
  color: #678699; }
  .search-form-wrap .search-form-wrap__close:hover {
    box-shadow: none;
    -webkit-transform: none;
            transform: none; }

.search-form-wrap.active .search-form-wrap__form {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 0px 10px rgba(12, 12, 12, 0.3); }

/**
 * Print Stylesheet fuer Deinewebsite.de
* @version         1.0
* @lastmodified    16.06.2016
*/
@media print {
  /* Inhaltsbreite setzen, Floats und Margins aufheben */
  /* Achtung: Die Klassen und IDs variieren von Theme zu Theme. Hier also eigene Klassen setzen */
  #content, #page {
    width: 100%;
    margin: 0;
    float: none; }
  /** Seitenränder einstellen */
  @page {
    margin: 2cm; }
  /* Font auf 16px/13pt setzen, Background auf Weiß und Schrift auf Schwarz setzen.*/
  /* Das spart Tinte */
  body {
    font: 13pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000; }
  h1 {
    font-size: 24pt; }
  h2, h3, h4 {
    font-size: 14pt;
    margin-top: 25px; }
  /* Alle Seitenumbrüche definieren */
  a {
    page-break-inside: avoid; }
  blockquote {
    page-break-inside: avoid; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid; }
  img {
    page-break-inside: avoid;
    page-break-after: avoid; }
  table, pre {
    page-break-inside: avoid; }
  ul, ol, dl {
    page-break-before: avoid; }
  /* Linkfarbe und Linkverhalten darstellen */
  a:link, a:visited, a {
    background: transparent;
    color: #520;
    font-weight: bold;
    text-decoration: underline;
    text-align: left; }
  a {
    page-break-inside: avoid; }
  a[href^=http]:after {
    content: " <" attr(href) "> "; }
  a:after > img {
    content: ""; }
  article a[href^="#"]:after {
    content: ""; }
  a:not(:local-link):after {
    content: " <" attr(href) "> "; }
  /**
 * Eingebundene Videos verschwinden lassen und den Whitespace der iframes auf null reduzieren.
 */
  .entry iframe, ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap; }
  .embed-youtube, .embed-responsive {
    position: absolute;
    height: 0;
    overflow: hidden; }
  /* Unnötige Elemente ausblenden für den Druck */
  #header-widgets, nav, aside.mashsb-container,
  .sidebar, .mashshare-top, .mashshare-bottom,
  .content-ads, .make-comment, .author-bio,
  .heading, .related-posts, #decomments-form-add-comment,
  #breadcrumbs, #footer, .post-byline, .meta-single,
  .site-title img, .post-tags, .readability {
    display: none; }
  /* Benutzerdefinierte Nachrichten vor und nach dem Inhalt einfügen */
  .entry:after {
    content: "\ Alle Rechte vorbehalten. (c) 2014 - 2016 TechBrain - techbrain.de";
    color: #999 !important;
    font-size: 1em;
    padding-top: 30px; }
  #header:before {
    content: "\ Vielen herzlichen Dank für das Ausdrucken unseres Artikels. Wir hoffen, dass auch andere Artikel von uns Ihr Interesse wecken können.";
    color: #777 !important;
    font-size: 1em;
    padding-top: 30px;
    text-align: center !important; }
  /* Wichtige Elemente definieren */
  p, address, li, dt, dd, blockquote {
    font-size: 100%; }
  /* Zeichensatz fuer Code Beispiele */
  code, pre {
    font-family: "Courier New", Courier, mono; }
  ul, ol {
    list-style: square;
    margin-left: 18pt;
    margin-bottom: 20pt; }
  li {
    line-height: 1.6em; } }

/*------------------------------------*\
# VIEWS
\*------------------------------------*/
/*------------------------------------*\
# LAYOUT
\*------------------------------------*/
.toolkit__wrapper {
  display: flex;
  flex-wrap: nowrap; }

.toolkit__navigation {
  width: 20%;
  max-height: 100vh;
  overflow-y: scroll;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.toolkit__navigation-block {
  margin-bottom: 0.375rem; }

.toolkit__navigation-head {
  text-transform: uppercase; }
  .toolkit__navigation-head:hover {
    cursor: pointer;
    color: #1B1464; }

.toolkit__navigation-separator {
  height: 1px;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.4);
  margin-top: 0.375rem;
  margin-bottom: 0.375rem; }

.toolkit__separator {
  height: 1px;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.4);
  margin-top: 0.375rem;
  margin-bottom: 0.375rem; }

.toolkit__navigation-item {
  color: rgba(17, 17, 17, 0.4);
  font-size: 0.8rem; }

.toolkit__content {
  width: 80%;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  .toolkit__content > .js-toolkit-section {
    display: none; }

.toolkit__nav--user-wrapper {
  position: relative;
  height: 300px;
  display: flex;
  justify-content: flex-end; }

.toolkit__headline {
  color: #1B1464;
  margin-bottom: 1.25em; }

.toolkit__subheadline {
  border-left: 2px solid #1B1464;
  padding-left: 0.75rem;
  margin-bottom: 1em; }

/*------------------------------------*\
# COLORS
\*------------------------------------*/
.toolkit__colors-wrapper {
  display: flex; }

.toolkit__color-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  height: 50px; }

.toolkit__color-box--primary {
  background-color: #1B1464; }

.toolkit__color-box--secondary {
  background-color: #678699; }

.toolkit__color-box--tertiary {
  background-color: #dfe8f1; }

.toolkit__color-box--info {
  background-color: #2196f3; }

.toolkit__color-box--success {
  background-color: #4caf50; }

.toolkit__color-box--warning {
  background-color: #f1c40f; }

.toolkit__color-box--alert {
  background-color: #f44336; }

.toolkit__color-box--trueblack {
  background-color: #0c0c0c; }

.toolkit__color-box--black {
  background-color: #0c0c0c; }

.toolkit__color-box--lightergray {
  background-color: rgba(12, 12, 12, 0.1); }

.toolkit__color-box--lightgray {
  background-color: rgba(17, 17, 17, 0.4); }

.toolkit__color-box--gray {
  background-color: rgba(12, 12, 12, 0.7); }

.toolkit__color-box--darkgray {
  background-color: #7f8c8d; }

.toolkit__color-box--darkergray {
  background-color: #323232; }

.toolkit__color-box--white {
  background-color: #f9f9f9; }

.toolkit__color-box--truewhite {
  background-color: #ffffff; }

/*------------------------------------*\
# ICONS
\*------------------------------------*/
.toolkit__icons-wrapper {
  display: flex;
  flex-wrap: wrap; }

.toolkit__icon {
  width: 10%;
  max-height: 64px;
  padding: 0.375rem; }

/*------------------------------------*\
# GRID
\*------------------------------------*/
.toolkit__grid .cell {
  margin-bottom: 1rem; }

/*------------------------------------*\
# LAYOUT
\*------------------------------------*/
/*------------------------------------*\
# TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
# BUTTONS
\*------------------------------------*/
/*------------------------------------*\
# TABLES
\*------------------------------------*/
/*------------------------------------*\
# FORMS
\*------------------------------------*/
/*------------------------------------*\
# INPUTS
\*------------------------------------*/
/*------------------------------------*\
# FIGURES
\*------------------------------------*/
/*------------------------------------*\
# NOTIFICATOINS
\*------------------------------------*/
/*------------------------------------*\
# ACCORDION
\*------------------------------------*/
/*------------------------------------*\
# TABS
\*------------------------------------*/
/*------------------------------------*\
# SLIDER
\*------------------------------------*/
/*------------------------------------*\
# CAROUSEL
\*------------------------------------*/
/*------------------------------------*\
# MEDIA OBJECT
\*------------------------------------*/
/*------------------------------------*\
# NAVIGATION
\*------------------------------------*/
/*------------------------------------*\
# TOOLTIPS
\*------------------------------------*/
/*------------------------------------*\
# TAGS
\*------------------------------------*/
/*------------------------------------*\
# LOGOS
\*------------------------------------*/
/*------------------------------------*\
# REFERENCES
\*------------------------------------*/
/*------------------------------------*\
# SUMMARY
\*------------------------------------*/
/*------------------------------------*\
# ERROR PAGES
\*------------------------------------*/
/*------------------------------------*\
# HEADER
\*------------------------------------*/
/*------------------------------------*\
# FOOTER
\*------------------------------------*/
/*------------------------------------*\
# MAILER
\*------------------------------------*/
/*------------------------------------*\
# CAPTCHA
\*------------------------------------*/
/*------------------------------------*\
# MAPS
\*------------------------------------*/
/*------------------------------------*\
# COOKIES
\*------------------------------------*/
.toolkit__cookies-wrapper .cookies__wraper {
  position: relative !important; }

/*------------------------------------*\
# PROJECT SPECIFIC
\*------------------------------------*/
/*------------------------------------*\
# UTILITIES
\*------------------------------------*/
.view--contact .section--padded--tb.section--offset {
  margin-bottom: 14.375rem; }

.view--contact .view__body {
  padding-top: 0; }
  @media only screen and (min-width: 1024px) {
    .view--contact .view__body {
      padding-top: 0; } }

.view--contact .team {
  margin-bottom: 14.375rem;
  padding: 0 3.25rem; }

.view--contact .team h1 {
  margin-bottom: 6.25rem; }

.view--contact .contact__cta {
  padding-left: 13rem;
  position: relative; }
  .view--contact .contact__cta .cta {
    position: absolute;
    top: 14.875rem;
    left: 13rem; }

.view--contact .contact__info {
  padding-bottom: 7.1875rem; }

.view--contact .contact-opening-times {
  font-weight: 600;
  margin-top: 3.125rem; }
  @media only screen and (min-width: 1024px) {
    .view--contact .contact-opening-times {
      padding-top: 0; } }
  .view--contact .contact-opening-times div {
    font-weight: 700;
    margin-bottom: 1.25rem;
    display: flex; }
    .view--contact .contact-opening-times div::before {
      display: none; }
  .view--contact .contact-opening-times span {
    width: 6.5rem;
    display: block;
    font-weight: 400;
    margin-right: 0.625rem;
    opacity: 0.75; }
  .view--contact .contact-opening-times p {
    font-size: 1.625rem;
    line-height: 1.2;
    margin-bottom: 3rem !important;
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    margin: 0;
    padding: 0; }
    @media only screen and (min-width: 1024px) {
      .view--contact .contact-opening-times p {
        font-size: 2rem; } }

.view--bookings .view__body {
  padding-top: 1/22.5rem;
  padding-bottom: 6.625rem; }
  @media only screen and (min-width: 1024px) {
    .view--bookings .view__body {
      padding-top: 22.5rem;
      padding-bottom: 6.625rem; } }

.view--bookings .header {
  width: 52.125rem; }

.section--whats-on {
  padding-top: 3.25rem;
  padding-bottom: 2.75rem;
  margin-bottom: 3.59375rem;
  background: #dfe8f1; }
  @media only screen and (min-width: 1024px) {
    .section--whats-on {
      padding-bottom: 5.25rem; } }
  .section--whats-on .link--section {
    display: block;
    padding-top: 2.25rem;
    color: #ffffff; }
    @media only screen and (min-width: 1024px) {
      .section--whats-on .link--section {
        padding-top: 6.25rem; } }

.section--about .section__body {
  padding: 4.8125rem 0;
  padding-right: 6.5rem; }

.section--about .section__img {
  width: 66.5rem;
  height: 100%;
  min-height: 34.375rem; }

.section--info--bg {
  padding: 8.5rem 0;
  position: relative; }
  .section--info--bg:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background-color: #678699; }

.section__img > img {
  height: 100%;
  width: 100%;
  object-fit: cover; }

.section__body--margin {
  margin-bottom: 5rem; }

.section--padded--large {
  padding: 4.875rem 0;
  padding-bottom: 0; }
  @media only screen and (min-width: 1024px) {
    .section--padded--large {
      padding: 4.875rem 0; } }

.section--margin--large {
  border-top: 2.925rem solid #ffffff;
  border-bottom: 2.925rem solid #ffffff; }
  @media only screen and (min-width: 1024px) {
    .section--margin--large {
      border-top: 4.875rem solid #ffffff;
      border-bottom: 4.875rem solid #ffffff; } }

.not-implemented {
  padding: 7.1875rem 0;
  text-align: center; }

.safeguarding {
  display: grid;
  grid-gap: 6.5rem; }
  @media only screen and (min-width: 1024px) {
    .safeguarding {
      grid-gap: 13rem;
      grid-template-columns: 32.5rem 32.5rem; } }

.safeguarding__img {
  height: auto;
  width: 100%;
  margin-bottom: 3.125rem; }
  .safeguarding__img img {
    height: auto;
    max-height: 100%;
    width: 100%;
    object-fit: cover; }

.about-text img {
  width: 19.5rem;
  height: auto;
  object-fit: cover;
  margin: 2rem 0 !important;
  display: inline-block; }

.about-text span {
  overflow: auto;
  height: auto !important;
  width: auto !important; }

.about-text p:empty,
.about-text p:blank {
  display: none; }

.about-text p {
  font-size: 1rem; }

.about-text h3 {
  font-size: 2.5rem; }

.about-text blockquote {
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
  margin-bottom: 4rem;
  opacity: 0.7; }

.about-text:nth-child(odd) {
  text-align: left; }

.notification-wrapper {
  margin-top: 2rem;
  padding: 1.25rem;
  background-color: #EABB5F;
  border-radius: 0.375rem;
  margin-bottom: 3.25rem;
  color: #f9f9f9;
  text-align: center; }
  @media only screen and (min-width: 1024px) {
    .notification-wrapper {
      margin-top: 0; } }
  .notification-wrapper p {
    margin-bottom: 0; }
  .notification-wrapper p:empty {
    display: none; }

.loader {
  transition: all 300ms ease-in-out;
  opacity: 0.15;
  /* .section--primary {
		background: rgba(224, 224, 224, 0.493);
	}  */ }
  .loader::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    background: url("/spinner.svg") no-repeat center center;
    opacity: 0;
    background-size: 6.25rem;
    background-color: transparent;
    -webkit-animation-name: loader;
            animation-name: loader;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

@-webkit-keyframes loader {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes loader {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.loader--loaded {
  opacity: 1;
  /* .section--primary {
		background: $color-primary;
	}  */ }
  .loader--loaded::after {
    display: none;
    -webkit-animation: none;
            animation: none; }

.share-buttons {
  position: fixed;
  bottom: 6.5rem;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  background: #ffffff;
  border-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
  .share-buttons button {
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0.625rem;
    border-radius: 50%; }
    .share-buttons button:nth-last-child(1) {
      margin-bottom: 0; }
    .share-buttons button svg {
      height: 100%;
      width: 100%;
      object-fit: contain; }

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.85rem;
  padding-bottom: 1.85rem;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1); }
  .search-header h1 {
    margin: 0; }

.section--community {
  position: relative;
  margin-bottom: 6.5rem;
  background-color: #1B1464; }
  @media only screen and (min-width: 1024px) {
    .section--community:nth-child(even) .community {
      flex-direction: row-reverse; } }
  @media only screen and (min-width: 1024px) {
    .section--community:nth-child(even) .community__img {
      right: auto;
      left: 0; } }

.community {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1024px) {
    .community {
      min-height: 40vh;
      align-items: center;
      flex-direction: row; } }
  .community .card--circle {
    margin-bottom: 1.5rem; }
    @media only screen and (min-width: 1024px) {
      .community .card--circle {
        margin-bottom: 0; } }

.community__img {
  height: 24.75rem; }
  @media only screen and (min-width: 1024px) {
    .community__img {
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      height: 100%;
      width: 40%;
      z-index: 0; } }
  .community__img img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

/*------------------------------------*\
# VENDORS
\*------------------------------------*/
/*@import
  "styles/vendors/animations",
  "styles/vendors/ui_datepicker",
  "styles/vendors/bamboolab_cookies",
  "styles/vendors/captcha",
  "styles/vendors/google_map";*/
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

