.partners {
	display: grid;
	grid-gap: 2 * $gutter 2*$gutter;
	grid-template-columns: repeat(2, 1fr);
	min-height: 18.5rem;


	@include breakpoint($medium) {
		grid-template-columns: repeat(6, 1fr);
	}

	@include breakpoint($large) {
		grid-template-columns: repeat(8, 1fr);
	}
}

.partner {
	display: flex !important;
	flex-direction: column;
	align-items: center;
	//justify-content: center;
	text-align: center;
	font-weight: $semibold;
	color: $black;
}

.club-header {
	padding-bottom: 2 * 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #aaa;
}

.partner--big {
	font-size: 1.25rem;
	align-items: flex-start;
	text-align: left;
	margin-bottom: 0;

	@include breakpoint($large) {
		width: 3 * $cell;
	}

	a {
		font-size: 1.25em;
		color: $color-accent;
	}

	.partner__img {
		width: 1.5 * $cell;
		height: auto;
		margin-bottom: $gutter;

		img {
			height: 100%;
		}
	}
}

.partner__img {
	margin-bottom: 1.25rem;
	width: 100%;
	height: 12.5rem;
	display: flex;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain !important;
	}
}
