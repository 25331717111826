/*------------------------------------*\
# CORE BUTTONS
\*------------------------------------*/

button,
.btn {
	border: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 0.75rem;
	line-height: normal;
	font-weight: $regular;
	padding: 0.75rem 1.375rem;
	transition: $transition-time;
	border: 1px solid transparent;
	border-radius: 0.375rem;
	overflow: hidden;
	position: relative;
	outline: none;

	@include breakpoint($large) {
		font-size: 1rem;
	}

	&:focus {
		outline: none;
		border-width: 1px;
		box-shadow: 0 0 0px 0px transparent;
	}

	&:hover {
		cursor: pointer;
		transform: translateY(-0.3125rem);
		box-shadow: 0.25rem 0 0.625rem rgba($black, 0.25);
	}
}

.btn__text {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.btn--margin {
	margin-right: 0.625rem;
}

.btn--big {
	padding: 1.35 * 0.75rem 1.75 * 1.375rem;
	font-size: 1.25rem;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

.btn--primary {
	background-color: $color-accent;
	color: $white--true;
	border-color: $color-accent;

	&:focus,
	&:active,
	&:hover {
		background-color: rgba($color-accent, 0.9);
		border-color: rgba($color-accent, 0.9);
		color: $white--true;
	}
}

.btn--blue {
	background-color: $color-primary;
	color: $white--true;
	border-color: $color-primary;

	&:focus,
	&:active,
	&:hover {
		background-color: rgba($color-primary, 0.9);
		border-color: rgba($color-primary, 0.9);
		color: $white--true;
	}
}

.btn--transparent {
	background-color: transparent;
	color: $color-accent;
	border-color: $color-accent;

	&:focus,
	&:active,
	&:hover {
		background-color: $color-accent;
		border-color: $color-accent;
		color: $white--true;
	}
}

.btn--white--transparent {
	border-color: $white--true;
	color: $white--true;

	&:hover {
		background-color: $white--true;
		color: $color-primary;
		border-color: $white--true;
	}
}

.btn--fill {
	background-color: $color-primary;
	border: none;
}

.btn--upload {
	display: flex !important;
	flex-direction: column;
	margin-bottom: $gutter;
	margin-top: $gutter;

	input {
		height: 0;
	}
}

/*------------------------------------*\
# WIDTH
\*------------------------------------*/

.btn--full {
	display: block;
	width: 100%;
	text-align: center;
}

/*------------------------------------*\
# UTILITIES
\*------------------------------------*/

.btn--spacing {
	margin-bottom: $gutter;
}

/*------------------------------------*\
# STAY AWAY
\*------------------------------------*/

/*------------------------------------*\
# PRIMARY / SECONDARY / TERTIARY
\*------------------------------------*/

.btn--primary--light {
	background-color: lighten($color-primary, 20%);
	color: $white;

	&:focus,
	&:active,
	&:hover {
		background-color: lighten($color-primary, 25%);
	}
}

.btn--secondary {
	background-color: $color-secondary;
	color: $white;

	&:focus,
	&:active,
	&:hover {
		background-color: darken($color-secondary, 5%);
	}
}

.btn--tertiary {
	background-color: $color-tertiary;
	color: $white;

	&:focus,
	&:active,
	&:hover {
		background-color: darken($color-tertiary, 5%);
	}
}

/*------------------------------------*\
# LARGE / SMALL
\*------------------------------------*/

.btn--large {
	padding-left: 2 * $button-padding--lr;
	padding-right: 2 * $button-padding--lr;
	padding-top: 2 * $button-padding--tb;
	padding-bottom: 2 * $button-padding--tb;

	&.btn__icon {
		background-position: 2 * $button-icon--size center;
		padding-left: 3.5 * $button-icon--size;
	}
}

.btn--small {
	padding-left: 1/2 * $button-padding--lr;
	padding-right: 1/2 * $button-padding--lr;
	padding-top: 1/2 * $button-padding--tb;
	padding-bottom: 1/2 * $button-padding--tb;

	&.btn__icon {
		background-position: 2 * $button-icon--size center;
		padding-left: 3.5 * $button-icon--size;
	}
}

.btn--spacing {
	margin-top: $gutter;
	margin-bottom: $gutter;
}

/*------------------------------------*\
# UNDERLINE
\*------------------------------------*/

.btn--underline {
	background-color: transparent;
	color: $black;
	border-bottom: 2px solid $black;

	border-radius: 0;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
	margin-bottom: 1rem;

	&:active {
		border-bottom: 2px solid lighten($color-primary, 5%);
	}

	&:focus,
	&:hover {
		border-bottom: 2px solid $color-primary;
	}
}

/*------------------------------------*\
# ARROW
\*------------------------------------*/

.btn--arrow {
	@extend .btn--primary;

	//background-image: image-url('icons/followright_white.svg');
	background-repeat: no-repeat;
	background-size: $button-icon--size;
	background-position: right $button-icon--size center;
	padding-right: 2.5 * $button-icon--size;
}

.btn--arrow--simple {
	color: $color-primary;
	font-weight: $bold;
	display: block;
	margin-top: 1rem;
	font-size: 1.2rem;

	&:hover {
		.icon--arrow::before {
			width: 0.75rem;
		}
	}

	.icon--arrow {
		&::before {
			content: '';
			margin-left: 0.5rem;
			width: 0.5rem;
			border-bottom: 4px solid $color-primary;
			display: inline-block;
			vertical-align: middle;
			transition: $transition-time;
			margin-top: -2px;
		}

		&::after {
			content: '';
			width: 0.5rem;
			height: 0.5rem;
			border-bottom: 3px solid $color-primary;
			border-right: 3px solid $color-primary;
			transform: rotate(-45deg);
			margin-left: -6px;
			display: inline-block;
			vertical-align: middle;
			margin-top: -1px;
		}
	}
}

/*------------------------------------*\
# DISABLED
\*------------------------------------*/

.btn--disabled {
	background-color: $color-gray;
	color: $white--true;
	cursor: default;
	pointer-events: none;
	opacity: 0.5;

	&:focus,
	&:active,
	&:hover {
		background-color: $color-gray;
		color: $color-gray--darker;
		cursor: default;
		pointer-events: none;
		opacity: 0.5;
	}
}

/*------------------------------------*\
# ICONS
\*------------------------------------*/

.btn__icon {
	background-repeat: no-repeat;
	background-size: $button-icon--size;
	background-position: $button-icon--size center;
	padding-left: 2.5 * $button-icon--size;
}

.btn--like {
	@extend .btn--primary;
	//background-image: image-url('icons/heartempty_black.svg');
}

.btn--like--full {
	// background-image: image-url('icons/heartfull_black.svg') !important;
	animation: pulse 0.5s 1;
	animation-timing-function: ease-in-out;
}

@keyframes pulse {
	0% {
		background-size: 1.5rem;
		background-position: 1.5rem center;
	}
	50% {
		background-size: 2rem;
		background-position: 1.25rem center;
	}
	100% {
		background-size: 1.5rem;
		background-position: 1.5rem center;
	}
}

.btn--add {
	@extend .btn--primary;
	// background-image: image-url('icons/add_black.svg');
	transition: background-position 0s ease;
}

.btn--mail {
	@extend .btn--primary;
	// background-image: image-url('icons/mail_black.svg');
}

.btn--download {
	@extend .btn--primary;
	// background-image: image-url('icons/download_black.svg');
}

.btn--play {
	@extend .btn--primary;
	// background-image: image-url('icons/play2_black.svg');
}

.btn--share {
	@extend .btn--primary;
	// background-image: image-url('icons/share_black.svg');
}

.btn--thumbs {
	@extend .btn--primary;
	// background-image: image-url('icons/like_black.svg');
}

/*------------------------------------*\
# Utility
\*------------------------------------*/

.btn--create {
	@extend .btn--small;
	background-color: $color-success;
	color: $white;

	&:focus,
	&:active,
	&:hover {
		background-color: darken($color-success, 5%);
	}
}

.btn--update {
	@extend .btn--small;
	background-color: $color-warning;
	color: $black;

	&:focus,
	&:active,
	&:hover {
		background-color: darken($color-warning, 5%);
	}
}

.btn--remove {
	@extend .btn--small;
	background-color: $color-alert;
	color: $white;

	&:focus,
	&:active,
	&:hover {
		background-color: darken($color-alert, 5%);
	}
}

/*------------------------------------*\
# BUTTON GROUP
\*------------------------------------*/

.btn-group {
	display: inline-block;
	vertical-align: middle;
	display: flex;
	flex-wrap: nowrap;

	& .btn {
		border-radius: 0;
	}

	& .btn:first-child {
		border-top-left-radius: $button-radius;
		border-bottom-left-radius: $button-radius;
	}

	& .btn:last-child {
		border-top-right-radius: $button-radius;
		border-bottom-right-radius: $button-radius;
	}
}

/*------------------------------------*\
# BUTTON GOOGLE
\*------------------------------------*/

.btn--google {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19.0625rem;
	height: 3.375rem;
	font-size: 0.875rem;
	font-weight: $semibold;
	position: relative;

	button {
		padding: 1.125rem 1rem !important;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		box-shadow: none !important;
		border: 1px solid $black !important;
		border-radius: 0.375rem !important;

		> div {
			position: absolute;
			border-radius: 0 !important;

			svg {
				height: 100%;
				width: 100%;
			}
		}

		> span {
			color: $black !important;
			font-size: 0.875rem !important;
			padding: 0 !important;
			margin: 0 auto;
		}
	}

	&:hover {
	}
}

.btn--center {
	display: flex;
	margin-left: auto;
	margin-right: auto;
}

/*------------------------------------*\
# FIXED BUTTONS
\*------------------------------------*/

.btn--fixed {
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 20;
	display: none;
	padding: 0;
	border-radius: 0;
	overflow: visible;
	box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
	transform-origin: 100% 100%;
	transform: translateX(1px);

	@include breakpoint($large) {
		display: block;
	}

	&:hover {
		box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
		opacity: 0.75;
		transform: translateX(1px);
	}

	img {
		transition: $transition-time;
		width: 6.25rem;
		height: auto;
	}
}

.btn--see-more {
	margin: auto;
	margin-top: $cell;
}

.like-btn {
	display: flex;
	align-items: center;
	font-size: 1.125rem;
	color: $color-gray;
	line-height: 0;
	padding: 0;
	background: transparent;
	border: none;
	overflow: visible;
	justify-content: flex-start;


	&:hover {
		box-shadow: none;
		transform: none;
		opacity: 0.75;
	}
}

.like-btn__icon {
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	border: 1px solid $color-gray--lighter;
	overflow: hidden;
	padding: 0.25rem;
	box-shadow: 2px 2px 0.375rem 0px rgba($black, 0.15);
	margin-right: 2*0.625rem;

	img {
		width: 100%;
	}
}
