.tweet {
	background-color: $white--true;
	padding: 1.25rem;
	box-shadow: 0 0 0.625rem rgba($color: #000000, $alpha: 0.035);

	@include breakpoint($large) {
	}
}

.tweet__date {
	font-size: 1.125rem;
	color: $color-accent;
	margin-bottom: 0.75rem;

	@include breakpoint($large) {
	}
}

.tweet__name {
	margin-bottom: 0.625rem;
	color: $color-info;

	@include breakpoint($large) {
	}
}

.tweet__content {
	@include breakpoint($large) {
		font-size: 1rem;
	}
}

.tweet__hashtags {
	@include breakpoint($large) {
	}
}

.tweet-feed {
	display: grid;
	grid-gap: $gutter;
}

.tweets-section {
	padding: 1/2 * $cell 0;
	@include breakpoint($large) {
		padding: 13.25rem 0;
		padding-bottom: 1/2 * 14.375rem;
	}
}

.tweets__subsection {
	padding-bottom: 8rem;

	.tweets__title {
		display: block;
		color: $color-primary;
		font-size: 2rem;
		margin-bottom: 4rem;

		&:hover {
			transition: $transition-time;
			opacity: 0.7;
		}
	}

	&:nth-last-child(1) {
		padding-bottom: 0;
	}
}

.tweets-section__wrap {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: $gutter;

	@include breakpoint($medium) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include breakpoint($large) {
		grid-template-columns: repeat(4, 1fr);
	}

	.tweet {
		border: 1px solid rgba($color: #000000, $alpha: 0.1);
	}
}

.homepage-tweets {
	display: grid;
	grid-gap: $gutter;
}
