.cta {
	display: grid;
	grid-template-rows: auto 1fr auto auto;
	justify-items: center;
	max-height: fit-content;
	text-align: center;

	@include breakpoint($large) {
		text-align: left;
		justify-items: start;
	}

	.btn {
		margin-top: auto;
	}
}

.cta__img {
	margin-bottom: 0.85 * 2.875rem;

	@include breakpoint($large) {
		margin-bottom: 2.875rem;
	}

	> div {
		height: 6.25rem;
		width: 6.25rem;
		position: relative;
		border-radius: 50%;
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint($large) {
			height: 8.5rem;
			width: 8.5rem;
		}
	}

	img {
		margin: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.cta__body {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint($large) {
		align-items: flex-start;
		display: block;
	}
}

.cta__title {
	font-size: 1.5rem;
	//height: 100%;

	@include breakpoint($large) {
		font-size: 2rem;
		padding-right: 1rem;
		min-height: 6rem;
	}
}

.cta__desc {
	margin-bottom: 1/2 * 3rem;
	font-size: 0.875rem;
	// height: 100%;
	line-height: 1.5;
	width: 60%;

	@include breakpoint($large) {
		width: 2 * $cell;
		font-size: 1.125rem;
		margin-bottom: 3rem;
	}

	p {
		margin: 0;
	}
}

.cta__wrap {
	display: grid;
	grid-gap: 3.75rem;

	@include breakpoint($large) {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1.25rem;
	}
}
