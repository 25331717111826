.view--bookings {

    .view__body {
        padding-top: 1/22.5rem;
        padding-bottom: 1/2*13.25rem;
        
        @include breakpoint($large) {
            padding-top: 22.5rem;
            padding-bottom: 1/2*13.25rem;
        }
    }

    .header {
        width: 52.125rem;
    }
}