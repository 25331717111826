/*------------------------------------*\
# CONFIG
\*------------------------------------*/

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

@import 'styles/config/variables', 'styles/config/utilities',
	'styles/config/mixins';

/*------------------------------------*\
# BASE
\*------------------------------------*/

@import 'styles/base/typography', 'styles/base/global';

/*------------------------------------*\
# MODULES
\*------------------------------------*/

@import 'styles/modules/buttons', 'styles/modules/burger',
	'styles/modules/forms', 'styles/modules/inputs', 'styles/modules/tabs',
	'styles/modules/accordions', 'styles/modules/icons', 'styles/modules/cta',
	'styles/modules/breadcrumbs', 'styles/modules/pagination',
	'styles/modules/slider', 'styles/modules/events_slider',
	'styles/modules/articles', 'styles/modules/cards', 'styles/modules/calendars',
	'styles/modules/lists', 'styles/modules/maps', 'styles/modules/tweets',
	'styles/modules/search-result', 'styles/modules/modal',
	'styles/modules/images', 'styles/modules/feed', 'styles/modules/partners';

/*------------------------------------*\
# LAYOUT
\*------------------------------------*/

@import 'styles/layout/grid', 'styles/layout/header', 'styles/layout/footer',
	'styles/layout/navigation', 'styles/layout/sidebar', 'styles/layout/print';

/*------------------------------------*\
# VIEWS
\*------------------------------------*/

@import 'styles/views/toolkit', 'styles/views/temp',
	'styles/views/search-results', 'styles/views/dashboard', 'styles/views/login',
	'styles/views/contact', 'styles/views/whats_on', 'styles/views/bookings',
	'styles/views/home', 'styles/views/our_community';

/*------------------------------------*\
# VENDORS
\*------------------------------------*/

/*@import
  "styles/vendors/animations",
  "styles/vendors/ui_datepicker",
  "styles/vendors/bamboolab_cookies",
  "styles/vendors/captcha",
  "styles/vendors/google_map";*/

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
